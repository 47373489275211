import { ISSUE_TYPES, STATUS_CATEGORY_CLASS, SCREEN_ITEM_DISPLAY_PROPERTIES, DISALLOWED_SCREEN_ITEMS_FOR_CATEGORY_SUB, ISSUE_STATUS_CATEGORY } from "@/constants"
import ThemisScreen from "@/components/screen"
import moment from "moment"
import { getDueDateColor } from "@/utils"
import { SCREEN_ITEM_TYPE } from "../../../constants"

export default {
  name      : "Tasks",
  components: {
    ThemisScreen
  },
  props: {
    pCanAddTask             : Boolean,
    pTasks                  : Array,
    pUsers                  : Array,
    pStatuses               : Array,
    pWorkflows              : Array,
    pWorkflowAssociations   : Array,
    pIsTransitionDone       : Boolean,
    pIsTransitionInProgress : Boolean,
    pUsersWithAllLevelAccess: Array,
    pLoggedInUser           : Object
  },
  data: () => ({
    typeId                 : ISSUE_TYPES[1].id,
    submitEventType        : undefined,
    isTasksExpanded        : false,
    addTaskDialog          : false,
    screenItemsForComponent: []
  }),
  computed: {
    hasTasks() {
      return !!this.pTasks.length
    },
    statusesMap() {
      const statusesMap = {}
      for (const status of this.pStatuses) {
        statusesMap[status.id] = status
      }
      return statusesMap
    },
    getAddTaskButtonLabel() {
      return this.hasTasks ? this.$t("1265") : this.$t("1263")
    },
    taskWorkflow() {
      return this.pWorkflows.find(workflow => workflow.id === this.workflowAssociation.workflowId)
    },
    workflowAssociation() {
      return this.pWorkflowAssociations.find(workflowAssociation =>
        workflowAssociation.issueTypeId === this.typeId
      )
    },
    initialTransitionTask() {
      return this.taskWorkflow.transitions.find(transition => transition.initialTransition)
    },
    usersMap() {
      const usersMap = new Object()
      for (const user of this.pUsers) {
        usersMap[user.id] = user
      }
      return usersMap
    },
    usersWithUnassignedOption() {
      const usersWithDetails = []
      for (const userWithAccess of this.pUsersWithAllLevelAccess) {
        const user = this.usersMap[userWithAccess]
        if (user?.enabled && user.id !== this.pLoggedInUser.id) {
          usersWithDetails.push(user)
        }
      }
      return [{
        ...this.pLoggedInUser,
        name: this.$t("951")
      }, ...usersWithDetails]
    }
  },
  methods: {
    getClassForDueDateColor(dueDate, statusId) {
      const statusCategory    = this.statusesMap[statusId].category
      const isNewOrInprogress = statusCategory === ISSUE_STATUS_CATEGORY.NEW
        || statusCategory === ISSUE_STATUS_CATEGORY.IN_PROGRESS
      return getDueDateColor(dueDate, isNewOrInprogress)
    },
    getClassForStatusChip(statusId) {
      return STATUS_CATEGORY_CLASS[this.statusesMap[statusId].category]
    },
    isUserDisabled(assigneeId) {
      return this.pUsers.find(user => user.id === assigneeId)?.disabledButNotDeleted
    },
    getUserNameForATask(assigneeId) {
      return assigneeId ? this.pUsers.find(user => user.id === assigneeId)?.name : "Unassigned"
    },
    getStatusForATask(statusId) {
      return this.statusesMap[statusId]?.name
    },
    getDueDateForATask(dueDate) {
      return dueDate ? this.$t("1835") + moment(dueDate).format("MMMM D") : ""
    },
    toggleExpandCollapseTasks() {
      this.isTasksExpanded = !this.isTasksExpanded
    },
    handleCancelTask() {
      this.addTaskDialog = false
      this.task.summary  = null
    },
    addNewTask() {
      this.addTaskDialog           = true
      const screenItems            = this.initialTransitionTask.screen
        ? [...this.initialTransitionTask.screen.screenItems]
        : []
      this.screenItemsForComponent = screenItems
        .filter(screenItem => {
          return screenItem.issueProperty
            && !DISALLOWED_SCREEN_ITEMS_FOR_CATEGORY_SUB.includes(screenItem.issueProperty.key)
            && screenItem.type !== SCREEN_ITEM_TYPE.FIELD && screenItem.type !== SCREEN_ITEM_TYPE.FORM
        })
        .sort((firstScreenItem, secondScreenItem) => firstScreenItem.sortingOrder - secondScreenItem.sortingOrder)
        .map(screenItem => {
          return {
            key      : screenItem.type === "ISSUE_PROPERTY" ? screenItem.issueProperty.key : screenItem.field.key,
            mandatory: screenItem.type === "ISSUE_PROPERTY" ? screenItem.issueProperty.mandatory : screenItem.field.mandatory,
            items    : this[SCREEN_ITEM_DISPLAY_PROPERTIES[screenItem.issueProperty?.key]?.computedPropertyValue]
              ?? null
          }
        })
    },
    handleSubmitAddTask(screenItems) {
      const [transitionLink] = this.initialTransitionTask.transitionLinks
      const task             = {
        transitionId: this.initialTransitionTask.id,
        data        : {
          ...screenItems,
          typeId  : this.typeId,
          statusId: transitionLink.toStatusId
        }
      }
      this.$emit("addTasks", task)
    },
    handleCancelAddTask() {
      this.addTaskDialog           = false
      this.screenItemsForComponent = []
    }
  },
  watch: {
    pIsTransitionDone: {
      handler: function(newValue) {
        if (newValue) {
          this.addTaskDialog = false
        }
      }
    }
  }
}