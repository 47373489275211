import store from "@/plugins/vuex"
import { PAGE_TITLES, PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"
import { DATA_EXPORT_TYPE } from "@/constants"

export const beforeAnalyticsExport = async (to, from)  => {
  const loggedInUserPolicies = store.getters["accessControl/loggedInUserPolicies"]
  if (!loggedInUserPolicies["Analytics view"]) {
    return "not-found"
  }

  if (!["analytics-export"].includes(from.name)) {
    store.commit("shared/setProgressBarInitiated", true)
    store.commit("shared/setProgressBarPromisesPending", true)
    store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.ANALYTICS))
    store.commit("shared/setBreadcrumbs", undefined)
    const promisesArrayToBeResolved = []

    if (loggedInUserPolicies["Export view"]) {
      const loadExportsPromise = store.dispatch("exports/loadExports", { type: DATA_EXPORT_TYPE.ANALYTICS })
      promisesArrayToBeResolved.push(loadExportsPromise)
    }

    Promise.all(promisesArrayToBeResolved).then(() => {
      store.commit("shared/setProgressBarInitiated", false)
      store.commit("shared/setProgressBarPromisesPending", false)
    })
  }
}