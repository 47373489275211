var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{ref:"table_issue_add_forms",staticClass:"elevation-1 table--add-screen-items",attrs:{"data-cy":"","items":_vm.pItemsForTable,"item-key":"id","headers":_vm.headersForAddItemsTable,"height":_vm.calculateHeightForTable,"items-per-page":-1,"checkbox-color":"primary","sort-by":"name","hide-default-footer":"","fixed-header":"","show-select":"","multi-sort":""},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('v-simple-checkbox',_vm._g({directives:[{name:"ripple",rawName:"v-ripple"}],attrs:{"data-cy":"4279","value":props.value || props.indeterminate,"indeterminate":props.indeterminate,"color":"primary"}},on))]}},{key:"item.fields",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-block text-truncate max-width-400",attrs:{"data-cy":"6824"}},on),[_c('span',{staticClass:"info--text",attrs:{"data-cy":"6825"}},[_vm._v(_vm._s(item.fields))])])]}}],null,true)},[_c('span',{attrs:{"color":"info darken-4","data-cy":"6826"}},[_vm._v(_vm._s(item.fields))])])]}}]),model:{value:(_vm.itemsToBeAdded),callback:function ($$v) {_vm.itemsToBeAdded=$$v},expression:"itemsToBeAdded"}})}
var staticRenderFns = []

export { render, staticRenderFns }