export default {
  setLoadingCharts: (state, loadingCharts) => {
    state.loadingCharts = loadingCharts
  },
  setAddingChart: (state, addingChart) => {
    state.addingChart = addingChart
  },
  setChartAdded: (state, chartAdded) => {
    state.chartAdded = chartAdded
  },
  setCharts: (state, charts) => {
    state.charts = charts
  },
  setChartAddError: (state, chartAddError) => {
    state.chartAddError = chartAddError
  },
  resetChartAddError: state => {
    state.chartAddError = undefined
  },
  updateCharts: (state, charts) => {
    for (const chart of charts) {
      const index = state.charts.findIndex(stateChart => stateChart?.id === chart.id)
      if (index >= 0) {
        state.charts.splice(index, 1, { ...state.charts[index], ...chart })
      } else {
        state.charts.push(chart)
      }
    }
  },
  setUpdatingChart: (state, properties) => {
    for (const property of properties) {
      state.updatingChart[property] = true
    }
  },
  resetUpdatingChart: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.updatingChart[property] = false
      }
    } else {
      Object.keys(state.updatingChart).forEach(property => state.updatingChart[property] = false)
    }
  },
  setChartUpdated: (state, properties) => {
    for (const property of properties) {
      state.chartUpdated[property] = true
      setTimeout(() => {
        state.chartUpdated[property] = false
      }, 5000)
    }
  },
  resetChartUpdated: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.chartUpdated[property] = false
      }
    } else {
      Object.keys(state.chartUpdated).forEach(property => state.chartUpdated[property] = false)
    }
  },
  setChartUpdateError: (state, { properties, error }) => {
    for (const property of properties) {
      state.chartUpdateError[property] = error
    }
  },
  resetChartUpdateError: (state, properties) => {
    if (properties) {
      for (const property of properties) {
        state.chartUpdateError[property] = undefined
      }
    } else {
      Object.keys(state.chartUpdateError).forEach(property => state.chartUpdateError[property] = undefined)
    }
  },
  setChartRemoved: (state, chartRemoved) => {
    state.chartRemoved = chartRemoved
  },
  setRemovingChart: (state, removingChart) => {
    state.removingChart = removingChart
  },
  removeChart: (state, chartId) => {
    const index = state.charts.findIndex(chart => chart.id === chartId)
    if (index >= 0) {
      state.charts.splice(index, 1)
    }
  }
}