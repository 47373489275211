import { defaultTableHeaderClasses } from "@/constants"

export const SCREEN_ADD_FORM_ITEMS = {
  headers: [{
    text : "2322",
    align: "left",
    value: "name",
    class: defaultTableHeaderClasses,
    width: "50%"
  }, {
    text : "2323",
    align: "left",
    value: "fields",
    class: defaultTableHeaderClasses,
    width: "50%"
  }]
}