export default {
  loadingCustomViews: undefined,
  customViews       : new Array(),
  addingCustomView  : false,
  customViewAdded   : false,
  customViewAddError: undefined,
  removingCustomView: undefined,
  customViewRemoved : undefined,
  updatingCustomView: {
    name     : false,
    creatorId: false,
    access   : false
  },
  customViewUpdated: {
    name     : false,
    creatorId: false,
    access   : false
  },
  customViewUpdateError: {
    name     : undefined,
    creatorId: undefined,
    access   : false
  },
  customViewChartFiltersToReload: {
    filterIds   : new Array(),
    customViewId: undefined
  },
  reloadAllCharts: false
}