import ThemisInput from "@/components/shared/input"
import ThemisScreenItemsTable from "@/components/screen-items-table"
import ThemisScreenItemsFormTable from "@/components/screen-items-form-table"
import ThemisReadOnly from "@/components/shared/read-only"
import { mapActions, mapGetters, mapMutations } from "vuex"
import { STATUS_KEYS, MAX_CHARACTER_LIMIT, SCREEN_ITEM_TYPE } from "@/constants"
import { PAGE_TITLE_WITHOUT_TRANSLATION } from "@/constants/page-titles"
import { SCREEN } from "@/constants/bread-crumbs/screen"

export default {
  name      : "Screen",
  components: {
    ThemisInput,
    ThemisScreenItemsTable,
    ThemisScreenItemsFormTable,
    ThemisReadOnly
  },
  data: () => ({
    localScreen                : undefined,
    showAddScreenItemsDialog   : false,
    activeTab                  : null,
    isCancelAddScreenItems     : false,
    issuePropertyItemsToBeAdded: [],
    issueFieldItemsToBeAdded   : [],
    issueFormItemsToBeAdded    : [],
    screenNameCharacterLimit   : MAX_CHARACTER_LIMIT.SCREEN_NAME,
    screenItemsAreDraggable    : false,
    draggingScreenItem         : undefined,
    dragEnterScreenItem        : undefined,
    currentHoveredItem         : undefined
  }),
  computed: {
    ...mapGetters({
      screens                   : "screens/screens",
      screenItems               : "screens/screenItems",
      fieldsV2                  : "fields/fieldsV2",
      formInstances             : "formInstances/formInstances",
      formTemplates             : "formTemplates/formTemplates",
      formTemplateConfigurations: "formTemplateConfigurations/formTemplateConfigurations",
      issueFields               : "issueFields/issueFields",
      isScreenItemsAdded        : "screens/isScreenItemsAdded",
      isAddingScreenItems       : "screens/isAddingScreenItems",
      screenNameUpdateError     : "screens/screenNameUpdateError",
      isUpdatingScreenName      : "screens/isUpdatingScreenName",
      isScreenNameUpdated       : "screens/isScreenNameUpdated",
      isWorkflowsEnabled        : "configurations/isWorkflowsEnabled",
      isSortingOrderUpdated     : "screenItems/isSortingOrderUpdated",
      workflows                 : "workflows/workflows"
    }),
    screenId() {
      return +this.$route.params.id
    },
    screen() {
      return this.screens.find(screen => screen.id === this.screenId)
    },
    fieldsMap() {
      const fieldsMap = new Map()
      for (const field of this.fieldsV2) {
        fieldsMap.set(field.id, field)
      }
      return fieldsMap
    },
    screenWorkflowNameMap() {
      const screenWorkflowNameMap = {}
      this.workflows.forEach(workflow => {
        workflow.transitions.forEach(transition => {
          if (transition.screen) {
            if (!screenWorkflowNameMap[transition.screenId]) {
              screenWorkflowNameMap[transition.screenId] = new Set()
            }
            screenWorkflowNameMap[transition.screenId].add(workflow.name)
          }
        })
      })
      return screenWorkflowNameMap
    },
    isScreenAssociatedWithWorkflow() {
      return !!this.screenWorkflowNameMap[this.screen.id]
    },
    screenItemsForTable() {
      const screenItems = this.screenItems
        .filter(screenItem => screenItem.screenId === this.screenId)
        .filter(screenItem => {
          if (screenItem.type === SCREEN_ITEM_TYPE.FIELD) {
            return this.issueFieldIds.includes(screenItem.field.fieldId)
          }
          return true
        })
        .map(screenItem => {
          return {
            key         : `${screenItem.type}_${screenItem.id}`,
            id          : screenItem.id,
            name        : this.getScreenElementName(screenItem),
            sortingOrder: screenItem.sortingOrder,
            type        : screenItem.type,
            formFields  : screenItem.type === SCREEN_ITEM_TYPE.FORM
              ? this.getFormFieldsForScreenItemRow(screenItem.form) : null
          }
        }).sort((firstScreenItem, secondScreenItem) => firstScreenItem.sortingOrder - secondScreenItem.sortingOrder)
      return screenItems
    },
    issueFieldIds() {
      return this.issueFields.map(issueField => issueField.fieldId)
    },
    itemsForAddFieldsToScreenTable() {
      return this.fieldsV2.filter(field => {
        const existsInScreenItems = this.screenItems.find(item => item.field?.fieldId === field.id)
        return this.issueFieldIds.includes(field.id) && !existsInScreenItems
      })
        .map(field => {
          return {
            id       : field.id,
            name     : field.systemName,
            mandatory: false
          }
        })
    },
    itemsForAddIssuePropertiesToScreenTable() {
      return Object.keys(STATUS_KEYS)
        .filter(key => {
          return !this.screenItems.find(item => item.type === "ISSUE_PROPERTY" && item.issueProperty?.key === key)
        })
        .map(key => {
          return {
            id       : key,
            name     : this.$t(STATUS_KEYS[key]),
            mandatory: false
          }
        })
    },
    itemsForAddFormsToScreenTable() {
      const existingFormTemplateIds = new Set(this.screenItems
        .filter(screenItem => screenItem.type === SCREEN_ITEM_TYPE.FORM)
        .map(screenItem => screenItem.form.formTemplateId)
      )

      return this.formTemplates
        .filter(formTemplate => !existingFormTemplateIds.has(formTemplate.id))
        .map(formTemplate => {
          const fields = this.formTemplateConfigurations
            .filter(config => config.formTemplateId === formTemplate.id)
            .map(config => this.fieldsMap.get(config?.fieldId))

          const uniqueFieldNames = [...new Set(fields.map(field => field?.systemName))]

          return {
            id    : formTemplate.id,
            name  : formTemplate.name,
            fields: uniqueFieldNames.join(", ")
          }
        })
    },
    getSelectedItems() {
      switch (this.activeTab) {
        case 0:
          return this.issuePropertyItemsToBeAdded.length
        case 1:
          return this.issueFieldItemsToBeAdded.length
        case 2:
          return this.issueFormItemsToBeAdded.length
      }
    },
    getTotalItems() {
      switch (this.activeTab) {
        case 0:
          return this.itemsForAddIssuePropertiesToScreenTable.length
        case 1:
          return this.itemsForAddFieldsToScreenTable.length
        case 2:
          return this.itemsForAddFormsToScreenTable.length
      }
    },
    isScreenNameDuplicate() {
      return this.screenNameUpdateError?.type === "duplicate"
    },
    isScreenNameChanged() {
      return this.screen?.name !== this.localScreen?.name
    },
    selectedScreenItem() {
      const screenItems = this.screenItemsForTable
      if (this.$route.params.formId && this.$route.params.itemId) {
        const screenItem = screenItems.find(screenItem => screenItem.id === +this.$route.params.formId)
        return screenItem.formFields.find(formField => formField.id === +this.$route.params.itemId)
      } else {
        return screenItems.find(screenItem => screenItem.id === +this.$route.params.itemId)
      }
    }
  },
  methods: {
    ...mapActions({
      addIssueFields  : "issueFields/addIssueFields",
      notify          : "shared/notify",
      updateIssueField: "issueFields/updateIssueField",
      addScreenItems  : "screens/addScreenItems",
      updateScreen    : "screens/updateScreen",
      updateScreenItem: "screenItems/updateScreenItem"
    }),
    ...mapMutations({
      resetScreenUpdateError: "screens/resetScreenUpdateError",
      setBreadcrumbs        : "shared/setBreadcrumbs",
      setPageTitle          : "shared/setPageTitle"
    }),
    navigateToScreenItemConfiguration(item, event) {
      event?.stopPropagation()
      const params = {
        id      : this.screenId,
        itemId  : item.id,
        parentId: item.parent ? item.parent.id : null
      }
      if (item?.parent) {
        if (+this.$route.params?.itemId !== item.id || +this.$route.params?.formId !== item.parent.id) {
          params.formId = item.parent.id
          this.$router.push({ name: "screen-item-side-panel-with-form", params })
        }
      } else {
        if (+this.$route.params?.itemId !== item.id || +this.$route.params?.formId) {
          this.$router.push({ name: "screen-item-side-panel", params })
        }
      }
    },
    getScreenElementName(screenElement) {
      switch (screenElement.type) {
        case SCREEN_ITEM_TYPE.FIELD:
          return this.fieldsMap.get(screenElement.field.fieldId)?.systemName
        case SCREEN_ITEM_TYPE.ISSUE_PROPERTY:
          return this.$t(STATUS_KEYS[screenElement.issueProperty.key])
        case SCREEN_ITEM_TYPE.FORM:
          return this.formTemplates.find(formTemplate => formTemplate.id === screenElement.form.formTemplateId)?.name
      }
    },
    getFormFieldsForScreenItemRow(form) {
      return form?.formFields.map(formField => {
        const formTemplateConfiguration = this.formTemplateConfigurations.find(formTemplateConfiguration => {
          return formTemplateConfiguration.id === formField.formTemplateConfigurationId
        })
        return {
          key      : `field_of_form_${formField.id}`,
          id       : formField.id,
          name     : this.fieldsMap.get(formTemplateConfiguration?.fieldId)?.systemName,
          mandatory: formField.mandatory
        }
      })
    },
    getClassForScreenItemRow(item) {
      let classForScreenItemRow = ""
      if (item.key === this.selectedScreenItem?.key) {
        classForScreenItemRow = "blue lighten-5 "
      } else if (item.key === this.currentHoveredItem) {
        classForScreenItemRow = "grey lighten-3 "
      } else {
        classForScreenItemRow = "white "
      }

      if (item.id === this.dragEnterScreenItem?.id &&
        item.id !== this.draggingScreenItem?.id) {
        if (this.dragEnterScreenItem?.sortingOrder < this.draggingScreenItem?.sortingOrder) {
          classForScreenItemRow += "drop-row-down"
        } else {
          classForScreenItemRow += "drop-row-up"
        }
      }
      return classForScreenItemRow
    },
    currentlyHoveredOver(item, event) {
      event?.stopPropagation()
      this.currentHoveredItem = item?.key ?? undefined
      if (item) {
        this.screenItemsAreDraggable = true
      } else {
        this.screenItemsAreDraggable = false
      }
    },
    isHovered(item) {
      return item.key === this.currentHoveredItem
    },
    async cancelAddFieldsToIssueHandler() {
      this.showAddScreenItemsDialog    = false
      this.isCancelAddScreenItems      = true
      this.issuePropertyItemsToBeAdded = []
      this.issueFieldItemsToBeAdded    = []
      this.issueFormItemsToBeAdded     = []
      this.activeTab                   = null
      await this.$nextTick()
      this.isCancelAddScreenItems = false
    },
    issuePropertiesToBeAdded(items) {
      this.issuePropertyItemsToBeAdded = items
    },
    issueFieldsToBeAdded(items) {
      this.issueFieldItemsToBeAdded = items
    },
    issueFormsToBeAdded(items) {
      this.issueFormItemsToBeAdded = items
    },
    handleAddScreenItems() {
      const screenItems = []

      if (this.issuePropertyItemsToBeAdded.length) {
        this.issuePropertyItemsToBeAdded.forEach(item => {
          screenItems.push({
            type         : SCREEN_ITEM_TYPE.ISSUE_PROPERTY,
            issueProperty: {
              key      : item.id,
              mandatory: item.mandatory
            }
          })
        })
      }

      if (this.issueFieldItemsToBeAdded.length) {
        this.issueFieldItemsToBeAdded.forEach(item => {
          screenItems.push({
            type : SCREEN_ITEM_TYPE.FIELD,
            field: {
              fieldId  : item.id,
              mandatory: item.mandatory
            }
          })
        })
      }

      if (this.issueFormItemsToBeAdded.length) {
        this.issueFormItemsToBeAdded.forEach(item => {
          screenItems.push({
            type: SCREEN_ITEM_TYPE.FORM,
            form: {
              formTemplateId: item.id
            }
          })
        })
      }
      this.addScreenItems({ screenId: this.screen.id, screenItems })
    },
    handleScreenNameOnBlurEvent(onBlur) {
      onBlur()
      this.verifyAndUpdateScreenName()
    },
    verifyAndUpdateScreenName() {
      if (this.isScreenNameChanged) {
        if (this.localScreen.name &&
          this.localScreen.name.length <= this.screenNameCharacterLimit) {
          this.updateScreen({
            id  : this.localScreen.id,
            name: this.localScreen.name
          })
        }
      }
    },
    handleScreenNameInputOnEnter() {
      this.$refs.input_screen_name.blur()
    },
    screenItemDragStart(screenItem) {
      this.draggingScreenItem  = screenItem
      this.dragEnterScreenItem = this.draggingScreenItem
    },
    screenItemDragEnter(screenItem) {
      this.dragEnterScreenItem = screenItem
    },
    onScreenItemDragOver(event) {
      event.preventDefault()
    },
    onScreenItemDragEnd() {
      if (this.draggingScreenItem.id !== this.dragEnterScreenItem.id) {
        const isScreenItemDraggedUp =
          !!(this.draggingScreenItem.sortingOrder - this.dragEnterScreenItem.sortingOrder)

        const sortingOrder = isScreenItemDraggedUp ?
          this.dragEnterScreenItem.sortingOrder : this.dragEnterScreenItem.sortingOrder - 1

        this.updateScreenItem({
          id: this.draggingScreenItem.id,
          sortingOrder
        })
      }
    }
  },
  watch: {
    screen: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.localScreen) {
          this.localScreen = { ...newValue }
        }
      }
    },
    isScreenItemsAdded: {
      handler: function(value) {
        if (value) {
          this.notify({
            type: "success",
            text: "1521"
          })
          this.showAddScreenItemsDialog = false
          this.cancelAddFieldsToIssueHandler()
        }
      }
    },
    showAddScreenItemsDialog: {
      handler: function(value) {
        if (!value) {
          this.fieldsToBeAdded = []
        }
      }
    },
    "localScreen.name": {
      immediate: false,
      handler  : function() {
        if (this.screenNameUpdateError) {
          this.resetScreenUpdateError(["name"])
        }
      }
    },
    isScreenNameUpdated: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.setBreadcrumbs(
            SCREEN({ params: { id: this.localScreen.id } }, this.localScreen.name
            )
          )
          this.setPageTitle(PAGE_TITLE_WITHOUT_TRANSLATION(this.localScreen.name))
          this.notify({
            type: "success",
            text: "1521"
          })
        }
      }
    },
    isSortingOrderUpdated: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "1521"
          })
          this.draggingScreenItem  = undefined
          this.dragEnterScreenItem = undefined
        }
      }
    }
  }
}