import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/custom-views"

export default {
  /**
   * This action will load custom views.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load custom views.
   */
  async loadCustomViews(context, payload) {
    context.commit("setLoadingCustomViews", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getCustomViewsResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getCustomViewsResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateCustomViews", [getCustomViewsResponse.data])
      } else {
        context.commit("setCustomViews", getCustomViewsResponse.data)
      }
    }

    context.commit("setLoadingCustomViews", false)
  },

  /**
   * This action will add a custom view.
   * @param {*} context is the store.
   */
  async addCustomView(context, payload) {
    context.commit("setAddingCustomView", true)
    context.commit("setCustomViewAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postCustomViewResponse = await httpClient.post(undefined, payload)

    if (postCustomViewResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateCustomViews", [postCustomViewResponse.data])
      context.commit("setCustomViewAdded", true)
    } else if (postCustomViewResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setCustomViewAddError", postCustomViewResponse.data)
    }

    context.commit("setAddingCustomView", false)
  },

  /**
   * This action will load a custom view.
   * @param {*} context is the store.
   * @param {*} payload is the id of the custom view to be loaded.
   */
  async loadCustomView(context, payload) {
    context.commit("setLoadingCustomViews", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getCustomViewResponse = await httpClient.get(`/${payload.id}`)

    if (getCustomViewResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateCustomViews", [getCustomViewResponse.data])
    }

    context.commit("setLoadingCustomViews", false)
  },

  /**
   * This action updates name of a custom view.
   * @param {*} context is the store.
   * @param {*} customView id and name of a custom view.
   */
  async updateCustomView(context, customView) {
    const id = customView.id
    delete customView.id
    const propertiesToBeUpdated = Object.keys(customView)
    for (const property of propertiesToBeUpdated) {
      if (customView[property] === undefined) {
        customView[property] = null
      }
    }
    context.commit("setUpdatingCustomView", propertiesToBeUpdated)
    context.commit("resetCustomViewUpdated", propertiesToBeUpdated)
    context.commit("resetCustomViewUpdateError", propertiesToBeUpdated)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const updateCustomViewResponse = await httpClient.put(`/${id}`, customView)

    if (updateCustomViewResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      if (customView.groups) {
        const groups      = context.rootGetters["groups/groups"]
        customView.groups = customView.groups.map(groupId =>
          groups.find(group => group.id === groupId)
        )
      }
      context.commit("updateCustomViews", [{
        id,
        ...customView
      }])
      context.commit("setCustomViewUpdated", propertiesToBeUpdated)
    } else if (updateCustomViewResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setCustomViewUpdateError", {
        properties: propertiesToBeUpdated,
        error     : updateCustomViewResponse.data
      })
    } else if (updateCustomViewResponse.status === HTTP_STATUS_CODE.BAD_REQUEST){
      context.commit("setCustomViewUpdateError", {
        properties: propertiesToBeUpdated,
        error     : updateCustomViewResponse.data
      })
    }

    context.commit("resetUpdatingCustomView", propertiesToBeUpdated)
  },

  /**
   * This action removes a particular custom view.
   * @param {*} context is the store.
   * @param {*} payload contains id of the custom view.
   */
  async removeCustomView(context, payload) {
    context.commit("setRemovingCustomView", true)
    context.commit("setCustomViewRemoved", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const deleteCustomViewResponse = await httpClient.delete(`/${payload}`)

    if (deleteCustomViewResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("removeCustomView", payload)
      context.commit("setCustomViewRemoved", true)
    }

    context.commit("setRemovingCustomView", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setCustomViews", new Array())
    context.commit("setLoadingCustomViews", undefined)
    context.commit("setAddingCustomView", false)
    context.commit("setCustomViewAdded", false)
    context.commit("setCustomViewAddError", undefined)
    context.commit("setRemovingCustomView", false)
    context.commit("setCustomViewRemoved", false)
    context.commit("resetUpdatingCustomView")
    context.commit("resetCustomViewUpdated")
    context.commit("resetCustomViewUpdateError")
    context.commit("setCustomViewChartFiltersToReload", {
      filterIds   : new Array(),
      customViewId: undefined
    })
    context.commit("resetCustomViewChartFiltersToReload")
    context.commit("setReloadAllCharts", false)
    context.commit("resetReloadAllCharts")
  }
}
