import store from "@/plugins/vuex"
import { ISSUE_FORM } from "@/constants/bread-crumbs/issue-form"
import { PAGE_TITLE_WITHOUT_TRANSLATION } from "@/constants/page-titles"

/**
 * This method will be called before navigating to issue form page.
 */
export const beforeIssueForm = async (to, from) => {

  if (!["issue-form", "issue-form-field-configuration"].includes(from.name)) {
    store.commit("shared/setProgressBarInitiated", true)
    store.commit("shared/setProgressBarPromisesPending", true)
    await store.dispatch("formTemplates/loadFormTemplate", +to.params.id)
    const formTemplates = store.getters["formTemplates/formTemplates"]
    const formTemplate  = formTemplates.find(formTemplate => formTemplate.id === +to.params.id)

    if ((!formTemplate || formTemplate.reportForm)) {
      return "not-found"
    }

    store.commit("shared/setPageTitle", PAGE_TITLE_WITHOUT_TRANSLATION(formTemplate.name))
    store.commit("shared/setBreadcrumbs", ISSUE_FORM(to, formTemplate.name))

    const loadFieldsV2                   = store.dispatch("fields/loadFieldsV2")
    const loadOptionLists                = store.dispatch("optionLists/loadOptionLists")
    const loadFormTemplateConfigurations = store.dispatch("formTemplateConfigurations/loadFormTemplateConfigurations", { formTemplateId: +to.params.id })
    if (to.name === "issue-form-field-configuration") {
      await loadFormTemplateConfigurations
    }
    Promise.all([loadFieldsV2, loadOptionLists, loadFormTemplateConfigurations]).then(() => {
      store.commit("shared/setProgressBarInitiated", false)
      store.commit("shared/setProgressBarPromisesPending", false)
    })
  }

  if (to.name === "issue-form-field-configuration") {
    const formTemplateConfigurations = store.getters["formTemplateConfigurations/formTemplateConfigurations"]
    const formTemplateConfiguration  = formTemplateConfigurations.find(configuration =>
      configuration.formTemplateId === +to.params.id &&
      configuration.id === +to.params.configurationId
    )

    if (!formTemplateConfiguration) {
      return {
        name  : "issue-form",
        params: { id: +to.params.id }
      }
    }
  }
}