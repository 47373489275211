export default {
  setLoadingIssueSearch: (state, loadingIssueSearch) => {
    state.loadingIssueSearch = loadingIssueSearch
  },
  setIssueSearch: (state, issueSearch) => {
    state.issueSearch = issueSearch
  },
  setFilterIdWithIssueIds: (state, filterIdWithIssueIds) => {
    state.filterIdWithIssueIds = filterIdWithIssueIds
  },
  updateFilterIdWithIssueIds: (state, filterIdWithIssueIds) => {
    for (const item of filterIdWithIssueIds) {
      const index = state.filterIdWithIssueIds.findIndex(stateItems => stateItems?.filterId === item.filterId)
      if (index >= 0) {
        state.filterIdWithIssueIds.splice(index, 1, { ...state.filterIdWithIssueIds[index], ...item })
      } else {
        state.filterIdWithIssueIds.push(item)
      }
    }
  },
  setNotAccessibleSavedFilterIds: (state, notAccessibleSavedFilterIds) => {
    state.notAccessibleSavedFilterIds.push(notAccessibleSavedFilterIds)
  }
}