export default {
  loggedInUserPolicies              : new Object(),
  issueCommentRemovePolicies        : new Array(),
  issueCommentAddPolicies           : new Array(),
  issueUpdatePolicies               : new Array(),
  issueViewPolicies                 : new Array(),
  issueAddDocumentsPolicies         : new Array(),
  issueDocumentRemovePolicies       : new Array(),
  reportViewPolicies                : new Array(),
  reportUpdatePolicies              : new Array(),
  reportContentClearPolicies        : new Array(),
  reportAddMessagesPolicies         : new Array(),
  messageUpdatePolicies             : new Array(),
  messageRemovePolicies             : new Array(),
  messageItemAddTranslationsPolicies: new Array(),
  translationUpdatePolicies         : new Array(),
  issueAddFormInstancesPolicies     : new Array(),
  formInstanceUpdatePolicies        : new Array(),
  formInstanceRemovePolicies        : new Array(),
  issueUpdateIssueFieldValuesPolices: new Array(),
  issuePrintPolicies                : new Array(),
  issueLinkAddPolicies              : new Array(),
  issueLinkRemovePolicies           : new Array(),
  issueAddTaskPolicies              : new Array(),
  issueAddFoldersPolicies           : new Array(),
  issueDocumentUpdatePolicies       : new Array(),
  messageItemUpdatePolicies         : new Array(),
  folderUpdatePolicies              : new Array(),
  folderRemovePolicies              : new Array(),
  issueViewLogsPolicies             : new Array(),
  accessViewPolicies                : new Array(),
  savedFilterRemovePolicies         : new Array(),
  savedFilterUpdatePolicies         : new Array(),
  customViewUpdatePolicies          : new Array()
}