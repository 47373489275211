import store from "@/plugins/vuex"
import { PAGE_TITLE_WITHOUT_TRANSLATION } from "@/constants/page-titles"
import { SCREEN } from "@/constants/bread-crumbs/screen"
import { BOOLEAN } from "@/constants"

export const beforeScreen = async (to, from) => {
  if ((from.name === "screen" || from.name === "screen-item-side-panel" || from.name === "screen-item-side-panel-with-form") &&
    (to.name === "screen-item-side-panel" || to.name === "screen-item-side-panel-with-form")) {
    return
  } else if ((from.name === "screen-item-side-panel" || from.name === "screen-item-side-panel-with-form") && to.name === "screen") {
    return
  } else {
    store.commit("shared/setProgressBarInitiated", true)
    store.commit("shared/setProgressBarPromisesPending", true)
    await store.dispatch("screens/loadScreen", +to.params.id)
    const screens = store.getters["screens/screens"]
    const screen  = screens.find(screen => screen.id === +to.params.id)
    if (!screen) {
      return "not-found"
    }
    const loadScreenItemsPromise = store.dispatch("screens/loadScreenItems", {
      screenId: +to.params.id
    })

    if (to.name === "screen-item-side-panel" || to.name === "screen-item-side-panel-with-form") {
      await loadScreenItemsPromise
      const screenItems         = store.getters["screens/screenItems"]
      const screenItemElement   = screenItems.find(item => item.id === +to.params.itemId)
      const screenItemFormField = screenItems.find(screenItem => {
        if (screenItem.type === "FORM") {
          return screenItem.form.formFields.find(field =>
            field.id === +to.params.itemId)
        }
      })
      if ( +to.params.itemId && +to.params.formId ) {
        if ( !screenItemFormField ) {
          return "not-found"
        }
      } else {
        if ( !screenItemElement ) {
          return "not-found"
        }
      }
    }
    const loadIssueFields = store.dispatch("issueFields/loadIssueFields")
    store.commit("shared/setPageTitle", PAGE_TITLE_WITHOUT_TRANSLATION(screen.name))
    store.commit("shared/setBreadcrumbs", SCREEN(to, screen.name))
    const loadOptionLists                = store.dispatch("optionLists/loadOptionLists")
    const loadFieldsV2                   = store.dispatch("fields/loadFieldsV2")
    const loadFormTemplates              = store.dispatch("formTemplates/loadFormTemplates", {
      reportForm: BOOLEAN.FALSE
    })
    const loadFormInstances              = store.dispatch("formInstances/loadFormInstances")
    const loadFormTemplateConfigurations = store.dispatch("formTemplateConfigurations/loadFormTemplateConfigurations")
    Promise.all([
      loadIssueFields,
      loadOptionLists,
      loadFieldsV2,
      loadFormTemplates,
      loadFormInstances,
      loadFormTemplateConfigurations
    ])
      .then(() => {
        store.commit("shared/setProgressBarInitiated", false)
        store.commit("shared/setProgressBarPromisesPending", false)
      })
  }
}