import ThemisCascadedInput from "@/components/shared/cascaded-input"
import ThemisSaveFilter from "@/components/save-filter"
import ThemisDecision from "@/components/shared/decision"
import { ISSUE_TYPES } from "@/constants"
import { FIELD_TYPES } from "@/constants"
import moment from "moment"

export default {
  name      : "IssueFilters",
  components: {
    ThemisCascadedInput,
    ThemisSaveFilter,
    ThemisDecision
  },
  data() {
    return {
      selectedIssueStatuses             : new Array(),
      selectedIssueTypes                : new Array(),
      selectedIssueResolutions          : new Array(),
      selectedDomains                   : new Array(),
      selectedAssignees                 : new Array(),
      selectedIssueIds                  : new Array(),
      numberOfItemsInSelectionText      : 1,
      numberOfItemsInAdvancedFilters    : 2,
      selectedDates                     : new Array(),
      selectedUpdatedAtDates            : new Array(),
      selectedDueDates                  : new Array(),
      showDateRangeMenu                 : false,
      showDueDateRangeMenu              : false,
      showUpdatedAtRangeMenu            : false,
      showAdvancedButton                : true,
      selectedAdvancedFilters           : new Array(),
      formFields                        : {},
      issueFields                       : {},
      selectedLabels                    : new Array(),
      selectedChannels                  : new Array(),
      selectedReportStatuses            : new Array(),
      selectedStatusCategories          : new Array(),
      selectedIssueAnonymisationStatuses: new Array(),
      selectedReportSource              : new Array(),
      selectedReportLanguage            : new Array(),
      selectedMessageStatuses           : new Array(),
      selectedInitialResponseStatuses   : new Array(),
      isClearingAllFilters              : false,
      fieldsMap                         : {},
      showSaveFilterDialog              : false,
      isFilterNameDuplicate             : false,
      showUpdateFilterDialog            : false,
      isSavingExistingFilterAsNew       : false
    }
  },
  props: {
    pIssues                     : Array,
    pIssueIds                   : Array,
    pDomains                    : Array,
    pIssueStatuses              : Array,
    pIssueResolutions           : Array,
    pUsers                      : Array,
    pAdvancedFilters            : Array,
    pLabels                     : Array,
    pChannels                   : Array,
    pReportStatuses             : Array,
    pIssueAnonymisationStatuses : Array,
    pFields                     : Array,
    pOptionListItems            : Array,
    pUserIssuesFiltersPreference: Object,
    pReportSources              : Array,
    pStatusCategories           : Array,
    pLanguages                  : Array,
    pIssueFields                : Array,
    pIssueTypes                 : Array,
    pMessageStatuses            : Array,
    pInitialResponseStatuses    : Array,
    pIsIssuesPage               : Boolean,
    pFilterAddError             : Object,
    pIsAddingFilter             : Boolean,
    pIsFilterAdded              : Boolean,
    pCanUpdateFilter            : Boolean,
    pShowFilterUpdateOption     : Boolean,
    pIsFilterCriteriaChanged    : Boolean,
    pFilter                     : Object,
    pLoggedInUser               : Object,
    pIsUpdatingCriteria         : Boolean,
    pIsCriteriaUpdated          : Boolean,
    pCanAddFilter               : Boolean,
    pFilterPreference           : Object,
    pFormTemplates              : Array
  },
  computed: {
    /**
     * returns only the shortlisted form fields from advanced search drop down
     */
    selectedFormFields() {
      const selectedFormFields = []
      if (this.pFields) {
        for (const field of this.pFields) {
          let fieldValues               = []
          const filteredOptionListItems = this.pOptionListItems.filter(optionList =>
            optionList.optionListId === field.optionListId)
          const isCascaded              = !!filteredOptionListItems.find(optionListItem =>
            optionListItem.parentId)?.parentId
          const selectedAdvancedFilters = this.selectedAdvancedFilters.filter(filter =>
            filter.key === `reporter-intake-forms-${field.id}`
          )
          for (const selectedAdvancedFilter of selectedAdvancedFilters) {
            if (field.type === FIELD_TYPES.BOOLEAN.value) {
              fieldValues = [this.$t("1782"), this.$t("1781")]
            } else if (isCascaded) {
              fieldValues = this.cascadedOptionListItems.filter(optionListItem =>
                optionListItem.optionListId === field.optionListId)
            } else {
              fieldValues = this.pOptionListItems
                .filter(optionListItem => optionListItem.optionListId === field.optionListId)
                .map(optionListItem => optionListItem.name)
            }
            if (this.formFields[`${selectedAdvancedFilter.formTemplateId}-${field.id}`]) {
              for (const value of this.formFields[`${selectedAdvancedFilter.formTemplateId}-${field.id}`]) {
                const isFieldValueAlreadyPresent = filteredOptionListItems.find(optionList =>
                  optionList.name === value)
                if (!isFieldValueAlreadyPresent) {
                  if (isCascaded) {
                    fieldValues.push({ name: value, children: [] })
                  } else {
                    fieldValues.push(value)
                  }
                }
              }
            }
            selectedFormFields.push({
              id            : field.id,
              key           : `${selectedAdvancedFilter.formTemplateId}-${field.id}`,
              label         : this.$t("2254", { fieldName: field.systemName, formName: selectedAdvancedFilter.formName }),
              systemName    : field.systemName,
              values        : fieldValues,
              tableColumn   : this.$CONSTANTS.ISSUE_SEARCH_FILTERS.FORM_TEMPLATE,
              formTemplateId: selectedAdvancedFilter.formTemplateId,
              type          : `reporter-intake-forms-${field.id}-${selectedAdvancedFilter.formTemplateId}`,
              isCascaded
            })
          }
        }
      }
      return selectedFormFields
    },
    selectedIssueFields() {
      const selectedIssueFields = []
      if (this.pIssueFields) {
        for (const issueField of this.pIssueFields) {
          let fieldValues = []
          const field     = this.fieldsMap[issueField.fieldId]
          if (field) {
            const filteredOptionListItems = this.pOptionListItems.filter(optionList =>
              optionList.optionListId === field.optionListId)
            const isCascaded              = !!filteredOptionListItems.find(optionListItem =>
              optionListItem.parentId)?.parentId
            if (this.selectedAdvancedFilters.find(filter => filter.key === `issue-fields-${field.id}`)) {
              if (field.type === FIELD_TYPES.BOOLEAN.value) {
                fieldValues = [this.$t("1782"), this.$t("1781")]
              } else if (isCascaded) {
                fieldValues = this.cascadedOptionListItems.filter(optionListItem =>
                  optionListItem.optionListId === field.optionListId)
              } else {
                fieldValues = this.pOptionListItems
                  .filter(optionListItem => optionListItem.optionListId === field.optionListId)
                  .map(optionListItem => optionListItem.name)
              }
              if (this.issueFields[field.systemName]) {
                for (const value of this.issueFields[field.systemName]) {
                  const isFieldValueAlreadyPresent = filteredOptionListItems.find(optionList =>
                    optionList.name === value
                  )
                  if (!isFieldValueAlreadyPresent) {
                    if (isCascaded) {
                      fieldValues.push({ name: value, children: [] })
                    } else {
                      fieldValues.push(value)
                    }
                  }
                }
              }
              selectedIssueFields.push({
                id         : issueField.id,
                key        : `issue-fields-${field.id}`,
                systemName : field.systemName,
                values     : fieldValues,
                tableColumn: this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_FIELD,
                isCascaded
              })
            }
          }
        }
      }
      return selectedIssueFields
    },
    selectedIssueFormFields() {
      const selectedIssueFormFields = []
      if (this.pFields) {
        for (const field of this.pFields) {
          let fieldValues               = []
          const filteredOptionListItems = this.pOptionListItems.filter(optionList =>
            optionList.optionListId === field.optionListId)
          const isCascaded              = !!filteredOptionListItems.find(optionListItem =>
            optionListItem.parentId)?.parentId
          const selectedAdvancedFilters = this.selectedAdvancedFilters.filter(filter =>
            filter.key === `issue-forms-${field.id}`
          )
          for (const selectedAdvancedFilter of selectedAdvancedFilters) {
            if (field.type === FIELD_TYPES.BOOLEAN.value) {
              fieldValues = [this.$t("1782"), this.$t("1781")]
            } else if (isCascaded) {
              fieldValues = this.cascadedOptionListItems.filter(optionListItem =>
                optionListItem.optionListId === field.optionListId)
            } else {
              fieldValues = this.pOptionListItems
                .filter(optionListItem => optionListItem.optionListId === field.optionListId)
                .map(optionListItem => optionListItem.name)
            }
            if (this.formFields[`${selectedAdvancedFilter.formTemplateId}-${field.id}`]) {
              for (const value of this.formFields[`${selectedAdvancedFilter.formTemplateId}-${field.id}`]) {
                const isFieldValueAlreadyPresent = filteredOptionListItems.find(optionList =>
                  optionList.name === value)
                if (!isFieldValueAlreadyPresent) {
                  if (isCascaded) {
                    fieldValues.push({ name: value, children: [] })
                  } else {
                    fieldValues.push(value)
                  }
                }
              }
            }
            selectedIssueFormFields.push({
              id            : field.id,
              key           : `${selectedAdvancedFilter.formTemplateId}-${field.id}`,
              label         : this.$t("2254", { fieldName: field.systemName, formName: selectedAdvancedFilter.formName }),
              systemName    : field.systemName,
              values        : fieldValues,
              tableColumn   : this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_FORM,
              formTemplateId: selectedAdvancedFilter.formTemplateId,
              type          : `issue-forms-${field.id}-${selectedAdvancedFilter.formTemplateId}`,
              isCascaded
            })
          }
        }
      }
      return selectedIssueFormFields
    },
    sortedAdvancedFilters() {
      const advancedFilters = [...this.pAdvancedFilters]
      if (this.selectedAdvancedFilters.length) {
        for (const selectedAdvancedFilter of this.selectedAdvancedFilters) {
          const advancedFilterIndex = advancedFilters.findIndex(advancedFilter =>
            advancedFilter.value === selectedAdvancedFilter.value)
          if (advancedFilterIndex) {
            advancedFilters.splice(advancedFilterIndex, 1)
          }
        }
        return [...this.selectedAdvancedFilters, ...advancedFilters]
      }
      return advancedFilters
    },
    /**
     * maintains mapping of advanced filter to its associated data variable
     */
    advancedFilters() {
      const advancedFilters                                                            = {}
      advancedFilters[this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DOMAIN[0]]                  = "selectedDomains"
      advancedFilters[this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ASSIGNEE[0]]                = "selectedAssignees"
      advancedFilters[this.$CONSTANTS.ISSUE_SEARCH_FILTERS.CHANNEL[0]]                 = "selectedChannels"
      advancedFilters[this.$CONSTANTS.ISSUE_SEARCH_FILTERS.LABEL[0]]                   = "selectedLabels"
      advancedFilters[this.$CONSTANTS.ISSUE_SEARCH_FILTERS.REPORT_STATUS[0]]           = "selectedReportStatuses"
      advancedFilters[this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_ANONYMISATION[0]]     = "selectedIssueAnonymisationStatuses"
      advancedFilters[this.$CONSTANTS.ISSUE_SEARCH_FILTERS.SOURCE[0]]                  = "selectedReportSource"
      advancedFilters[this.$CONSTANTS.ISSUE_SEARCH_FILTERS.LANGUAGE[0]]                = "selectedReportLanguage"
      advancedFilters[this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DATE_RANGE[0]]              = "selectedDates"
      advancedFilters[this.$CONSTANTS.ISSUE_SEARCH_FILTERS.UPDATED_AT[0]]              = "selectedUpdatedAtDates"
      advancedFilters[this.$CONSTANTS.ISSUE_SEARCH_FILTERS.RESOLUTION[0]]              = "selectedIssueResolutions"
      advancedFilters[this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DUE_DATE[0]]                = "selectedDueDates"
      advancedFilters[this.$CONSTANTS.ISSUE_SEARCH_FILTERS.MESSAGE_STATUS[0]]          = "selectedMessageStatuses"
      advancedFilters[this.$CONSTANTS.ISSUE_SEARCH_FILTERS.INITIAL_RESPONSE_STATUS[0]] = "selectedInitialResponseStatuses"
      advancedFilters[this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_STATUS_CATEGORY[0]]   = "selectedStatusCategories"
      return advancedFilters
    },
    optionListItemsMap() {
      const optionListItemsMap = new Object()
      for (const optionListItem of this.pOptionListItems) {
        optionListItemsMap[optionListItem.id] = { ...optionListItem, children: [] }
      }
      return optionListItemsMap
    },
    cascadedOptionListItems() {
      const cascadedOptionListItems = []

      for (const optionListItem of this.pOptionListItems) {
        const parent = this.optionListItemsMap[optionListItem.parentId]
        if (parent) {
          parent.children.push(this.optionListItemsMap[optionListItem.id])
        } else {
          cascadedOptionListItems.push(this.optionListItemsMap[optionListItem.id])
        }
      }
      return cascadedOptionListItems
    },
    showClearAllFilters() {
      return this.pIsIssuesPage && Object.keys(this.pUserIssuesFiltersPreference).length
    },
    showSaveFilter() {
      return this.pIsIssuesPage && Object.keys(this.pUserIssuesFiltersPreference).length
        && this.pCanAddFilter
    },
    showFilterActions() {
      return this.showClearAllFilters || this.showSaveFilter || this.showUpdateFilterButton
    },
    showUpdateFilterButton() {
      return this.pIsFilterCriteriaChanged && !this.pIsIssuesPage && this.pCanAddFilter
    },
    showFilterUpdateOption() {
      return this.pFilter.creatorId === this.pLoggedInUser.id && this.pCanUpdateFilter
    },
    showSaveAsNewFilterOption() {
      return this.pCanAddFilter
    }
  },
  methods: {
    dateRangeText(selectedDates) {
      const formattedDates = []
      for (const selectedDate of selectedDates) {
        const dateFields    = moment(new Date(selectedDate)).format("YYYY-MM-DD").split("-")
        const formattedDate = [dateFields[2], dateFields[1], dateFields[0]].join("-")
        formattedDates.push(formattedDate)
      }
      return formattedDates.join(" ~ ")
    },
    saveIssueField(issueField) {
      const fieldId      = this.pIssueFields.find(pIssueField => pIssueField.id === issueField.id)?.fieldId
      const fieldType    = this.fieldsMap[fieldId].type
      const valuesToEmit = []
      if (fieldType === FIELD_TYPES.BOOLEAN.value) {
        for (const value of this.issueFields[issueField.systemName]) {
          if (value === this.$t("1781")) {
            valuesToEmit.push("true")
          }
          if (value === this.$t("1782")) {
            valuesToEmit.push("false")
          }
        }
      } else {
        valuesToEmit.push(...this.issueFields[issueField.systemName])
      }
      this.$emit("filter", [issueField.systemName, issueField.tableColumn, { "issueFieldId": [issueField.id] }], valuesToEmit)
    },
    saveFormField(field) {
      const fieldType  = this.fieldsMap[field.id].type
      const key        = `${field.formTemplateId}-${field.id}`
      let valuesToEmit = []
      if (fieldType === FIELD_TYPES.BOOLEAN.value) {
        for (const value of this.formFields[key]) {
          if (value === this.$t("1781")) {
            valuesToEmit.push("true")
          }
          if (value === this.$t("1782")) {
            valuesToEmit.push("false")
          }
        }
      } else {
        valuesToEmit = this.formFields[key]
      }

      this.$emit("filter", [key, field.tableColumn, { "fieldId": [field.id], "formTemplateId": field.formTemplateId }], valuesToEmit)
    },
    removeIssueTypeChip(index) {
      this.selectedIssueTypes = this.selectedIssueTypes.filter((_, i) => i !== index)
    },
    removeIssueStatusChip(index) {
      this.selectedIssueStatuses = this.selectedIssueStatuses.filter((_, i) => i !== index)
    },
    removeIssueResolutionChip(index) {
      this.selectedIssueResolutions = this.selectedIssueResolutions.filter((_, i) => i !== index)
    },
    removeSelectedAdvancedFilter(index) {
      this.selectedAdvancedFilters = this.selectedAdvancedFilters.filter((_, i) => i !== index)
    },
    removeIssueIdChip(index) {
      this.selectedIssueIds = this.selectedIssueIds.filter((_, i) => i !== index)
    },
    isIssueTypeCase(item) {
      return item.value === ISSUE_TYPES[0].id
    },
    isIssueTypeTask(item) {
      return item.value === ISSUE_TYPES[1].id
    },
    selectedFormFieldValues(items, field) {
      const key = `${field.formTemplateId}-${field.id}`
      delete this.formFields[key]
      this.$set(this.formFields, field.key, items)
      this.$emit("filter", [field.key, field.tableColumn, { "fieldId": [field.id], "formTemplateId": field.formTemplateId }], this.formFields[key])
    },
    selectedIssueFieldValues(items, field) {
      delete this.issueFields[field.systemName]
      this.$set(this.issueFields, field.systemName, items)
      this.$emit("filter", [field.systemName, field.tableColumn, { "issueFieldId": [field.id] }], this.issueFields[field.systemName])
    },
    /**
     * utility to ensure date range selected in calendar is maintained properly in associated model
     */
    handleInput() {
      if (this.selectedDates.length === 2) {
        const fromDate = new Date(this.selectedDates[0])
        const toDate   = new Date(this.selectedDates[1])
        if (toDate.getTime() < fromDate.getTime()) {
          this.selectedDates = [this.selectedDates[1], this.selectedDates[0]]
        }
        this.showDateRangeMenu = false
      }
    },
    handleUpdatedAtInput() {
      if (this.selectedUpdatedAtDates.length === 2) {
        const fromDate = new Date(this.selectedUpdatedAtDates[0])
        const toDate   = new Date(this.selectedUpdatedAtDates[1])
        if (toDate.getTime() < fromDate.getTime()) {
          this.selectedUpdatedAtDates = [this.selectedUpdatedAtDates[1], this.selectedUpdatedAtDates[0]]
        }
        this.showUpdatedAtRangeMenu = false
      }
    },
    handleDueDateInput() {
      if (this.selectedDueDates.length === 2) {
        const fromDate = new Date(this.selectedDueDates[0])
        const toDate   = new Date(this.selectedDueDates[1])
        if (toDate.getTime() < fromDate.getTime()) {
          this.selectedDueDates = [this.selectedDueDates[1], this.selectedDueDates[0]]
        }
        this.showDueDateRangeMenu = false
      }
    },
    /**
     * clears the selected filter search criteria
     * @param {*} selectedFilter - filter data variable that has to be cleared
     */
    clearFilter(selectedFilter) {
      this[selectedFilter] = []
    },
    /**
     * removes the searched advance filter
     * @param {*} entity - entity that has to be excluded from filter.
     * @param {*} selectedFilter - data variable of the associated filter
     */
    removeAdvancedFilter(entity, selectedFilter) {
      this.clearFilter(selectedFilter)
      const index = this.selectedAdvancedFilters.findIndex(filter => filter.key === entity)
      this.selectedAdvancedFilters.splice(index, 1)
    },
    /**
     * clears the filtered form field values
     * @param {*} filter - removes previously shortlisted search values of form field.
     */
    clearFormFieldFilter(filter) {
      const fieldId                                          = filter.id || filter.fieldId
      this.formFields[`${filter.formTemplateId}-${fieldId}`] = []
      if (!this.isClearingAllFilters) {
        this.$emit("filter", [`${filter.formTemplateId}-${fieldId}`, filter.tableColumn, true], [])
      }
    },
    /**
     * removes the filtered form field values
     * @param {*} filter - removes previously shortlisted search values of form field.
     */
    removeFormFieldFilter(filter) {
      this.clearFormFieldFilter(filter)
      const index = this.selectedAdvancedFilters.findIndex(selectedFilter => selectedFilter.value === filter.type)
      this.selectedAdvancedFilters.splice(index, 1)
    },
    /**
     * removes a specific form field value from filter
     * @param {*} field - specifies the form field value amongst the form fields which will be removed.
     * @param {*} index - the position of the value in the form field values array which has to be excluded from search.
     */
    removeFormFieldValue(field, index) {
      const key = `${field.formTemplateId}-${field.id}`
      this.formFields[key].splice(index, 1)
      this.saveFormField(field)
    },

    clearIssueFieldFilter(systemName) {
      if (this.issueFields[systemName]) {
        this.issueFields[systemName] = []
        if (!this.isClearingAllFilters) {
          this.$emit("filter", [systemName, this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_FIELD, true], [])
        }
      }
    },

    removeIssueFieldFilter(issueField) {
      this.clearIssueFieldFilter(issueField.systemName)
      const index = this.selectedAdvancedFilters.findIndex(filter => filter.key === issueField.key)
      this.selectedAdvancedFilters.splice(index, 1)
    },

    removeIssueFieldValue(issueField, index) {
      this.issueFields[issueField.systemName].splice(index, 1)
      this.saveIssueField(issueField)
    },

    /**
     * clears all advance filters
     */
    clearFilters(shouldEmitFilterEvent) {
      this.isClearingAllFilters = true
      const filtersToBeCleared  = [...this.selectedAdvancedFilters]
      for (const filter of filtersToBeCleared) {
        let filterName = filter.key
        if (filterName.startsWith("issue-fields-")) {
          filterName = filterName.slice("issue-fields-".length)
        } else if (filterName.startsWith("reporter-intake-forms-")) {
          filterName = filterName.slice("reporter-intake-forms-".length)
        } else if (filterName.startsWith("issue-forms-")) {
          filterName = filterName.slice("issue-forms-".length)
        }
        this.removeFormFieldFilter(filter)
        this.removeIssueFieldFilter({ ...filter, systemName: filter.name })
        this.removeAdvancedFilter(filterName, this.advancedFilters[filterName])
      }
      this.selectedAdvancedFilters  = []
      this.selectedIssueStatuses    = []
      this.selectedIssueResolutions = []
      this.selectedDates            = []
      this.selectedUpdatedAtDates   = []
      this.selectedIssueTypes       = []
      this.selectedIssueIds         = []
      if (shouldEmitFilterEvent) {
        this.$emit("filter")
      }
      this.$nextTick(() => {
        this.isClearingAllFilters = false
      })
    },
    setFormInstanceFieldFiltersFromUserPreferences() {
      const report = this.pFilterPreference.report
      if (!report) {
        return
      }
      const reportWithFormInstances = report.filter(report => report.formInstances)

      for (const report of reportWithFormInstances) {
        for (const formInstance of report.formInstances) {
          const formTemplateId = formInstance.formTemplateId
          for (const formInstanceField of formInstance.formInstanceFields) {
            const fieldId   = formInstanceField.fieldId[0]
            const fieldType = this.fieldsMap[fieldId]?.type

            const isFieldAlreadyAdded = !!this.selectedAdvancedFilters.find(filter =>
              filter.key === `reporter-intake-forms-${fieldId}`
            )

            if (!isFieldAlreadyAdded) {
              let fieldValues = []
              if (fieldType === FIELD_TYPES.BOOLEAN.value) {
                for (const value of formInstanceField.formInstanceFieldValues[0].value) {
                  if (value === "true") {
                    fieldValues.push(this.$t("1781"))
                  }
                  if (value === "false") {
                    fieldValues.push(this.$t("1782"))
                  }
                }
              } else {
                fieldValues = formInstanceField.formInstanceFieldValues[0].value
              }

              const formTemplate = this.pFormTemplates.find(formTemplate => formTemplate.id === formTemplateId)
              if (formTemplate) {
                this.selectedAdvancedFilters.push({
                  name    : this.$t("2254", { fieldName: this.fieldsMap[fieldId]?.systemName, formName: formTemplate.name }),
                  key     : `reporter-intake-forms-${fieldId}`,
                  value   : `reporter-intake-forms-${fieldId}-${formTemplateId}`,
                  formName: formTemplate.name,
                  fieldId,
                  formTemplateId
                })
              }

              this.formFields[`${formTemplateId}-${fieldId}`] = fieldValues
              this.$emit("filter", [
                `${formTemplateId}-${fieldId}`,
                this.$CONSTANTS.ISSUE_SEARCH_FILTERS.FORM_TEMPLATE,
                {
                  "fieldId"       : [fieldId],
                  "formTemplateId": formTemplateId
                }
              ], formInstanceField.formInstanceFieldValues[0].value
              )
            }
          }
        }
      }
    },
    setIssueFormInstanceFieldFiltersFromUserPreferences() {
      if (!this.pFilterPreference.formInstances) {
        return
      }
      const formInstances = this.pFilterPreference.formInstances

      for (const formInstance of formInstances) {
        const formTemplateId = formInstance.formTemplateId
        for (const formInstanceField of formInstance.formInstanceFields) {
          const fieldId   = formInstanceField.fieldId[0]
          const fieldType = this.fieldsMap[fieldId]?.type

          const isFieldAlreadyAdded = !!this.selectedAdvancedFilters.find(filter =>
            filter.key === `issue-forms-${fieldId}`
          )

          if (!isFieldAlreadyAdded) {
            let fieldValues = []
            if (fieldType === FIELD_TYPES.BOOLEAN.value) {
              for (const value of formInstanceField.formInstanceFieldValues[0].value) {
                if (value === "true") {
                  fieldValues.push(this.$t("1781"))
                }
                if (value === "false") {
                  fieldValues.push(this.$t("1782"))
                }
              }
            } else {
              fieldValues = formInstanceField.formInstanceFieldValues[0].value
            }

            const formTemplate = this.pFormTemplates.find(formTemplate => formTemplate.id === formTemplateId)
            if (formTemplate) {
              this.selectedAdvancedFilters.push({
                name    : this.$t("2254", { fieldName: this.fieldsMap[fieldId]?.systemName, formName: formTemplate.name }),
                key     : `issue-forms-${fieldId}`,
                value   : `issue-forms-${fieldId}-${formTemplateId}`,
                formName: formTemplate.name,
                fieldId,
                formTemplateId
              })
            }

            this.formFields[`${formTemplateId}-${fieldId}`] = fieldValues
            this.$emit("filter", [
                `${formTemplateId}-${fieldId}`,
                this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_FORM,
                {
                  "fieldId"       : [fieldId],
                  "formTemplateId": formTemplateId
                }
            ], formInstanceField.formInstanceFieldValues[0].value
            )
          }
        }
      }
    },
    setIssueFieldFiltersFromUserPreferences() {
      const issueFieldValues = this.pFilterPreference.issueFieldValues

      if (!issueFieldValues) {
        return
      }
      for (const issueFieldValue of issueFieldValues) {
        const issueFieldId = issueFieldValue.issueFieldId[0]
        const fieldId      = this.pIssueFields.find(issueField => issueField.id === issueFieldId)?.fieldId

        if (!fieldId) {
          return
        }
        const fieldSystemName = this.fieldsMap[fieldId]?.systemName
        const fieldType       = this.fieldsMap[fieldId]?.type

        if (!fieldSystemName) {
          return
        }

        const fieldFilter         = `issue-fields-${fieldId}`
        const isFieldAlreadyAdded = !!this.selectedAdvancedFilters.find(filter => filter.key === fieldFilter)

        if (!isFieldAlreadyAdded) {
          const filterValue = []
          if (fieldType === FIELD_TYPES.BOOLEAN.value) {
            for (const value of issueFieldValue.value) {
              if (value === "true") {
                filterValue.push(this.$t("1781"))
              }
              if (value === "false") {
                filterValue.push(this.$t("1782"))
              }
            }
          } else {
            filterValue.push(...issueFieldValue.value)
          }

          this.selectedAdvancedFilters.push({
            name: fieldSystemName,
            key : fieldFilter
          })
          this.issueFields[fieldSystemName] = filterValue
          this.$emit("filter", [
            fieldSystemName,
            this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_FIELD,
            {
              "issueFieldId": [issueFieldId]
            }
          ], issueFieldValue.value
          )
        }
      }
    },
    handleFilterSaveAsNew() {
      this.isSavingExistingFilterAsNew = true
      this.showSaveFilterDialog        = true
    },
    handleAddFilter(filter) {
      this.$emit("addFilter", {
        filterName                 : filter.name,
        isSavingExistingFilterAsNew: this.isSavingExistingFilterAsNew
      })
    },
    resetAddFilterError() {
      this.$emit("resetAddFilterError")
    },
    handleCancelFilter() {
      this.showSaveFilterDialog        = false
      this.isSavingExistingFilterAsNew = false
    },
    async handleResetFilter() {
      this.$emit("resetFilterUpdate")
      await this.$nextTick()
      this.clearFilters(false)
      await this.$nextTick()
      this.generateFilters(this.pFilter.criteria)
    },
    generateFilters(value) {
      const id                    = value.id
      const issueStatus           = value.statusId
      const issueResolution       = value.resolutionId
      const createdAt             = value.createdAt
      const updatedAt             = value.updatedAt
      const dueDate               = value.dueDate
      const domain                = value.domainId
      const assignee              = value.assigneeId
      const report                = value.report
      const labels                = value.labels
      const issueType             = value.typeId
      const issueAnonymisation    = value.dataRetentionStatus
      const messageStatus         = value.messageStatus
      const initialResponseStatus = value.initialResponseStatus
      const status                = value.status
      const formInstances         = value.formInstances

      if (status) {
        const statusCategory = status.find(status => status.category)?.category
        if (statusCategory) {
          const isStatusCategoryAlreadyAdded =
              !!this.selectedAdvancedFilters.find(filter => filter.key ===
                this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_STATUS_CATEGORY[0])
          if (!isStatusCategoryAlreadyAdded) {
            this.selectedAdvancedFilters.push({
              name: this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_STATUS_CATEGORY[0],
              key : this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_STATUS_CATEGORY[0]
            })
            this.selectedStatusCategories = statusCategory
          }
        }
      }
      if (report) {
        const channelFilters      = report.find(report => report.channelId)?.channelId
        const reportStatusFilters = report.find(report => report.status)?.status
        const reportSourceFilters = report.find(report => report.source)?.source
        const languageFilters     = report.find(report => report.languageId)?.languageId
        if (channelFilters) {
          const isChannelAlreadyAdded =
              !!this.selectedAdvancedFilters.find(filter => filter.key ===
                this.$CONSTANTS.ISSUE_SEARCH_FILTERS.CHANNEL[0])
          if (!isChannelAlreadyAdded) {
            this.selectedAdvancedFilters.push({
              name: this.$CONSTANTS.ISSUE_SEARCH_FILTERS.CHANNEL[0],
              key : this.$CONSTANTS.ISSUE_SEARCH_FILTERS.CHANNEL[0]
            })
            this.selectedChannels = channelFilters
          }
        }
        if (reportStatusFilters) {
          const isReportStatusAlreadyAdded =
              !!this.selectedAdvancedFilters.find(filter => filter.key ===
                this.$CONSTANTS.ISSUE_SEARCH_FILTERS.REPORT_STATUS[0])
          if (!isReportStatusAlreadyAdded) {
            this.selectedAdvancedFilters.push({
              name: this.$CONSTANTS.ISSUE_SEARCH_FILTERS.REPORT_STATUS[0],
              key : this.$CONSTANTS.ISSUE_SEARCH_FILTERS.REPORT_STATUS[0]
            })
            this.selectedReportStatuses = reportStatusFilters
          }
        }
        if (reportSourceFilters) {
          const isReportSourceAlreadyAdded =
              !!this.selectedAdvancedFilters.find(filter => filter.key ===
                this.$CONSTANTS.ISSUE_SEARCH_FILTERS.SOURCE[0])
          if (!isReportSourceAlreadyAdded) {
            this.selectedAdvancedFilters.push({
              name: this.$CONSTANTS.ISSUE_SEARCH_FILTERS.SOURCE[0],
              key : this.$CONSTANTS.ISSUE_SEARCH_FILTERS.SOURCE[0]
            })
            this.selectedReportSource = reportSourceFilters
          }
        }
        if (languageFilters) {
          const isLanguageAlreadyAdded =
              !!this.selectedAdvancedFilters.find(filter => filter.key ===
                this.$CONSTANTS.ISSUE_SEARCH_FILTERS.LANGUAGE[0])
          if (!isLanguageAlreadyAdded) {
            this.selectedAdvancedFilters.push({
              name: this.$CONSTANTS.ISSUE_SEARCH_FILTERS.LANGUAGE[0],
              key : this.$CONSTANTS.ISSUE_SEARCH_FILTERS.LANGUAGE[0]
            })
            this.selectedReportLanguage = languageFilters
          }
        }
        this.setFormInstanceFieldFiltersFromUserPreferences()
      }
      this.setIssueFieldFiltersFromUserPreferences()

      if (formInstances) {
        this.setIssueFormInstanceFieldFiltersFromUserPreferences()
      }

      if (labels) {
        const isLabelAlreadyAdded =
            !!this.selectedAdvancedFilters.find(filter => filter.key ===
              this.$CONSTANTS.ISSUE_SEARCH_FILTERS.LABEL[0])
        if (!isLabelAlreadyAdded) {
          this.selectedAdvancedFilters.push({
            name: this.$CONSTANTS.ISSUE_SEARCH_FILTERS.LABEL[0],
            key : this.$CONSTANTS.ISSUE_SEARCH_FILTERS.LABEL[0]
          })
          this.selectedLabels = labels
        }
      }
      if (id) {
        this.selectedIssueIds = id
      }

      if (issueStatus) {
        this.selectedIssueStatuses = issueStatus
      }
      if (issueResolution) {
        const isIssueResolutionAlreadyAdded =
            !!this.selectedAdvancedFilters.find(filter => filter.key ===
              this.$CONSTANTS.ISSUE_SEARCH_FILTERS.RESOLUTION[0])
        if (!isIssueResolutionAlreadyAdded) {
          this.selectedAdvancedFilters.push({
            name: this.$CONSTANTS.ISSUE_SEARCH_FILTERS.RESOLUTION[0],
            key : this.$CONSTANTS.ISSUE_SEARCH_FILTERS.RESOLUTION[0]
          })
          this.selectedIssueResolutions = issueResolution
        }
      }
      if (issueType) {
        this.selectedIssueTypes = issueType
      }
      if (issueAnonymisation) {
        const isIssueAnonymisationAlreadyAdded =
            !!this.selectedAdvancedFilters.find(filter => filter.key ===
              this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_ANONYMISATION[0])
        if (!isIssueAnonymisationAlreadyAdded) {
          this.selectedAdvancedFilters.push({
            name: this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_ANONYMISATION[0],
            key : this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_ANONYMISATION[0]
          })
          this.selectedIssueAnonymisationStatuses = issueAnonymisation
        }
      }
      if (createdAt) {
        const isDateRangeAlreadyAdded =
            !!this.selectedAdvancedFilters.find(filter => filter.key ===
              this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DATE_RANGE[0])
        if (!isDateRangeAlreadyAdded) {
          const formattedDates = []
          const datesSplit     = createdAt.split("to")
          const fromDate       = this.$moment(new Date(datesSplit[0])).format("YYYY-MM-DD")
          const toDate         = this.$moment(new Date(datesSplit[1])).format("YYYY-MM-DD")
          formattedDates.push(fromDate)
          formattedDates.push(toDate)
          this.selectedAdvancedFilters.push({
            name: this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DATE_RANGE[0],
            key : this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DATE_RANGE[0]
          })
          this.selectedDates = formattedDates
        }
      }
      if (updatedAt) {
        const isDateRangeAlreadyAdded =
            !!this.selectedAdvancedFilters.find(filter => filter.key ===
              this.$CONSTANTS.ISSUE_SEARCH_FILTERS.UPDATED_AT[0])
        if (!isDateRangeAlreadyAdded) {
          const formattedDates = []
          const datesSplit     = updatedAt.split("to")
          const fromDate       =  datesSplit[0].split("T").length === 2
            ? this.$moment(new Date(datesSplit[0])).utc().toISOString()
            : this.$moment(new Date(datesSplit[0])).format("YYYY-MM-DD")
          const toDate         =  datesSplit[0].split("T").length === 2
            ? this.$moment(new Date(datesSplit[1])).utc().toISOString()
            : this.$moment(new Date(datesSplit[1])).format("YYYY-MM-DD")
          formattedDates.push(fromDate)
          formattedDates.push(toDate)
          this.selectedAdvancedFilters.push({
            name: this.$CONSTANTS.ISSUE_SEARCH_FILTERS.UPDATED_AT[0],
            key : this.$CONSTANTS.ISSUE_SEARCH_FILTERS.UPDATED_AT[0]
          })
          this.selectedUpdatedAtDates = formattedDates
        }
      }
      if (dueDate) {
        const isDateRangeAlreadyAdded =
            !!this.selectedAdvancedFilters.find(filter => filter.key ===
              this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DUE_DATE[0])
        if (!isDateRangeAlreadyAdded) {
          const formattedDates = []
          const datesSplit     = dueDate.split("to")
          const fromDate       = datesSplit[0].split("T").length === 2
            ? this.$moment(new Date(datesSplit[0])).utc().toISOString()
            : this.$moment(new Date(datesSplit[0])).format("YYYY-MM-DD")
          const toDate         = datesSplit[0].split("T").length === 2
            ? this.$moment(new Date(datesSplit[1])).utc().toISOString()
            : this.$moment(new Date(datesSplit[1])).format("YYYY-MM-DD")
          formattedDates.push(fromDate)
          formattedDates.push(toDate)
          this.selectedAdvancedFilters.push({
            name: this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DUE_DATE[0],
            key : this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DUE_DATE[0]
          })
          this.selectedDueDates = formattedDates
        }
      }
      if (domain) {
        const isDomainAlreadyAdded =
            !!this.selectedAdvancedFilters.find(filter => filter.key ===
              this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DOMAIN[0])
        if (!isDomainAlreadyAdded) {
          this.selectedAdvancedFilters.push({
            name: this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DOMAIN[0],
            key : this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DOMAIN[0]
          })
          this.selectedDomains = domain
        }
      }
      if (assignee) {
        const isAssigneeAlreadyAdded =
            !!this.selectedAdvancedFilters.find(filter => filter.key ===
              this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ASSIGNEE[0])
        if (!isAssigneeAlreadyAdded) {
          this.selectedAdvancedFilters.push({
            name: this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ASSIGNEE[0],
            key : this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ASSIGNEE[0]
          })
          this.selectedAssignees = assignee
        }
      }
      if (messageStatus) {
        const isMessageStatusAlreadyAdded =
            !!this.selectedAdvancedFilters.find(filter => filter.key ===
              this.$CONSTANTS.ISSUE_SEARCH_FILTERS.MESSAGE_STATUS[0])
        if (!isMessageStatusAlreadyAdded) {
          this.selectedAdvancedFilters.push({
            name: this.$CONSTANTS.ISSUE_SEARCH_FILTERS.MESSAGE_STATUS[0],
            key : this.$CONSTANTS.ISSUE_SEARCH_FILTERS.MESSAGE_STATUS[0]
          })
          this.selectedMessageStatuses = messageStatus
        }
      }
      if (initialResponseStatus) {
        const isInitialResponseStatusAlreadyAdded =
            !!this.selectedAdvancedFilters.find(filter => filter.key ===
              this.$CONSTANTS.ISSUE_SEARCH_FILTERS.INITIAL_RESPONSE_STATUS[0])
        if (!isInitialResponseStatusAlreadyAdded) {
          this.selectedAdvancedFilters.push({
            name: this.$CONSTANTS.ISSUE_SEARCH_FILTERS.INITIAL_RESPONSE_STATUS[0],
            key : this.$CONSTANTS.ISSUE_SEARCH_FILTERS.INITIAL_RESPONSE_STATUS[0]
          })
          this.selectedInitialResponseStatuses = initialResponseStatus
        }
      }
    }
  },
  watch: {
    selectedAdvancedFilters: {
      immediate: true,
      handler  : function(newFilters, oldFilters) {
        let excludedFilter = oldFilters?.find(oldFilter => newFilters.indexOf(oldFilter) === -1)
        if (excludedFilter) {
          if (excludedFilter.key.startsWith("issue-fields-")) {
            excludedFilter = excludedFilter.key.slice(0, "issue-fields-".length - 1)
          } else if (excludedFilter.key.startsWith("reporter-intake-forms-")) {
            excludedFilter = excludedFilter.key.slice(0, "reporter-intake-forms-".length - 1)
          }

          if (this.formFields[excludedFilter]) {
            this.removeFormFieldFilter(excludedFilter)
          } else if (this.issueFields[excludedFilter]) {
            this.removeIssueFieldFilter({ ...excludedFilter, systemName: excludedFilter.name })
          } else {
            this.clearFilter(this.advancedFilters[excludedFilter])
          }
        }
        this.$emit("updateAdvancedFilters", newFilters)
      }
    },
    selectedDomains: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.isClearingAllFilters) {
          this.$emit("filter", this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DOMAIN, newValue)
        }
      }
    },
    selectedAssignees: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.isClearingAllFilters) {
          this.$emit("filter", this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ASSIGNEE, newValue)
        }
      }
    },
    selectedIssueStatuses: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.isClearingAllFilters) {
          this.$emit("filter", this.$CONSTANTS.ISSUE_SEARCH_FILTERS.STATUS, newValue)
        }
      }
    },
    selectedIssueTypes: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.isClearingAllFilters) {
          this.$emit("filter", this.$CONSTANTS.ISSUE_SEARCH_FILTERS.TYPE, newValue)
        }
      }
    },
    selectedIssueResolutions: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.isClearingAllFilters) {
          this.$emit("filter", this.$CONSTANTS.ISSUE_SEARCH_FILTERS.RESOLUTION, newValue)
        }
      }
    },
    selectedDates: {
      immediate: true,
      handler  : function(newValue) {
        if ((!newValue.length || newValue.length === 2) && !this.isClearingAllFilters) {
          this.$emit("filter", this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DATE_RANGE, newValue)
        }
      }
    },
    selectedUpdatedAtDates: {
      immediate: true,
      handler  : function(newValue) {
        if ((!newValue.length || newValue.length === 2) && !this.isClearingAllFilters) {
          this.$emit("filter", this.$CONSTANTS.ISSUE_SEARCH_FILTERS.UPDATED_AT, newValue)
        }
      }
    },
    selectedDueDates: {
      immediate: true,
      handler  : function(newValue) {
        if ((!newValue.length || newValue.length === 2) && !this.isClearingAllFilters) {
          this.$emit("filter", this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DUE_DATE, newValue)
        }
      }
    },
    selectedChannels: {
      immediate: true,
      handler  : function(newValue) {
        if(!this.isClearingAllFilters){
          this.$emit("filter", [...this.$CONSTANTS.ISSUE_SEARCH_FILTERS.CHANNEL], newValue)
        }
      }
    },
    selectedReportStatuses: {
      immediate: true,
      handler  : function(newValue) {
        if(!this.isClearingAllFilters){
          this.$emit("filter", [...this.$CONSTANTS.ISSUE_SEARCH_FILTERS.REPORT_STATUS], newValue)
        }
      }
    },
    selectedIssueAnonymisationStatuses: {
      immediate: true,
      handler  : function(newValue) {
        if(!this.isClearingAllFilters){
          this.$emit("filter", [...this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_ANONYMISATION], newValue)
        }
      }
    },
    selectedLabels: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.isClearingAllFilters) {
          this.$emit("filter", this.$CONSTANTS.ISSUE_SEARCH_FILTERS.LABEL, newValue)
        }
      }
    },
    pFields: {
      immediate: true,
      handler  : function(newValue) {
        this.fieldsMap = {}
        for (const field of newValue) {
          this.fieldsMap[field.id] = field
        }
        this.setFormInstanceFieldFiltersFromUserPreferences()
        this.setIssueFieldFiltersFromUserPreferences()
        this.setIssueFormInstanceFieldFiltersFromUserPreferences()
      }
    },
    pIssueFields: {
      immediate: true,
      handler  : function() {
        this.setIssueFieldFiltersFromUserPreferences()
      }
    },
    selectedIssueIds: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.isClearingAllFilters) {
          this.$emit("filter", this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ID, newValue)
        }
      }
    },
    pFilterPreference: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.generateFilters(newValue)
        }
      }
    },
    selectedReportSource: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.isClearingAllFilters) {
          this.$emit("filter", [...this.$CONSTANTS.ISSUE_SEARCH_FILTERS.SOURCE], newValue)
        }
      }
    },
    selectedReportLanguage: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.isClearingAllFilters) {
          this.$emit("filter", [...this.$CONSTANTS.ISSUE_SEARCH_FILTERS.LANGUAGE], newValue)
        }
      }
    },
    selectedMessageStatuses: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.isClearingAllFilters) {
          this.$emit("filter", this.$CONSTANTS.ISSUE_SEARCH_FILTERS.MESSAGE_STATUS, newValue)
        }
      }
    },
    selectedInitialResponseStatuses: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.isClearingAllFilters) {
          this.$emit("filter", this.$CONSTANTS.ISSUE_SEARCH_FILTERS.INITIAL_RESPONSE_STATUS, newValue)
        }
      }
    },
    selectedStatusCategories: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.isClearingAllFilters) {
          this.$emit("filter", [...this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_STATUS_CATEGORY], newValue)
        }
      }
    },
    pFilterAddError: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue?.field === "name" && newValue?.type === "duplicate") {
          this.isFilterNameDuplicate = true
        } else {
          this.isFilterNameDuplicate = false
        }
      }
    },
    pIsFilterAdded: {
      handler: function(newValue) {
        if (newValue) {
          this.handleCancelFilter()
        }
      }
    },
    showFilterActions: {
      immediate: true,
      handler  : function(newValue) {
        this.$emit("toggleFilterActionsVisibility", newValue)
      }
    },
    pIsUpdatingCriteria: {
      handler: function(newValue) {
        this.$DECISIONS.UPDATE_FILTER.pActions[0].buttonProps.disabled = newValue
        this.$DECISIONS.UPDATE_FILTER.pActions[1].buttonProps.loading  = newValue
      }
    },
    pIsCriteriaUpdated: {
      handler: function(newValue) {
        if (newValue) {
          this.showUpdateFilterDialog = false
        }
      }
    }
  }
}
