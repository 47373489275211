export default {
  "Issue view"                     : "setIssueViewPolicies",
  "Issue update"                   : "setIssueUpdatePolicies",
  "Issue add comment"              : "setIssueCommentAddPolicies",
  "IssueComment remove"            : "setIssueCommentRemovePolicies",
  "Issue add documents"            : "setIssueAddDocumentsPolicies",
  "IssueDocument remove"           : "setIssueDocumentRemovePolicies",
  "Report view"                    : "setReportViewPolicies",
  "Report update"                  : "setReportUpdatePolicies",
  "Report content clear"           : "setReportContentClearPolicies",
  "Report add messages"            : "setReportAddMessagesPolicies",
  "Message update"                 : "setMessageUpdatePolicies",
  "Message remove"                 : "setMessageRemovePolicies",
  "MessageItem add translations"   : "setMessageItemAddTranslationsPolicies",
  "Translation update"             : "setTranslationUpdatePolicies",
  "Issue add form instances"       : "setIssueAddFormInstancesPolicies",
  "FormInstance update"            : "setFormInstanceUpdatePolicies",
  "FormInstance remove"            : "setFormInstanceRemovePolicies",
  "Issue update issue field values": "setIssueUpdateIssueFieldValuesPolicies",
  "Issue print"                    : "setIssuePrintPolicies",
  "Issue add link"                 : "setIssueLinkAddPolicies",
  "IssueLink remove"               : "setIssueLinkRemovePolicies",
  "Issue add task"                 : "setIssueAddTaskPolicies",
  "Issue add folders"              : "setIssueAddFoldersPolicies",
  "IssueDocument update"           : "setIssueDocumentUpdatePolicies",
  "MessageItem update"             : "setMessageItemUpdatePolicies",
  "Folder update"                  : "setFolderUpdatePolicies",
  "Folder remove"                  : "setFolderRemovePolicies",
  "Issue view logs"                : "setIssueViewLogsPolicies",
  "Access view"                    : "setAccessViewPolicies",
  "SavedFilter remove"             : "setSavedFilterRemovePolicies",
  "SavedFilter update"             : "setSavedFilterUpdatePolicies",
  "CustomView update"              : "setCustomViewUpdatePolicies"
}