/**
 * @file It contains modules that needs to displayed in navigation drawer
 */
export default [{
  icon     : "mdi-chart-line",
  title    : "710",
  action   : [],
  roleTypes: ["issue"]
},
{
  icon     : "mdi-chart-box-outline",
  title    : "413",
  action   : "issues",
  roleTypes: ["issue"]
},
{
  icon : "mdi-account-multiple-outline",
  title: "1100",
  items: [{
    title    : "414",
    action   : "users",
    roleTypes: ["global"]
  },
  {
    title    : "415",
    action   : "groups",
    roleTypes: ["global"]
  },
  {
    title    : "421",
    action   : "roles",
    roleTypes: ["global"]
  },
  {
    title    : "419",
    action   : "domains",
    roleTypes: ["global"]
  },
  {
    title    : "420",
    action   : "channels",
    roleTypes: ["global"]
  }]
},
{
  icon : "mdi-file-document-multiple-outline",
  title: "1101",
  items: [{
    title    : "730",
    action   : "option-lists",
    roleTypes: ["global"]
  },
  {
    title    : "742",
    action   : "fields",
    roleTypes: ["global"]
  },
  {
    title    : "736",
    action   : "reporter-intake-forms",
    roleTypes: ["global"]
  },
  {
    title    : "1204",
    action   : "issue-forms",
    roleTypes: ["global"]
  }]
},
{
  icon : "mdi-wrench-outline",
  title: "890",
  items: [{
    title    : "934",
    action   : ["issue-fields", "issue-form-templates", "issue-form-template"],
    roleTypes: ["global"]
  },
  {
    title    : "1419",
    action   : "workflows",
    roleTypes: ["global"]
  },
  {
    title    : "1389",
    action   : "screens",
    roleTypes: ["global"]
  }]
},
{
  icon : "mdi-cog",
  title: "1102",
  items: [{
    title    : "417",
    action   : "configurations",
    roleTypes: ["global"]
  },
  {
    title    : "1088",
    action   : "automations",
    roleTypes: ["global"]
  },
  {
    title    : "1714",
    action   : "reply-templates",
    roleTypes: ["global"]
  },
  {
    title    : "698",
    action   : "export",
    roleTypes: ["global"]
  },
  {
    title    : "423",
    action   : "exceptions",
    roleTypes: ["global"]
  },
  {
    title    : "424",
    action   : "logs",
    roleTypes: ["global"]
  }]
}]

export const DASHBOARD = {
  icon  : "mdi-view-dashboard",
  title : "412",
  action: "dashboard"
}

export const ISSUES_WITH_SAVED_FILTERS_ENABLED = {
  icon : "mdi-chart-box-outline",
  title: "413",
  items: [{
    title    : "2064",
    action   : "filters",
    roleTypes: ["issue"]
  },
  {
    title    : "2065",
    action   : "issues",
    roleTypes: ["issue"]
  }]
}