import ThemisInput from "@/components/shared/input"
import ThemisDecision from "@/components/shared/decision"
import { mapActions, mapGetters } from "vuex"
import { STATUS_KEYS } from "@/constants"
import { FIELD_TYPES } from "@/constants"
import { SCREEN_ITEM_TYPE } from "../../constants"

export default {
  name      : "Screen-Side-Panel",
  components: {
    ThemisInput,
    ThemisDecision
  },
  data: () => ({
    showRemoveScreenItemDialog: false,
    localScreenItem           : {
      id       : null,
      mandatory: false
    }
  }),
  computed: {
    ...mapGetters({
      screenItems               : "screens/screenItems",
      screens                   : "screens/screens",
      formTemplates             : "formTemplates/formTemplates",
      formTemplateConfigurations: "formTemplateConfigurations/formTemplateConfigurations",
      fieldsV2                  : "fields/fieldsV2",
      optionLists               : "optionLists/optionLists",
      isMandatoryUpdated        : "screenItems/isMandatoryUpdated",
      isScreenItemRemoved       : "screenItems/isScreenItemRemoved",
      isRemovingScreenItem      : "screenItems/isRemovingScreenItem",
      isUpdatingMandatory       : "screenItems/isUpdatingMandatory"
    }),
    screen() {
      return this.screens.find(screen => screen.id === +this.$route.params.id)
    },
    screenItem() {
      const screenItemElement   = this.screenItems.find(item => item.id === +this.$route.params.itemId)
      const screenItemFormField = this.screenItems.find(screenItem => {
        if (screenItem.type === "FORM") {
          return screenItem.form.formFields.find(field =>
            field.id === +this.$route.params.itemId)
        }
      })
      if ( +this.$route.params.itemId && +this.$route.params.formId ) {
        return screenItemFormField
      }
      return screenItemElement
    },
    screenItemKey() {
      return this.screenItem?.issueProperty?.key
    },
    screenItemFieldId() {
      return this.screenItem?.field?.fieldId
    },
    screenItemFormField() {
      let formField
      if (this.screenItem.type === SCREEN_ITEM_TYPE.FORM && this.$route.params.formId) {
        formField = this.screenItem.form.formFields.find(field =>
          field.id === +this.$route.params.itemId)
      }
      return formField
    },
    fieldsMap() {
      const fieldsMap = new Map()
      for (const field of this.fieldsV2) {
        fieldsMap.set(field.id, field)
      }
      return fieldsMap
    },
    optionListsMap() {
      const optionListsMap = new Map()
      for (const optionList of this.optionLists) {
        optionListsMap[optionList.id] = optionList
      }
      return optionListsMap
    },
    isIssueField() {
      if (this.screenItem?.id !== +this.$route.params?.itemId ||
        (this.screenItem?.id === +this.$route.params?.itemId && this.$route.params.formId) ||
        this.screenItem?.type === SCREEN_ITEM_TYPE.FIELD) {
        return true
      }
      return false
    },
    isIssueForm() {
      if (this.screenItem?.id !== +this.$route.params?.itemId &&
        this.$route.params.formId &&
        this.screenItem?.type === SCREEN_ITEM_TYPE.FORM) {
        return false
      }
      return this.screenItem?.type === SCREEN_ITEM_TYPE.FORM && !this.$route.params.formId
    },
    isRemoveFromScreenButtonVisible() {
      if (this.screen?.systemDefault) {
        return false
      } else {
        return this.screenItem?.id === +this.$route.params.itemId && !this.$route.params.formId
      }
    },
    itemName() {
      switch (this.screenItem?.type) {
        case SCREEN_ITEM_TYPE.ISSUE_PROPERTY:
          return this.$t(STATUS_KEYS[this.screenItemKey])
        case SCREEN_ITEM_TYPE.FIELD:
          return this.selectedField?.systemName
        case SCREEN_ITEM_TYPE.FORM:
          if (+this.$route.params?.itemId === this.screenItem.id && !this.$route.params?.formId) {
            return this.formTemplates.find(form =>
              form.id === this.screenItem?.form?.formTemplateId)?.name
          } else {
            return this.selectedField?.systemName
          }
      }
    },
    selectedField() {
      let field
      if (this.screenItem?.type === SCREEN_ITEM_TYPE.FORM) {
        const fieldId = this.formTemplateConfigurations.find(formTemplateConfiguration =>
          formTemplateConfiguration.id === this.screenItemFormField?.formTemplateConfigurationId)?.fieldId
        field         = this.fieldsMap.get(fieldId)
      } else {
        field = this.fieldsMap.get(this.screenItemFieldId)
      }
      return field
    },
    selectedFieldType() {
      if (this.selectedField) {
        return Object.values(FIELD_TYPES).find(fieldType => fieldType.value === this.selectedField.type)
      }
    },
    optionListNameOfSelectedField() {
      return this.optionListsMap[this.selectedField.optionListId]?.name
    },
    fieldTypeIconOfSelectedField() {
      return this.selectedFieldType?.icon
    },
    fieldTypeNameOfSelectedField() {
      return this.selectedFieldType?.name
    },
    showOptionListDetails() {
      return [FIELD_TYPES.OPTION_LIST.value, FIELD_TYPES.MULTIPLE_OPTION_LIST.value].includes(
        this.selectedField?.type
      )
    }
  },
  methods: {
    ...mapActions({
      updateScreenItem: "screenItems/updateScreenItem",
      removeScreenItem: "screenItems/removeScreenItem",
      notify          : "shared/notify"
    }),
    handleRemoveScreenItem() {
      this.removeScreenItem(this.screenItem.id)
    },
    handleUpdateScreenItem(payloadItems) {
      let payload
      if (this.screenItem.type === SCREEN_ITEM_TYPE.FORM && this.screenItem.id !== this.$route.params.itemId) {
        payload = {
          id  : this.screenItem.id,
          form: {
            formField: {
              id       : payloadItems.id,
              mandatory: payloadItems.mandatory
            }
          }
        }
      } else {
        payload = payloadItems
      }
      this.updateScreenItem({
        ...payload,
        type: this.screenItem.type
      })
    }
  },
  watch: {
    screenItem: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          const isLocalScreenItemNotSet          = !this.localScreenItem
          const isLocalScreenItemSetButDifferent = !isLocalScreenItemNotSet &&
            this.localScreenItem.id !== newValue.id
          if (isLocalScreenItemNotSet || isLocalScreenItemSetButDifferent) {
            switch (newValue.type) {
              case SCREEN_ITEM_TYPE.FORM:
                this.localScreenItem = {
                  id       : this.screenItemFormField?.id,
                  mandatory: this.screenItemFormField?.mandatory ?? false
                }
                break
              case SCREEN_ITEM_TYPE.FIELD:
                this.localScreenItem = {
                  id       : newValue.id,
                  mandatory: newValue.field.mandatory
                }
                break
              case SCREEN_ITEM_TYPE.ISSUE_PROPERTY:
                this.localScreenItem = {
                  id       : newValue.id,
                  mandatory: newValue.issueProperty.mandatory
                }
                break
            }
          }
        }
      }
    },
    isMandatoryUpdated: {
      handler: function(value) {
        if (value) {
          this.notify({
            type: "success",
            text: "1529"
          })
        }
      }
    },
    isScreenItemRemoved: {
      handler: function(newValue) {
        if (newValue) {
          this.$router.push({ name: "screen", params: { id: this.screen.id } })
          this.notify({
            type: "success",
            text: "1529"
          })
        }
      }
    },
    isRemovingScreenItem: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.REMOVE_SCREEN_ITEM.pActions[0].buttonProps.disabled = newValue
        this.$DECISIONS.REMOVE_SCREEN_ITEM.pActions[1].buttonProps.loading  = newValue
      }
    }
  }
}