import ThemisInput from "@/components/shared/input"
import ThemisDateTimePicker from "@/components/shared/date-time-picker"
import ThemisCascadedInput from "@/components/shared/cascaded-input"
import { FIELD_TYPES, MAX_CHARACTER_LIMIT, ARRAY } from "@/constants"

export default {
  name      : "IssueFields",
  components: {
    ThemisInput,
    ThemisDateTimePicker,
    ThemisCascadedInput
  },
  props: {
    pIsOptionListCascaded     : Boolean,
    pIssueField               : Object,
    pOptionListItems          : Array,
    pIssueFieldValues         : Array,
    pReadOnly                 : Boolean,
    pIsUpdatingIssueFieldValue: Boolean,
    pIsIssueFieldValueUpdated : Boolean,
    pLocalScreenItem          : Object
  },
  emits: ["updateIssueFieldValue"],
  data() {
    return {
      issueFieldValues            : null,
      updatingIssueFieldId        : null,
      showIssueFieldDateMenu      : false,
      isDateTimePickerOpen        : false,
      shortTextFieldCharacterLimit: MAX_CHARACTER_LIMIT.ISSUE_FIELDS_SHORT_TEXT_FIELD,
      longTextFieldCharacterLimit : MAX_CHARACTER_LIMIT.ISSUE_FORM_LONG_TEXT_FIELD
    }
  },
  computed: {
    isIssueFieldValueUpdating() {
      return this.pIsUpdatingIssueFieldValue && this.updatingIssueFieldId === this.pIssueField.issueFieldId
    },
    issueFieldDateDisplay() {
      let dateValue = this.issueFieldValues
      if (dateValue) {
        dateValue = this.$moment(dateValue).format("DD MMMM YYYY")
      }
      return dateValue
    },
    rules() {
      const rules = []
      if (this.pLocalScreenItem && this.pLocalScreenItem.field) {
        const isFieldMandatory = this.pLocalScreenItem.mandatory
        if (isFieldMandatory) {
          rules.push("required")
        }
        if (this.pLocalScreenItem.field.fieldType === FIELD_TYPES.SHORT_TEXT.value) {
          rules.push(`max:${this.shortTextFieldCharacterLimit}`)
        }
        if (this.pLocalScreenItem.field.fieldType === FIELD_TYPES.LONG_TEXT.value) {
          rules.push(`max:${this.longTextFieldCharacterLimit}`)
        }
      } else if (this.pIssueField && this.pIssueField.fieldType === FIELD_TYPES.SHORT_TEXT.value) {
        rules.push(`max:${this.shortTextFieldCharacterLimit}`)
      } else if (this.pIssueField && this.pIssueField.fieldType === FIELD_TYPES.LONG_TEXT.value) {
        rules.push(`max:${this.longTextFieldCharacterLimit}`)
      }
      return rules.join("|")
    }
  },
  methods: {
    isFieldTypeMultipleOptionList(issueField) {
      return issueField.fieldType === FIELD_TYPES.MULTIPLE_OPTION_LIST.value
    },
    isFieldTypeSingleOptionList(issueField) {
      return issueField.fieldType === FIELD_TYPES.OPTION_LIST.value
    },
    handleOptionListInputOnBlurEvent(onBlur, issueField) {
      onBlur()
      if (this.isFieldTypeMultipleOptionList(issueField)) {
        this.updateIssueFieldValueHandler(issueField)
      }
    },
    handleOptionListInputOnChangeEvent(issueField) {
      if (!this.isFieldTypeMultipleOptionList(issueField)) {
        this.updateIssueFieldValueHandler(issueField)
      }
    },
    handleNumberFieldUpdateEvent(onBlur) {
      if (onBlur){
        onBlur()
      }
      const issueFieldValue = this.issueFieldValues
      const isNumber        = Number.isInteger(issueFieldValue)
      const isEmptyString   = issueFieldValue?.toString().length === 0

      if (isNumber) {
        this.issueFieldValues = this.issueFieldValues.toString()
      } else if(isEmptyString) {
        this.issueFieldValues = null
      }
      if (!this.pIssueField.readOnly) {
        this.updateIssueFieldValueHandler(this.pIssueField)
      }
    },
    handleNumberFieldKeypress(event) {
      const issueFieldValue        = this.issueFieldValues
      const isIssueFieldValueEmpty = !issueFieldValue && issueFieldValue !== 0
      const isKeyPressedADash      = event.key === "-"
      const isKeyPressedNotANumber = isNaN(event.key)

      if (isKeyPressedNotANumber) {
        if (!(isIssueFieldValueEmpty && isKeyPressedADash)) {
          event.preventDefault()
        }
      }
    },
    onNodeSelect(selectedNodes, validate) {
      this.issueFieldValues = selectedNodes
      this.updateIssueFieldValueHandler()
      validate(this.issueFieldValues)
    },
    getIssueFieldValue() {
      let fieldValue
      const isFieldTypeMultipleOptionList = this.isFieldTypeMultipleOptionList(this.pIssueField)
      const isFieldTypeSingleOptionList   = this.isFieldTypeSingleOptionList(this.pIssueField)
      if (isFieldTypeMultipleOptionList ||
        (isFieldTypeSingleOptionList && this.pIsOptionListCascaded)) {
        fieldValue = this.pIssueFieldValues?.filter(
          issueFieldValue => issueFieldValue.value
        ).map(issueFieldValue =>
          issueFieldValue.value
        )
      } else {
        fieldValue = this.pIssueFieldValues && this.pIssueFieldValues[ARRAY.FIRST]?.value
      }
      if (this.pIssueField.fieldType === FIELD_TYPES.DATE_TIME.value) {
        return fieldValue ? new Date(fieldValue) : undefined
      }
      if (this.pIssueField.fieldType === FIELD_TYPES.DATE.value) {
        return fieldValue ??  undefined
      }
      return fieldValue
    },
    updateIssueFieldValueHandler() {
      this.showIssueFieldDateMenu = false
      const fieldValues           = this.issueFieldValues
      if (this.hasIssueFieldValueChanged(this.pIssueField)) {
        const dataToBeUpdated               = []
        const isFieldTypeMultipleOptionList = this.isFieldTypeMultipleOptionList(this.pIssueField)
        const isFieldTypeSingleOptionList   = this.isFieldTypeSingleOptionList(this.pIssueField)

        if (isFieldTypeMultipleOptionList || (isFieldTypeSingleOptionList && this.pIsOptionListCascaded)) {
          if (fieldValues.length) {
            for (const fieldValue of fieldValues) {
              dataToBeUpdated.push({
                issueFieldId: this.pIssueField.issueFieldId,
                value       : fieldValue,
                issueId     : this.pIssueField.issueId
              })
            }
          } else {
            dataToBeUpdated.push({
              issueFieldId: this.pIssueField.issueFieldId,
              value       : null,
              issueId     : this.pIssueField.issueId
            })
          }
        } else {
          dataToBeUpdated.push({
            issueFieldId: this.pIssueField.issueFieldId,
            value       : fieldValues,
            issueId     : this.pIssueField.issueId
          })
        }

        this.$emit("updateIssueFieldValue", dataToBeUpdated)
        this.updatingIssueFieldId = this.pIssueField.issueFieldId
      }
    },
    hasIssueFieldValueChanged() {
      const isFieldTypeMultipleOptionList = this.isFieldTypeMultipleOptionList(this.pIssueField)
      const originalIssueFieldValue       = this.getIssueFieldValue()
      if (isFieldTypeMultipleOptionList) {
        // Condition was to added when multiple fields are selected and then deselected in screen component
        if (!originalIssueFieldValue.length && !this.issueFieldValues.length) {
          return true
        }
        return JSON.stringify(originalIssueFieldValue.slice().sort()) !==
          JSON.stringify(this.issueFieldValues.slice().sort())
      } else {
        return originalIssueFieldValue !== this.issueFieldValues
      }
    },
    handleDateClearEvent() {
      this.issueFieldValues = null
      this.updateIssueFieldValueHandler()
    },
    handleDateTimeClearEvent() {
      this.issueFieldValues = null
      this.updateIssueFieldValueHandler()
    },
    dateTimeForDisplaying() {
      if (this.issueFieldValues) {
        return this.$moment(this.issueFieldValues).format("D MMMM YYYY HH:mm (UTCZ)")
      }
    },
    handleResetDateTimePicker() {
      const issueFieldValue = this.getIssueFieldValue()
      if (issueFieldValue) {
        this.issueFieldValues = issueFieldValue
      } else {
        this.issueFieldValues = null
      }
    },
    handleCloseDateTimePicker() {
      this.isDateTimePickerOpen = false
      this.handleResetDateTimePicker()
    },
    handleUpdateDateTime() {
      this.isDateTimePickerOpen = false
      this.updateIssueFieldValueHandler()
    },
    handleShortTextFieldOnUpdateEvent(onBlur) {
      onBlur()
      const shortTextFieldValue               = this.issueFieldValues
      const isShortTextFieldNullOrWithinLimit = !shortTextFieldValue ||
        shortTextFieldValue.length <= this.shortTextFieldCharacterLimit
      if (isShortTextFieldNullOrWithinLimit) {
        this.updateIssueFieldValueHandler()
      }
    },
    handleShortTextFieldInputOnEnter(issueFieldId) {
      this.$refs[`short_text_field_${issueFieldId}`].blur()
    },
    handleLongTextFieldOnUpdateEvent(onBlur) {
      onBlur()
      const longTextFieldValue             = this.issueFieldValues
      const isLongTextFieldNullOrWithLimit = !longTextFieldValue ||
        longTextFieldValue.length <= this.longTextFieldCharacterLimit
      if (isLongTextFieldNullOrWithLimit) {
        this.updateIssueFieldValueHandler()
      }
    },
    handleLongTextFieldInputOnEnter(issueFieldId) {
      this.$refs[`long_text_field_${issueFieldId}`].blur()
    },
    handleCascadeInputBlur(onBlur, validate) {
      onBlur()
      validate()
    },
    handleValidate(value, validate) {
      validate(value)
    }
  },
  watch: {
    pIssueFieldValues: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && !this.pIsUpdatingIssueFieldValue) {
          this.issueFieldValues = this.getIssueFieldValue()
        }
      }
    },
    pIsIssueFieldValueUpdated: {
      immediate: true,
      handler  : function(value) {
        if (value) {
          this.updatingIssueFieldId = null
        }
      }
    }
  }
}