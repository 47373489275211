import ThemisExport from"@/components/analytics/export"
import ThemisViews from "@/components/analytics/views"
import { mapGetters, mapActions, mapMutations } from "vuex"
import { ROUTE_NAME, TABS } from "@/constants"

export default {
  name      : "Analytics",
  components: {
    ThemisExport,
    ThemisViews
  },
  data() {
    return {
      selectedTab: null,
      tabs       : {
        [TABS.VIEWS] : 0,
        [TABS.EXPORT]: 1
      },
      customViewNameToAdd    : undefined,
      addedChartSavedFilterId: undefined,
      addedChartCustomViewId : undefined
    }
  },
  computed: {
    ...mapGetters({
      isAddingExport                : "exports/isAddingExport",
      exports                       : "exports/exports",
      latestAnalyticsExport         : "exports/latestAnalyticsExport",
      isExportAdded                 : "exports/isExportAdded",
      domains                       : "domains/domains",
      channels                      : "channels/channels",
      filters                       : "filters/filters",
      loggedInUserPolicies          : "accessControl/loggedInUserPolicies",
      isCustomAnalyticsEnabled      : "configurations/isCustomAnalyticsEnabled",
      isAddingCustomView            : "customViews/isAddingCustomView",
      isCustomViewAdded             : "customViews/isCustomViewAdded",
      customViewAddError            : "customViews/customViewAddError",
      customViews                   : "customViews/customViews",
      charts                        : "charts/charts",
      loggedInUser                  : "auth/loggedInUser",
      isAddingChart                 : "charts/isAddingChart",
      isChartAdded                  : "charts/isChartAdded",
      chartAddError                 : "charts/chartAddError",
      groups                        : "groups/groups",
      isUpdatingCustomViewAccess    : "customViews/isUpdatingCustomViewAccess",
      isCustomViewAccessUpdated     : "customViews/isCustomViewAccessUpdated",
      customViewUpdatePolicies      : "accessControl/customViewUpdatePolicies",
      issueFields                   : "issueFields/issueFields",
      fieldsV2                      : "fields/fieldsV2",
      formTemplates                 : "formTemplates/formTemplates",
      issueFormTemplates            : "issueFormTemplates/issueFormTemplates",
      formTemplateConfigurations    : "formTemplateConfigurations/formTemplateConfigurations",
      isUpdatingCustomViewName      : "customViews/isUpdatingCustomViewName",
      isCustomViewNameUpdated       : "customViews/isCustomViewNameUpdated",
      customViewNameUpdateError     : "customViews/customViewNameUpdateError",
      isRemovingCustomView          : "customViews/isRemovingCustomView",
      isCustomViewRemoved           : "customViews/isCustomViewRemoved",
      filterIdWithIssueIds          : "issueSearch/filterIdWithIssueIds",
      optionListItems               : "optionListItems/optionListItems",
      users                         : "users/users",
      languages                     : "languages/languages",
      statuses                      : "issueStatuses/issueStatuses",
      issues                        : "issues/lastSearchIssues",
      isRemovingChart               : "charts/isRemovingChart",
      isChartRemoved                : "charts/isChartRemoved",
      notAccessibleFilterIds        : "issueSearch/notAccessibleFilterIds",
      isReloadAllCharts             : "customViews/isReloadAllCharts",
      customViewChartFiltersToReload: "customViews/customViewChartFiltersToReload",
      issueSearch                   : "issueSearch/issueSearch"
    }),
    showViewsTab() {
      return this.checkPropertyInAnalyticsViewWhere("insights")
    },
    showExportsTab() {
      return this.checkPropertyInAnalyticsViewWhere("export")
    },
    sortedCustomViews() {
      return [...this.customViews].sort((firstCustomView, secondCustomView) =>
        firstCustomView.id - secondCustomView.id
      )
    }
  },
  methods: {
    ...mapActions({
      addExport                  : "exports/addExport",
      loadExport                 : "exports/loadExport",
      downloadDataExport         : "exports/downloadDataExport",
      addCustomView              : "customViews/addCustomView",
      removeCustomView           : "customViews/removeCustomView",
      updateCustomView           : "customViews/updateCustomView",
      addChart                   : "charts/addChart",
      notify                     : "shared/notify",
      removeChart                : "charts/removeChart",
      loadIssueSearchWithCriteria: "issueSearch/loadIssueSearchWithCriteria",
      loadIssueSearch            : "issueSearch/loadIssueSearch",
      loadIssuesFromIssueSearchId: "issueSearch/loadIssuesFromIssueSearchId"
    }),
    ...mapMutations({
      setCustomViewAddError              : "customViews/setCustomViewAddError",
      resetChartAddError                 : "charts/resetChartAddError",
      setCustomViewUpdateError           : "customViews/setCustomViewUpdateError",
      resetCustomViewUpdateError         : "customViews/resetCustomViewUpdateError",
      resetReloadAllCharts               : "customViews/resetReloadAllCharts",
      resetCustomViewChartFiltersToReload: "customViews/resetCustomViewChartFiltersToReload",
      updateFilterIdWithIssueIds         : "issueSearch/updateFilterIdWithIssueIds",
      setCustomViewChartFiltersToReload  : "customViews/setCustomViewChartFiltersToReload"
    }),
    handleAddChart(chart) {
      if (chart.savedFilterId) {
        this.addedChartSavedFilterId = chart.savedFilterId
        this.addedChartCustomViewId  = chart.customViewId
      }
      this.addChart(chart)
    },
    handleTabClickEvent(tabName) {
      this.selectedTab = this.tabs[tabName]

      const tabRouteMapping = {
        [TABS.VIEWS] : ROUTE_NAME.ANALYTICS_VIEWS,
        [TABS.EXPORT]: ROUTE_NAME.ANALYTICS_EXPORT
      }

      const targetRouteName = tabRouteMapping[tabName]

      if (targetRouteName && this.$route.name !== targetRouteName) {
        this.$router.push({ name: targetRouteName })
      }
    },
    checkPropertyInAnalyticsViewWhere(property) {
      const analyticsViewPolicies = this.loggedInUserPolicies["Analytics view"]
      return analyticsViewPolicies && analyticsViewPolicies.some(analyticsViewPolicy =>
        analyticsViewPolicy.wheres && analyticsViewPolicy.wheres.some(where => where.type === property)
      )
    },
    handleAddCustomView(chart) {
      this.customViewNameToAdd = chart.name
      this.addCustomView(chart)
    },
    pollIssueSearch(id, customViewId) {
      new Promise((resolve, reject) => {
        const poll = async () => {
          try {
            await this.loadIssueSearch({ id })
            const status = this.issueSearch.status

            if (status === "completed") {
              const pollPromise = Promise.resolve()
              this.updateFilterIdWithIssueIds([{ filterId: this.issueSearch.filterId,
                issueIds: this.issueSearch.result }])
              if (customViewId) {
                this.setCustomViewChartFiltersToReload({ filterIds: [this.issueSearch.filterId], customViewId })
              }

              resolve(pollPromise)
            } else {
              setTimeout(poll, 1000)
            }
          } catch (error) {
            reject(error)
          }
        }

        poll()
      })
    }
  },
  watch: {
    "$route.name": {
      immediate: true,
      handler  : function(name) {
        if ((name === ROUTE_NAME.ANALYTICS_VIEWS || name === ROUTE_NAME.ANALYTICS_VIEW) && this.checkPropertyInAnalyticsViewWhere("insights")) {
          this.selectedTab = this.tabs[TABS.VIEWS]
        } else if (name === ROUTE_NAME.ANALYTICS_EXPORT && this.checkPropertyInAnalyticsViewWhere("export")) {
          this.selectedTab = this.tabs[TABS.EXPORT]
        } else {
          this.$router.push({ name: "forbidden" })
        }
      }
    },
    isCustomViewAdded: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "2247"
          })
          const customViewToRedirect = this.customViews.find(eachCustomView =>
            eachCustomView.name === this.customViewNameToAdd
          )
          if (customViewToRedirect) {
            this.$router.push({
              name  : ROUTE_NAME.ANALYTICS_VIEW,
              params: {
                id: customViewToRedirect.id
              }
            })
          }
          this.customViewNameToAdd = undefined
        }
      }
    },
    isCustomViewAccessUpdated: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "2282"
          })
        }
      }
    },
    isChartAdded: {
      handler: async function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "2289"
          })
        }
        if (this.addedChartSavedFilterId) {
          const selectedFilter = this.filterIdWithIssueIds.find(filterIdWithIssueId =>
            filterIdWithIssueId.filterId === this.addedChartSavedFilterId)
          if (!selectedFilter) {
            await this.loadIssueSearchWithCriteria({
              filterId                : this.addedChartSavedFilterId,
              excludeColumnPreferences: true,
              excludeFilterPreferences: true
            })
            this.pollIssueSearch(this.issueSearch.id, this.addedChartCustomViewId)
          }
          this.addedChartSavedFilterId = undefined
          this.addedChartCustomViewId  = undefined
        }
      }
    },
    isCustomViewNameUpdated: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "2312"
          })
        }
      }
    },
    isCustomViewRemoved: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "2317"
          })
          this.$router.push({
            name: ROUTE_NAME.ANALYTICS_VIEWS
          })
        }
      }
    },
    isRemovingCustomView: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.REMOVE_CUSTOM_VIEW.pActions[1].buttonProps.loading  = newValue
        this.$DECISIONS.REMOVE_CUSTOM_VIEW.pActions[0].buttonProps.disabled = newValue
      }
    },
    isChartRemoved: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "2340"
          })
        }
      }
    }
  }
}