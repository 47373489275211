export default {
  loadingCharts: false,
  charts       : new Array(),
  addingChart  : false,
  chartAdded   : false,
  chartAddError: undefined,
  updatingChart: {
    name     : false,
    creatorId: false
  },
  chartUpdated: {
    name     : false,
    creatorId: false
  },
  chartUpdateError: {
    name     : false,
    creatorId: false
  },
  removingChart: false,
  chartRemoved : false
}