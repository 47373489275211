import {
  DEFAULT_ISSUES_COLUMNS,
  ALLOWED_TYPES_FOR_ISSUE_FORMS_IN_COLUMNS,
  ALLOWED_TYPES_FOR_REPORTER_FORMS_IN_COLUMNS
} from "@/constants"

export default {
  name: "IssueColumnsPreference",
  data: () => ({
    issuesColumnsSearch               : undefined,
    localIssuesColumnsPreference      : undefined,
    isIssuesColumnsPreferenceAvailable: false,
    isIssueFieldsAvailable            : false,
    isFieldsAvailable                 : false,
    isFormTemplatesAvailable          : false,
    draggedIssuesColumn               : undefined,
    dragEnteredIssuesColumn           : undefined,
    isRestoringPreference             : false,
    isSavingPreferences               : false,
    issueFieldsForColumns             : [],
    formFieldsForColumns              : {
      issue   : [],
      reporter: []
    }
  }),
  props: {
    pIssuesColumnsPreference   : Array,
    pIssueFields               : Array,
    pFieldsMap                 : Object,
    pIsSaving                  : Boolean,
    pIsIssueFieldsEnabled      : Boolean,
    pFormTemplates             : Array,
    pIssueFormTemplates        : Array,
    pFormTemplateConfigurations: Array
  },
  methods: {
    isIssueColumnVisible(issuesColumnPreference) {
      return !this.issuesColumnsSearch ||
        issuesColumnPreference.name.toUpperCase().includes(this.issuesColumnsSearch.toUpperCase())
    },
    classForIssuesColumn() {
      return "white"
    },
    onIssuesColumnDragStart(issuesColumn) {
      this.draggedIssuesColumn     = issuesColumn
      this.dragEnteredIssuesColumn = this.draggedIssuesColumn
    },
    onIssuesColumnDragEnter(issuesColumn) {
      this.dragEnteredIssuesColumn = issuesColumn
    },
    onIssuesColumnDragOver(event) {
      event.preventDefault()
    },
    onIssuesColumnDragEnd() {
      if (this.draggedIssuesColumn.column !== this.dragEnteredIssuesColumn.column) {
        const indexOfDraggedIssuesColumn     = this.localIssuesColumnsPreference.findIndex(issuesColumn =>
          issuesColumn.column === this.draggedIssuesColumn.column
        )
        const indexOfDragEnteredIssuesColumn = this.localIssuesColumnsPreference.findIndex(issuesColumn =>
          issuesColumn.column === this.dragEnteredIssuesColumn.column
        )

        this.localIssuesColumnsPreference.splice(indexOfDraggedIssuesColumn, 1)
        this.localIssuesColumnsPreference.splice(indexOfDragEnteredIssuesColumn, 0, this.draggedIssuesColumn)
      }
    },
    handleSave() {
      this.isSavingPreferences = true
      this.$emit("save", this.localIssuesColumnsPreference.map(issuesColumnPreference => ({
        column  : issuesColumnPreference.column,
        selected: issuesColumnPreference.selected,
        type    : issuesColumnPreference.type
      })))
    },
    computeLocalIssuesColumnsPreference() {
      this.localIssuesColumnsPreference = []

      const issuesHeadersMap = new Map()
      for (const issuesHeader of this.$TABLES.ISSUES.headers) {
        if (!(["id", "icon"].includes(issuesHeader.value))) {
          issuesHeadersMap.set(issuesHeader.value, issuesHeader)
        }
      }

      const issueFieldsMap = new Map()
      if (this.pIsIssueFieldsEnabled) {
        for (const issueField of this.pIssueFields) {
          issueFieldsMap.set(issueField.id, issueField)
        }
      }

      const formTemplatesMap = new Map()
      for (const formTemplate of this.pFormTemplates) {
        formTemplatesMap.set(formTemplate.id, formTemplate)
      }

      for (const issuesColumnPreference of this.pIssuesColumnsPreference) {
        const issuesHeader = issuesHeadersMap.get(issuesColumnPreference.column)

        if (issuesHeader && issuesColumnPreference.type === "issue-property") {
          this.localIssuesColumnsPreference.push({
            column  : issuesHeader.value,
            selected: issuesColumnPreference.selected,
            name    : this.$t(issuesHeader.text),
            type    : issuesColumnPreference.type
          })
          issuesHeadersMap.delete(issuesColumnPreference.column)
        } else if (issuesColumnPreference.type === "issue-field") {
          if (issuesColumnPreference.selected) {
            const issueField = issueFieldsMap.get(issuesColumnPreference.column)
            if (issueField) {
              this.localIssuesColumnsPreference.push({
                column  : issueField.id,
                selected: issuesColumnPreference.selected,
                name    : this.pFieldsMap[issueField.fieldId].systemName,
                type    : issuesColumnPreference.type
              })
              issueFieldsMap.delete(issuesColumnPreference.column)
            }
          }
        } else if (issuesColumnPreference.type === "form-field") {
          const configuration = this.pFormTemplateConfigurations.find(formTemplateConfiguration =>
            formTemplateConfiguration.id === +issuesColumnPreference.column
          )
          if (configuration) {
            const formName = formTemplatesMap.get(configuration.formTemplateId).name
            const name     = this.$t("2254", {
              fieldName: this.pFieldsMap[configuration.fieldId].systemName,
              formName : formName
            })
            if (configuration && issuesColumnPreference.selected) {
              this.localIssuesColumnsPreference.push({
                column        : configuration.id,
                selected      : issuesColumnPreference.selected,
                type          : "form-field",
                formTemplateId: configuration.formTemplateId,
                name
              })
            }
          }
        }
      }
      for (const [headerValue, header] of issuesHeadersMap) {
        this.localIssuesColumnsPreference.push({
          column  : headerValue,
          selected: false,
          name    : this.$t(header.text),
          type    : "issue-property"
        })
      }
      for (const [issueFieldId, issueField] of issueFieldsMap) {
        this.issueFieldsForColumns.push({
          column  : issueFieldId,
          selected: false,
          name    : this.pFieldsMap[issueField.fieldId].systemName,
          type    : "issue-field"
        })
      }

      for (const formType of ["issue", "reporter"]) {
        const formTemplates     = formType === "issue" ? this.singleInstanceIssueFormTemplates : this.pFormTemplates.filter(template => template.reportForm)
        const allowedFieldTypes = formType === "issue" ? ALLOWED_TYPES_FOR_ISSUE_FORMS_IN_COLUMNS : ALLOWED_TYPES_FOR_REPORTER_FORMS_IN_COLUMNS
        for (const formTemplate of formTemplates) {
          const formTemplateId = formType === "issue" ? formTemplate.formTemplateId : formTemplate.id
          const configurations = this.getConfigurationsFromFormTemplate(formTemplateId)
          const formName       = formTemplatesMap.get(formTemplateId).name
          const isReporterForm = formTemplatesMap.get(formTemplateId).reportForm
          this.formFieldsForColumns[formType].push({
            formName,
            formTemplateId,
            displayName: isReporterForm ? this.$t("2253", { name: formName }) : this.$t("2252", { name: formName }),
            fields     : []
          })
          for (const configuration of configurations) {
            const field = this.pFieldsMap[configuration.fieldId]
            if (allowedFieldTypes.includes(field.type)) {
              const isExists = this.localIssuesColumnsPreference.some(preference => {
                return preference.column === configuration.id
              })
              if (!isExists) {
                this.formFieldsForColumns[formType][this.formFieldsForColumns[formType].length - 1].fields.push({
                  column  : configuration.id,
                  selected: false,
                  name    : this.$t("2254", { fieldName: field.systemName, formName: formName }),
                  type    : "form-field",
                  formTemplateId
                })
              }
            }
          }
          this.formFieldsForColumns[formType][this.formFieldsForColumns[formType].length - 1]
            .fields
            .sort((first, second) => first.name.localeCompare(second.name))
        }
        this.formFieldsForColumns[formType].sort((first, second) => first.formName.localeCompare(second.formName))
      }
    },
    getConfigurationsFromFormTemplate(formTemplateId) {
      return this.pFormTemplateConfigurations.filter(formTemplateConfiguration =>
        formTemplateConfiguration.formTemplateId === formTemplateId
      )
    },
    handleRestoreToDefault() {
      this.isRestoringPreference        = true
      this.localIssuesColumnsPreference = []
      for (const header of this.$TABLES.ISSUES.headers) {
        if (!(["id", "icon"].includes(header.value))) {
          this.localIssuesColumnsPreference.push({
            column  : header.value,
            selected: !!DEFAULT_ISSUES_COLUMNS.includes(header.value),
            name    : this.$t(header.text),
            type    : "issue-property"
          })
        }
      }
      for (const issueField of this.pIssueFields) {
        this.localIssuesColumnsPreference.push({
          column  : issueField.id,
          selected: false,
          name    : this.pFieldsMap[issueField.fieldId].systemName,
          type    : "issue-field"
        })
      }
      this.$emit("save", this.localIssuesColumnsPreference.map(issuesColumnPreference => ({
        column  : issuesColumnPreference.column,
        selected: issuesColumnPreference.selected,
        type    : issuesColumnPreference.type
      })))
    },
    async toggleIssueColumnsCheckbox(field, checked) {
      await this.$nextTick()
      field.selected = checked
      if (field.type === "issue-property") {
        return
      }
      if (field.type === "issue-field") {
        if (field.selected) {
          this.localIssuesColumnsPreference.push(field)
          this.issueFieldsForColumns = this.issueFieldsForColumns.filter(issueField =>
            issueField.column !== field.column
          )
        } else {
          this.issueFieldsForColumns.push(field)
          this.localIssuesColumnsPreference = this.localIssuesColumnsPreference.filter(issueField =>
            issueField.column !== field.column
          )
        }
      }
      if (field.type === "form-field") {
        const formType     = this.formTemplatesMap.get(field.formTemplateId).reportForm ? "reporter" : "issue"
        const formTemplate = this.formFieldsForColumns[formType].find(formFields =>
          formFields.formTemplateId === field.formTemplateId
        )

        if (field.selected) {
          this.localIssuesColumnsPreference.push(field)
          if (formTemplate) {
            formTemplate.fields = formTemplate.fields.filter(formField => formField.column !== field.column)
            if (!formTemplate.fields.length) {
              this.formFieldsForColumns[formType] = this.formFieldsForColumns[formType].filter(formFields =>
                formFields.formTemplateId !== field.formTemplateId
              )
            }
          }
        } else {
          if (formTemplate) {
            formTemplate.fields.push(field)
          } else {
            const formName       = this.formTemplatesMap.get(field.formTemplateId).name
            const isReporterForm = this.formTemplatesMap.get(field.formTemplateId).reportForm
            this.formFieldsForColumns[formType].push({
              formName      : this.formTemplatesMap.get(field.formTemplateId).name,
              displayName   : isReporterForm ? this.$t("2253", { name: formName }) : this.$t("2252", { name: formName }),
              formTemplateId: field.formTemplateId,
              fields        : [field]
            })
          }
          this.localIssuesColumnsPreference = this.localIssuesColumnsPreference.filter(formField =>
            formField.column !== field.column
          )
        }

        this.formFieldsForColumns[formType].sort((first, second) => first.formName.localeCompare(second.formName))

        this.formFieldsForColumns[formType].forEach(formFields => {
          formFields.fields.sort((first, second) => first.name.localeCompare(second.name))
        })
      }
    }
  },
  computed: {
    areIssuesColumnsDraggable() {
      return !this.issuesColumnsSearch || !this.issuesColumnsSearch.length
    },
    canComputeLocalIssuesColumnsPreference() {
      return this.isFieldsAvailable && this.isIssueFieldsAvailable &&
      this.isIssuesColumnsPreferenceAvailable && this.isFormTemplatesAvailable
    },
    sortedIssueFieldsForColumns() {
      return this.issueFieldsForColumns.sort((first, second) => first.name.localeCompare(second.name))
    },
    singleInstanceIssueFormTemplates() {
      return this.pIssueFormTemplates.filter(issueFormTemplate => !issueFormTemplate.multiInstance)
    },
    formTemplatesMap() {
      const formTemplatesMap = new Map()
      for (const formTemplate of this.pFormTemplates) {
        formTemplatesMap.set(formTemplate.id, formTemplate)
      }
      return formTemplatesMap
    }
  },
  watch: {
    pIssueFields: {
      immediate: true,
      handler  : function(newValue) {
        this.isIssueFieldsAvailable = !!newValue
      }
    },
    pFieldsMap: {
      immediate: true,
      handler  : function(newValue) {
        this.isFieldsAvailable = !!newValue
      }
    },
    pFormTemplates: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.isFormTemplatesAvailable = !!newValue
        }
      }
    },
    pIssuesColumnsPreference: {
      immediate: true,
      handler  : function(newValue) {
        this.isIssuesColumnsPreferenceAvailable = !!newValue
      }
    },
    canComputeLocalIssuesColumnsPreference: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.computeLocalIssuesColumnsPreference()
        }
      }
    },
    pIsSaving: {
      handler: function(newValue) {
        if (!newValue) {
          this.isRestoringPreference = false
          this.isSavingPreferences   = false
        }
      }
    }
  }
}