var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"data-cy":"2457","width":"806"}},[_c('v-card-title',{attrs:{"data-cy":"2458"}},[_vm._v(_vm._s(_vm.$t("1236")))]),_c('v-card-text',{attrs:{"data-cy":"2459"}},[_c('v-text-field',{ref:"input_columns_search",staticClass:"mb-4",attrs:{"data-cy":"2460","placeholder":_vm.$t('1237'),"prepend-inner-icon":"mdi-magnify","hide-details":"","outlined":""},model:{value:(_vm.issuesColumnsSearch),callback:function ($$v) {_vm.issuesColumnsSearch=$$v},expression:"issuesColumnsSearch"}}),_c('v-list',{ref:"list_issues_columns",staticClass:"overflow-auto",attrs:{"data-cy":"2461","max-height":"916"}},[_vm._l((_vm.localIssuesColumnsPreference),function(issuesColumnPreference,index){return (_vm.isIssueColumnVisible(issuesColumnPreference))?[_c('v-list-item',{ref:("list_item_issues_column_" + (issuesColumnPreference.column)),refInFor:true,class:_vm.classForIssuesColumn(issuesColumnPreference),attrs:{"data-cy":"2462","draggable":_vm.areIssuesColumnsDraggable},on:{"dragstart":function($event){return _vm.onIssuesColumnDragStart(issuesColumnPreference)},"dragenter":function($event){return _vm.onIssuesColumnDragEnter(issuesColumnPreference)},"dragover":_vm.onIssuesColumnDragOver,"dragend":_vm.onIssuesColumnDragEnd}},[_c('v-list-item-action',{attrs:{"data-cy":"2463"}},[_c('v-checkbox',{ref:("issues_column_preference_checkbox_" + index),refInFor:true,attrs:{"data-cy":("2464_" + index)},on:{"change":function($event){return _vm.toggleIssueColumnsCheckbox(issuesColumnPreference, $event)}},model:{value:(issuesColumnPreference.selected),callback:function ($$v) {_vm.$set(issuesColumnPreference, "selected", $$v)},expression:"issuesColumnPreference.selected"}})],1),_c('v-list-item-content',{attrs:{"data-cy":"2465"}},[_c('v-tooltip',{attrs:{"data-cy":"6605","nudge-left":"200","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({ref:("issues_column_preference_title_" + index),refInFor:true,staticClass:"text-truncate max-width-806",attrs:{"data-cy":("2466_" + index)}},'v-list-item-title',attrs,false),on),[_vm._v(_vm._s(issuesColumnPreference.name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(issuesColumnPreference.name))])])],1),(_vm.areIssuesColumnsDraggable)?_c('v-icon',{ref:"icon_drag_columns",refInFor:true,staticClass:"drag-cursor",attrs:{"data-cy":"2467"},on:{"mouseenter":function($event){_vm.issuesColumnIsDraggable = true},"mouseleave":function($event){_vm.issuesColumnIsDraggable = false}}},[_vm._v("mdi-drag-horizontal-variant")]):_vm._e()],1),_c('v-divider',{attrs:{"data-cy":"2468"}})]:_vm._e()}),(_vm.sortedIssueFieldsForColumns.length)?_c('div',{staticClass:"d-block mt-4"},[_c('span',{ref:"label_issue_fields",staticClass:"ml-4 info--text text--lighten-1",attrs:{"data-cy":"6600"}},[_vm._v(_vm._s(_vm.$t("2250")))]),_vm._l((_vm.sortedIssueFieldsForColumns),function(issueField,index){return (_vm.isIssueColumnVisible(issueField))?[_c('v-list-item',[_c('v-list-item-action',[_c('v-checkbox',{key:issueField.column,ref:("issue_field_checkbox_" + index),refInFor:true,attrs:{"data-cy":("6601_" + index),"value":issueField.selected},on:{"change":function($event){return _vm.toggleIssueColumnsCheckbox(issueField, $event)}}})],1),_c('v-list-item-content',{attrs:{"data-cy":"6602"}},[_c('v-tooltip',{attrs:{"data-cy":"6604","nudge-left":"200","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({ref:("issue_field_name_" + index),refInFor:true,staticClass:"text-truncate max-width-806",attrs:{"data-cy":("6603_" + index)}},'v-list-item-title',attrs,false),on),[_vm._v(_vm._s(issueField.name))])]}}],null,true)},[_c('span',{attrs:{"data-cy":("6610_" + (issueField.column))}},[_vm._v(_vm._s(issueField.name))])])],1)],1),_c('v-divider')]:_vm._e()})],2):_vm._e(),(_vm.formFieldsForColumns.issue.length)?_c('div',{ref:"issue_forms_list",staticClass:"d-block mt-4"},[_c('span',{ref:"label_issue_forms",staticClass:"ml-4 info--text text--lighten-1",attrs:{"data-cy":"6606"}},[_vm._v(_vm._s(_vm.$t("2251")))]),_vm._l((_vm.formFieldsForColumns.issue),function(form,index){return (form.fields.length)?_c('v-list',{key:index},[_c('div',{staticClass:"d-block body-1 ml-4 mb-4 grey--text text--darken-4",class:{ 'mt-4': index === 0 },attrs:{"data-cy":("6612_" + index)}},[_vm._v(_vm._s(form.displayName))]),_vm._l((form.fields),function(field,index){return (_vm.isIssueColumnVisible(field))?[_c('v-list-item',{key:index},[_c('v-list-item-action',[_c('v-checkbox',{key:field.column,ref:("form_field_checkbox_" + index),refInFor:true,attrs:{"data-cy":("6607_" + index),"value":field.selected},on:{"change":function($event){return _vm.toggleIssueColumnsCheckbox(field, $event)}}})],1),_c('v-list-item-content',{attrs:{"data-cy":"6602"}},[_c('v-tooltip',{attrs:{"data-cy":"6608","nudge-left":"200","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({ref:("issue_field_name_" + index),refInFor:true,staticClass:"text-truncate max-width-806",attrs:{"data-cy":("6609_" + index)}},'v-list-item-title',attrs,false),on),[_vm._v(_vm._s(field.name))])]}}],null,true)},[_c('span',{attrs:{"data-cy":("6611_" + (field.column))}},[_vm._v(_vm._s(field.name))])])],1)],1),_c('v-divider')]:_vm._e()})],2):_vm._e()})],2):_vm._e(),(_vm.formFieldsForColumns.reporter.length)?_c('div',{ref:"reporter_forms_list",staticClass:"d-block mt-4"},[_c('span',{ref:"label_reporter_forms",staticClass:"ml-4 info--text text--lighten-1",attrs:{"data-cy":"6618"}},[_vm._v(_vm._s(_vm.$t("2255")))]),_vm._l((_vm.formFieldsForColumns.reporter),function(form,index){return (form.fields.length)?_c('v-list',{key:index},[_c('div',{staticClass:"d-block body-1 ml-4 mb-4 grey--text text--darken-4",class:{ 'mt-4': index === 0 },attrs:{"data-cy":("6619_" + index)}},[_vm._v(_vm._s(form.displayName))]),_vm._l((form.fields),function(field,index){return (_vm.isIssueColumnVisible(field))?[_c('v-list-item',{key:index},[_c('v-list-item-action',[_c('v-checkbox',{key:field.column,ref:("reporter_form_field_checkbox_" + index),refInFor:true,attrs:{"data-cy":("6620_" + index),"value":field.selected},on:{"change":function($event){return _vm.toggleIssueColumnsCheckbox(field, $event)}}})],1),_c('v-list-item-content',{attrs:{"data-cy":"6602"}},[_c('v-tooltip',{attrs:{"data-cy":"6621","nudge-left":"200","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({ref:("reporter_form_field_name_" + index),refInFor:true,staticClass:"text-truncate max-width-806",attrs:{"data-cy":("6622_" + index)}},'v-list-item-title',attrs,false),on),[_vm._v(_vm._s(field.name))])]}}],null,true)},[_c('span',{attrs:{"data-cy":("6623_" + (field.column))}},[_vm._v(_vm._s(field.name))])])],1)],1),_c('v-divider')]:_vm._e()})],2):_vm._e()})],2):_vm._e()],2)],1),_c('v-card-actions',{staticClass:"pa-4",attrs:{"data-cy":"2469"}},[_c('v-row',{staticClass:"no-gutters",attrs:{"data-cy":"2470"}},[_c('v-col',{attrs:{"data-cy":"2471"}},[_c('v-btn',{ref:"button_restore_to_default",staticClass:"text-body-1",attrs:{"color":"primary","data-cy":"2487","loading":!_vm.isSavingPreferences && _vm.pIsSaving,"disabled":_vm.isSavingPreferences,"text":""},on:{"click":_vm.handleRestoreToDefault}},[_vm._v(_vm._s(_vm.$t("1245")))]),_c('v-btn',{ref:"button_save_columns_preference",staticClass:"float-right",attrs:{"data-cy":"2472","color":"primary","loading":!_vm.isRestoringPreference && _vm.pIsSaving,"disabled":_vm.isRestoringPreference},on:{"click":_vm.handleSave}},[_vm._v(_vm._s(_vm.$t("1239")))]),_c('v-btn',{ref:"button_cancel_columns_preference",staticClass:"float-right mr-8",attrs:{"data-cy":"2473","disabled":_vm.pIsSaving,"text":""},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(_vm._s(_vm.$t("1238")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }