export default {
  isLoadingCharts     : state => state.loadingCharts,
  charts              : state => state.charts,
  isChartAdded        : state => state.chartAdded,
  isAddingChart       : state => state.addingChart,
  isUpdatingChartName : state => state.updatingChart.name,
  isChartNameUpdated  : state => state.chartUpdated.name,
  chartNameUpdateError: state => state.chartUpdateError.name,
  chartAddError       : state => state.chartAddError,
  isChartRemoved      : state => state.chartRemoved,
  isRemovingChart     : state => state.removingChart
}