import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"
import store from "@/plugins/vuex"
import { SCREEN_ITEM_TYPE } from "../../constants"

const path = "/v1/screen-items"

export default {
  /**
   * This action will update screenItem.
   * @param {*} context is the store.
   * @param {*} payload contains screen item id and details to be updated.
   */
  async updateScreenItem(context, screenItem) {
    const id = screenItem.id
    delete screenItem.id

    let payload
    let propertiesToBeUpdated
    if (screenItem?.type === undefined) {
      propertiesToBeUpdated = Object.keys(screenItem)
      payload               = screenItem
    } else {
      switch (screenItem.type) {
        case SCREEN_ITEM_TYPE.FIELD:
          payload               = {
            field: {
              mandatory: screenItem.mandatory
            }
          }
          propertiesToBeUpdated = Object.keys(payload.field)
          break
        case SCREEN_ITEM_TYPE.ISSUE_PROPERTY:
          payload               = {
            issueProperty: {
              mandatory: screenItem.mandatory
            }
          }
          propertiesToBeUpdated = Object.keys(payload.issueProperty)
          break
        case SCREEN_ITEM_TYPE.FORM:
          payload               = {
            form: {
              ...screenItem.form
            }
          }
          propertiesToBeUpdated = Object.keys(payload.form.formField)
          break
      }
    }

    delete screenItem.type

    for (const property of propertiesToBeUpdated) {
      if (screenItem[property] === undefined) {
        screenItem[property] = null
      }
    }
    context.commit("setUpdatingScreenItem", propertiesToBeUpdated)
    context.commit("resetScreenItemUpdated", propertiesToBeUpdated)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const screenItemToBeUpdated = [{
      id,
      ...payload
    }]
    const updateItemResponse    = await httpClient.put(`/${id}`, payload)

    if (updateItemResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      store.commit("screens/updateScreenItems", screenItemToBeUpdated)
      context.commit("setScreenItemUpdated", propertiesToBeUpdated)
    }

    context.commit("resetUpdatingScreenItem", propertiesToBeUpdated)
  },

  /**
   * This action removes a particular screen item.
   * @param {*} context is the store.
   * @param {*} payload contains id of the screen item.
   */
  async removeScreenItem(context, payload) {
    context.commit("setRemovingScreenItem", true)
    context.commit("setScreenItemRemoved", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const deleteScreenItemResponse = await httpClient.delete(`/${payload}`)

    if (deleteScreenItemResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      store.commit("screens/removeScreenItem", payload)
      context.commit("setScreenItemRemoved", true)
    }
    context.commit("setRemovingScreenItem", false)
  },

  reset(context) {
    context.commit("setRemovingScreenItem", false)
    context.commit("setScreenItemRemoved", false)
    context.commit("resetScreenItemUpdated")
    context.commit("resetUpdatingScreenItem")
  }
}