import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE, BULK_IMPORT_STATUS, BULK_IMPORT_TYPES } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/bulk-imports"

export default {
  /**
   * This action uploads csv file to initiate bulk import.
   * @param {*} context is the store.
   * @param {*} payload contains file to be uploaded.
   */
  async uploadCsvFilesToBulkImport(context, payload) {
    context.commit("setUploadingCsvForBulkImport", true)
    context.commit("setCsvFileUploadedForBulkImport", false)

    const { name, type, file, domainId, speakUpFieldsMapping, speakUpUsersFieldsMapping, firstRowIsHeader } = payload

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const uploadUrlResponse = await httpClient.post(undefined, { name, type })
    if (uploadUrlResponse.status === HTTP_STATUS_CODE.OK) {
      const form = new FormData()
      Object.keys(uploadUrlResponse.data.fields).forEach(key =>
        form.append(key, uploadUrlResponse.data.fields[key]))
      form.append("file", file)

      const response = await fetch(uploadUrlResponse.data.url, {
        method: "POST",
        body  : form
      })
      const id       = uploadUrlResponse.data.id
      if (response.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
        if (type === BULK_IMPORT_TYPES.ISSUE) {
          await httpClient.put(`/${id}`, {
            status: BULK_IMPORT_STATUS.INITIATED,
            domainId,
            firstRowIsHeader,
            speakUpFieldsMapping
          })
        } else if(type === BULK_IMPORT_TYPES.USER){
          await httpClient.put(`/${id}`, {
            status: BULK_IMPORT_STATUS.INITIATED,
            domainId,
            firstRowIsHeader,
            speakUpUsersFieldsMapping
          })
        }
      }
    }
    context.commit("setCsvFileUploadedForBulkImport", true)
    context.commit("setUploadingCsvForBulkImport", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setCsvFileUploadedForBulkImport", undefined)
    context.commit("setUploadingCsvForBulkImport", undefined)
  }
}
