import ThemisRole from "@/components/role"
import ThemisInput from "@/components/shared/input"
import ThemisDecision from "@/components/shared/decision"
import { getHeadersForTable } from "@/utils/table"
import {
  TABLE_NAMES,
  ANCHOR_LINKS,
  MAX_CHARACTER_LIMIT,
  DEPENDENCY_CONDITIONS
} from "@/constants"
import ThemisReadOnly from "@/components/shared/read-only"

export default {
  name      : "Role",
  components: {
    ThemisRole,
    ThemisInput,
    ThemisReadOnly,
    ThemisDecision
  },
  data() {
    return {
      isDetailMode                    : true,
      isEditMode                      : false,
      isRoleNameDuplicate             : false,
      localRole                       : {},
      localPermissions                : [],
      localSelectedPermissions        : [],
      roleNameMaxCharacterLimit       : MAX_CHARACTER_LIMIT.ROLE_NAME,
      roleDescriptionMaxCharacterLimit: MAX_CHARACTER_LIMIT.ROLE_DESCRIPTION,
      selectableRolePermissions       : [],
      showHideRoleDialog              : false,
      savingRole                      : false
    }
  },
  props: {
    pAllPermissions      : Array,
    pRole                : Object,
    pIsRoleUpdating      : Boolean,
    pIsRoleUpdated       : Boolean,
    pRoleUpdateError     : Object,
    pIsCustomRolesEnabled: Boolean,
    pIsRoleShowUpdated   : Boolean
  },
  methods: {
    async onRowSelection(item, value) {
      await this.$nextTick()

      if (item) {
        const dependentPermissions = this.localPermissions.filter(permission =>
          permission.dependency && permission.dependency.some(permission => permission.id === item.id)
        )

        for (const permission of dependentPermissions) {
          if (value) {
            if (permission.dependencyCondition === DEPENDENCY_CONDITIONS.AND) {
              permission.choosable = permission.dependency.every(dependency =>
                this.localSelectedPermissions.some(permission => permission.id === dependency.id)
              )
            } else {
              permission.choosable = true
            }
          } else if (permission.choosable) {
            if (permission.dependencyCondition === DEPENDENCY_CONDITIONS.OR) {
              permission.choosable = permission.dependency.some(dependency =>
                item.name !== dependency.name &&
                this.localSelectedPermissions.some(permission => permission.id === dependency.id)
              )
            } else {
              permission.choosable = false
            }

            if (!permission.choosable) {
              this.localSelectedPermissions = this.localSelectedPermissions.
                filter(selectedPermission => selectedPermission.id !== permission.id)
            }
          }
        }

        if (value) {
          this.localSelectedPermissions.push(item)
        } else {
          this.localSelectedPermissions = this.localSelectedPermissions.
            filter(selectedPermission => selectedPermission.id !== item.id)
        }

        this.updateSelectablePropertyForPermissions()
      }
    },
    handleEditRole() {
      if (!this.pIsCustomRolesEnabled) {
        this.$router.push({ name: "role", params: { id: this.role.id } })
      } else {
        this.isEditMode   = true
        this.isDetailMode = false

        if (this.$route.hash !== ANCHOR_LINKS.EDIT) {
          this.$router.push({ hash: ANCHOR_LINKS.EDIT })
        }
      }
    },
    handleCancel() {
      if (this.pIsRoleUpdating) {
        return
      }

      this.resetComponent()
      this.$emit("cancel")
    },
    handleSave() {
      const isPermissionsUpdated = this.hasSelectedPermissionsChanged()

      if (!this.isRoleNameUpdated
        && !this.isRoleDescriptionUpdated
        && !isPermissionsUpdated
      ) {
        return
      }

      const roleDetailsToBeUpdated = {
        id: this.role.id
      }

      if (this.isRoleNameUpdated) {
        roleDetailsToBeUpdated["name"] = this.localRole.name
      }

      if (this.isRoleDescriptionUpdated) {
        roleDetailsToBeUpdated["description"] = this.localRole.description
      }

      if (isPermissionsUpdated) {
        roleDetailsToBeUpdated["permissions"] = this.localSelectedPermissions
      }

      this.savingRole = true
      this.$emit("save", roleDetailsToBeUpdated)
    },
    handleShowUpdate(value) {
      if (value) {
        const roleDetailsToBeUpdated = {
          id  : this.role.id,
          show: true
        }
        this.$emit("save", roleDetailsToBeUpdated)
      } else {
        this.showHideRoleDialog = true
      }
    },
    handleHideRoleDecisionYes(){
      const roleDetailsToBeUpdated = {
        id  : this.role.id,
        show: false
      }
      this.$emit("save", roleDetailsToBeUpdated)
    },
    handleHideRoleDecisionNo(){
      this.localRole.show     = true
      this.showHideRoleDialog = false
    },
    updateSelectablePropertyForPermissions() {
      for (const permission of this.localPermissions) {
        if (this.isDetailMode) {
          permission.choosable = false
        } else {
          if (permission.dependencyCondition === DEPENDENCY_CONDITIONS.AND) {
            permission.choosable = permission.dependency.every(dependency =>
              this.localSelectedPermissions.some(permission => permission.id === dependency.id)
            )
          } else if (permission.dependencyCondition === DEPENDENCY_CONDITIONS.OR
              || (!permission.dependencyCondition && permission?.dependency?.length)
          ) {
            permission.choosable = permission.dependency.some(dependency =>
              permission.name !== dependency.name &&
              this.localSelectedPermissions.some(permission => permission.id === dependency.id)
            )
          } else {
            permission.choosable = true
          }

          const isPermissionSelected = this.localSelectedPermissions.
            find(selectedPermission => selectedPermission.id === permission.id)

          if (!permission.choosable && isPermissionSelected) {
            this.localSelectedPermissions = this.localSelectedPermissions.
              filter(selectedPermission => selectedPermission.id !== permission.id)
          }
        }
      }

      this.localPermissions = [...this.localPermissions]
    },
    hasSelectedPermissionsChanged() {
      const allOriginalPermissions = this.pRole.permissions.filter(permission => permission.selectable)
      for (const oldPermission of allOriginalPermissions) {
        const selectedPermission = this.localSelectedPermissions.find(permission => permission.id === oldPermission.id)
        if (!selectedPermission) {
          return true
        }
      }

      for (const selectedPermission of this.localSelectedPermissions) {
        const oldPermission = allOriginalPermissions.find(permission => permission.id === selectedPermission.id)
        if (!oldPermission) {
          return true
        }
      }

      return false
    },
    resetComponent() {
      this.localRole.name           = this.pRole.name
      this.localRole.description    = this.pRole.description
      const roleSelectedPermissions = []
      for (const rolePermission of this.pRole.permissions) {
        if (rolePermission.selectable) {
          roleSelectedPermissions.push(this.localPermissions.find(permission => permission.id === rolePermission.id))
        }
      }
      this.localSelectedPermissions = [...roleSelectedPermissions]
      this.updateSelectablePropertyForPermissions()
    },
    getDisplayNameForPermission(permissionName) {
      return this.$t(this.$CONSTANTS.PERMISSION_NAME_TO_DISPLAY_NAME_MAP[permissionName]) || permissionName
    },
    getDependenciesText(permission) {
      if (permission.dependency && permission.dependency.length) {
        const joinWord        = permission.dependencyCondition
        const dependencyNames = permission.dependency
          .map(dependency => this.getDisplayNameForPermission(dependency.name)).join(` ${joinWord} `)
        return dependencyNames
      }
    },
    getEitherOrAllText(permission) {
      return permission.dependencyCondition === DEPENDENCY_CONDITIONS.OR ? this.$t("2101") : this.$t("2121")
    },
    getPermissionRequiresText(permission) {
      if (permission.dependency && permission.dependency.length > 3) {
        return this.$t("2102")
      } else {
        const dependencyNames = this.getDependenciesText(permission)
        if (dependencyNames) {
          return this.$t("2103", { permissions: dependencyNames })
        } else {
          return ""
        }
      }
    },
    shouldTooltipBeDisabled(permission) {
      return this.isDetailMode
        || permission.choosable
        || (permission.dependency && permission.dependency.length > 3)
    }
  },
  computed: {
    role() {
      return this.pRole
    },
    isRoleUpdating() {
      return this.pIsRoleUpdating && this.savingRole
    },
    showEditButton() {
      return this.isDetailMode && this.pIsCustomRolesEnabled
    },
    isSystemDefaultRole() {
      return this.role.systemDefault
    },
    isSaveButtonDisabled() {
      return !this.localRole.name ||
        !this.localRole.description ||
        this.localRole.name.length > this.roleNameMaxCharacterLimit ||
        this.localRole.description.length > this.roleDescriptionMaxCharacterLimit ||
        this.isRoleNameDuplicate ||
        !this.isRoleDetailsUpdated ||
        !this.localSelectedPermissions.length
    },
    isRoleDetailsUpdated() {
      return this.isRoleNameUpdated
        || this.isRoleDescriptionUpdated
        || this.hasSelectedPermissionsChanged()
    },
    isRoleNameUpdated() {
      return this.localRole.name !== this.role.name
    },
    isRoleDescriptionUpdated() {
      return this.localRole.description !== this.role.description
    },
    headerRolePermissions() {
      return getHeadersForTable(TABLE_NAMES.ROLE_PERMISSIONS, this.$t.bind(this))
    },
    showNotEditableChip(){
      return this.isEditMode && this.isSystemDefaultRole && this.pIsCustomRolesEnabled
    }
  },
  watch: {
    pIsRoleUpdating: {
      immediate: true,
      handler  : function(isUpdating) {
        if (this.showHideRoleDialog){
          this.$DECISIONS.HIDE_ROLE.pActions[0].buttonProps.disabled = isUpdating
          this.$DECISIONS.HIDE_ROLE.pActions[1].buttonProps.loading  = isUpdating
        }
      }
    },
    pIsRoleUpdated: {
      immediate: true,
      handler  : function(isUpdated) {
        if (isUpdated) {
          this.savingRole   = false
          this.isEditMode   = false
          this.isDetailMode = true
          this.$router.push({ name: "role", params: { id: this.role.id } })
          this.updateSelectablePropertyForPermissions()
        }
      }
    },
    pRole: {
      immediate: true,
      handler  : function(role) {
        this.localRole                 = JSON.parse(JSON.stringify(role))
        this.selectableRolePermissions = role.permissions.filter(permission => permission.selectable)
      }
    },
    pAllPermissions: {
      immediate: true,
      handler  : function(allPermissions) {
        const roleSelectedPermissions = []
        for (const rolePermission of this.selectableRolePermissions) {
          const permission = this.pAllPermissions.filter(permission => permission.selectable)
            .find(permission => permission.id === rolePermission.id)
          if (permission) {
            roleSelectedPermissions.push({
              id                 : permission.id,
              name               : permission.name,
              selectable         : permission.selectable,
              dependencyCondition: permission?.dependencies?.dependencyCondition,
              dependency         : permission?.dependencies?.dependency.map(dependencyId => {
                const dependencyPermission = this.pAllPermissions.filter(permission => permission.selectable)
                  .find(permission => permission.id === dependencyId)
                return {
                  id                 : dependencyPermission.id,
                  name               : dependencyPermission.name,
                  dependency         : dependencyPermission?.dependencies?.dependency,
                  dependencyCondition: dependencyPermission?.dependencies?.dependencyCondition
                }
              })
            })
          }
        }

        this.localSelectedPermissions = [...roleSelectedPermissions]
        this.localPermissions         = allPermissions.filter(permission => permission.selectable).map(permission => {
          const result = {
            id                 : permission.id,
            name               : permission.name,
            selectable         : permission.selectable,
            dependencyCondition: permission?.dependencies?.dependencyCondition,
            dependency         : permission?.dependencies?.dependency.map(dependencyId => {
              const dependencyPermission = this.pAllPermissions.filter(permission => permission.selectable)
                .find(permission => permission.id === dependencyId)
              return {
                id                 : dependencyPermission.id,
                name               : dependencyPermission.name,
                dependency         : dependencyPermission?.dependencies?.dependency,
                dependencyCondition: dependencyPermission?.dependencies?.dependencyCondition
              }
            })
          }
          return result
        })

        this.updateSelectablePropertyForPermissions()
      }
    },
    pRoleUpdateError: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue?.field === "name" && newValue?.type === "duplicate") {
          this.isRoleNameDuplicate = true
        } else {
          this.isRoleNameDuplicate = false
        }
      }
    },
    pIsRoleShowUpdated: {
      immediate: true,
      handler  : function(isShowUpdated){
        if (isShowUpdated){
          this.showHideRoleDialog = false
        }
      }
    },
    "localRole.name": {
      handler: function() {
        if (this.isRoleNameDuplicate) {
          this.$emit("resetRoleUpdateError")
        }
      }
    },
    "$route.hash": {
      immediate: true,
      handler  : function(hash, oldHash) {
        if (hash === oldHash) {
          return
        }
        if (hash === ANCHOR_LINKS.EDIT) {
          this.handleEditRole()
        } else {
          this.isEditMode   = false
          this.isDetailMode = true
        }
        this.updateSelectablePropertyForPermissions()
      }
    }
  }
}