import moment from "moment"
import {
  ISSUE_TYPES,
  MAX_CHARACTER_LIMIT,
  QUICK_LINKS_CONFIGURATION,
  ROUTE_NAME,
  MAXIMUM_CHARTS_PER_CUSTOM_VIEW,
  REPORT_SOURCE,
  ISSUE_PROPERTIES_FOR_CHART,
  CHART_DIMENSION_TYPES,
  METRICS,
  FIELD_TYPES,
  CHART_TYPES,
  SHARE_ACCESS,
  DEFAULT_CHARTS,
  SUBDIVISION_LABEL_OVERRIDES,
  VERTICAL_CHART_HEIGHT
} from "@/constants"
import ThemisInput from "@/components/shared/input"
import ThemisDateTimePicker from "@/components/shared/date-time-picker"
import ThemisCascadedInput from "@/components/shared/cascaded-input"
import ThemisReadOnly from "@/components/shared/read-only"
import ThemisChartAdd from "@/components/chart/add"
import ThemisShareAccess from "@/components/share-access"
import ThemisDecision from "@/components/shared/decision"

import { getBarChartOptions, getHorizontalBarChartHeight } from "@/constants/charts"

export default {
  name      : "Views",
  components: {
    ThemisInput,
    ThemisCascadedInput,
    ThemisDateTimePicker,
    ThemisReadOnly,
    ThemisChartAdd,
    ThemisShareAccess,
    ThemisDecision
  },
  data() {
    return {
      selectedItem             : 0,
      showDateRangeMenu        : false,
      selectedDates            : new Array(),
      compareToPreviousPeriod  : false,
      validSelectedDatesForKpis: new Array(),
      maximumNumberOfDaysLimit : MAX_CHARACTER_LIMIT.INSIGHTS_DATE_RANGE,
      dateFormatForValidation  : "DD-MM-YYYY",
      quickLinks               : QUICK_LINKS_CONFIGURATION,
      showAddChartDialog       : false,
      showCreateNewViewDialog  : false,
      showDeleteViewDialog     : false,
      newCustomView            : {
        name: null
      },
      localCustomView: {
        name: undefined
      },
      isCustomViewNameDuplicate      : false,
      isCustomViewUpdateNameDuplicate: false,
      customViewNameCharacterLimit   : MAX_CHARACTER_LIMIT.CUSTOM_VIEW_NAME,
      sources                        : Object.values(REPORT_SOURCE),
      showManageAccessDialog         : false,
      showDeleteChartDialog          : false,
      chartToDelete                  : null,
      chartDataList                  : [],
      computeChartTimeouts           : [],
      newChart                       : null
    }
  },
  props: {
    pDomains                       : Array,
    pChannels                      : Array,
    pUsers                         : Array,
    pLanguages                     : Array,
    pStatuses                      : Array,
    pFilterIdWithIssueIds          : Array,
    pIssues                        : Array,
    pLoggedInUserPolicies          : Object,
    pIsCustomAnalyticsEnabled      : Boolean,
    pIsAddingCustomView            : Boolean,
    pIsCustomViewAdded             : Boolean,
    pCustomViewAddError            : Object,
    pCustomViews                   : Array,
    pCharts                        : Array,
    pGroups                        : Array,
    pIsUpdatingAccess              : Boolean,
    pIsAccessUpdated               : Boolean,
    pCustomViewUpdatePolicies      : Array,
    pFilters                       : Array,
    pIsAddingChart                 : Boolean,
    pIsChartAdded                  : Boolean,
    pChartAddError                 : Object,
    pIssueFields                   : Array,
    pFields                        : Array,
    pFormTemplates                 : Array,
    pFormTemplateConfigurations    : Array,
    pIssueFormTemplates            : Array,
    pIsUpdatingCustomView          : Boolean,
    pCustomViewNameUpdateError     : Object,
    pLoggedInUser                  : Object,
    pIsRemovingCustomView          : Boolean,
    pIsCustomViewRemoved           : Boolean,
    pOptionListItems               : Array,
    pNotAccessibleSavedFilterIds   : Array,
    pIsRemovingChart               : Boolean,
    pIsChartRemoved                : Boolean,
    pIsResetAndLoadAllCharts       : Boolean,
    pCustomViewChartFiltersToReload: Object
  },
  computed: {
    defaultDateRange() {
      return {
        startDate: moment().subtract(365, "days").format("YYYY-MM-DD"),
        endDate  : moment().format("YYYY-MM-DD")
      }
    },
    dateRangeText() {
      if (!this.selectedDates.length) {
        this.selectedDates             = [this.defaultDateRange.startDate, this.defaultDateRange.endDate]
        this.validSelectedDatesForKpis = this.selectedDates
      }
      return this.selectedDates.map(date => moment(date).format("DD-MM-YYYY")).join(" ~ ")
    },
    filterAccessesToSelect() {
      return [{
        value: SHARE_ACCESS.EVERYONE,
        text : this.$t("2276")
      }, {
        value: SHARE_ACCESS.PRIVATE,
        text : this.$t("2277")
      }, {
        value: SHARE_ACCESS.GROUPS,
        text : this.$t("2278")
      }]
    },
    groupsToSelect() {
      return this.pGroups.map(group => {
        return {
          id  : group.id,
          text: group.name
        }
      })
    },
    canShareAccess() {
      const customViewUpdatePolicy = this.pCustomViewUpdatePolicies.find(currentCustomViewUpdatePolicy =>
        currentCustomViewUpdatePolicy.id === this.customViewId
      )
      return customViewUpdatePolicy?.set?.access !== undefined
    },
    currentAccess() {
      if (this.customView) {
        if (this.customView.access === SHARE_ACCESS.PRIVATE) {
          return this.$t("2083")
        } else if (this.customView.access === SHARE_ACCESS.GROUPS) {
          return this.$t("1935")
        }
        return this.$t("1936")
      } else {
        return this.$t("1936")
      }
    },
    previousTimeRange() {
      const numberOfDays = moment(this.validSelectedDatesForKpis[1]).diff(this.validSelectedDatesForKpis[0], "days")
      return [
        moment(this.validSelectedDatesForKpis[0]).subtract(numberOfDays + 1, "days"),
        moment(this.validSelectedDatesForKpis[0]).subtract(1, "days")
      ]
    },
    casesInCurrentDateRange() {
      return this.filterIssuesByDateRange(this.validSelectedDatesForKpis)
    },
    casesInPreviousDateRange() {
      return this.filterIssuesByDateRange(this.previousTimeRange)
    },
    numberOfCasesInCurrentDateRange() {
      return this.casesInCurrentDateRange.length
    },
    averageTimeToCloseInCurrentDateRange() {
      return this.getAverageTimeToClose(this.casesInCurrentDateRange)
    },
    averageTimeToCloseInPreviousDateRange() {
      return this.getAverageTimeToClose(this.casesInPreviousDateRange)
    },
    differenceInCases() {
      return this.getDifference(
        this.numberOfCasesInCurrentDateRange,
        this.casesInPreviousDateRange.length
      )
    },
    monthsForGraph() {
      const months = []
      if (this.validSelectedDatesForKpis.length === 2) {
        const startDate = moment(this.validSelectedDatesForKpis[0]).startOf("month")
        const endDate   = moment(this.validSelectedDatesForKpis[1]).endOf("month")

        const currentDate = startDate.clone()
        while (currentDate.isSameOrBefore(endDate, "month")) {
          months.push(currentDate.format("MMM YYYY"))
          currentDate.add(1, "month")
        }
      }
      return months
    },
    differenceInAverageTimeToClose() {
      return this.getDifference(
        this.averageTimeToCloseInCurrentDateRange,
        this.averageTimeToCloseInPreviousDateRange,
        this.casesInPreviousDateRange.length
      )
    },
    reportsInCurrentDateRange() {
      return this.getReports(this.casesInCurrentDateRange)
    },
    reportsInPreviousDateRange() {
      return this.getReports(this.casesInPreviousDateRange)
    },
    numberOfReportsInCurrentDateRange() {
      return this.reportsInCurrentDateRange.length
    },
    differenceInReports() {
      return this.getDifference(
        this.numberOfReportsInCurrentDateRange,
        this.reportsInPreviousDateRange.length
      )
    },
    currentNumberOfCheckBackReports() {
      return this.getNumberOfCheckBackReports(this.reportsInCurrentDateRange)
    },
    previousNumberOfCheckBackReports() {
      return this.getNumberOfCheckBackReports(this.reportsInPreviousDateRange)
    },
    currentReportsCheckBackRate() {
      return this.getCheckBackRate(this.numberOfReportsInCurrentDateRange, this.currentNumberOfCheckBackReports)
    },
    previousReportsCheckBackRate() {
      return this.getCheckBackRate(this.reportsInPreviousDateRange.length, this.previousNumberOfCheckBackReports)
    },
    differenceInReporterCheckBack() {
      return this.getDifference(
        this.currentReportsCheckBackRate,
        this.previousReportsCheckBackRate,
        this.reportsInPreviousDateRange.length
      )
    },
    kpisToDisplay() {
      const result = []
      if (this.isSelectPresentInViewPolicies("Issue view")) {
        result.push({
          kpiTitle      : this.$t("1141"),
          value         : this.numberOfCasesInCurrentDateRange,
          difference    : this.differenceInCases,
          class         : this.getColor(this.differenceInCases, true),
          kpiTooltipText: this.$t("1627")
        })
      }
      if (this.isSelectPresentInViewPolicies("Report view")) {
        result.push({
          kpiTitle      : this.$t("474"),
          value         : this.numberOfReportsInCurrentDateRange,
          difference    : this.differenceInReports,
          class         : this.getColor(this.differenceInReports, true),
          kpiTooltipText: this.$t("1640")
        })
      }

      if (this.isSelectPresentInViewPolicies("Message view")) {
        result.push({
          kpiTitle: this.$t("28"),
          value   : this.checkNotApplicable(
            this.currentReportsCheckBackRate, "27", "percentage"
          ),
          difference: this.checkNotApplicable(
            this.differenceInReporterCheckBack, "27", "percentage"
          ),
          class         : this.getColor(this.differenceInReporterCheckBack, true),
          kpiTooltipText: this.$t("29")
        })
      }

      if (this.checkPropertyInIssueViewPoliciesSelect("createdAt") &&
        this.checkPropertyInIssueViewPoliciesSelect("receivedAt")) {
        result.push({
          kpiTitle: this.$t("1144"),
          value   : this.checkNotApplicable(
            this.averageTimeToCloseInCurrentDateRange, "1146", "numberOfDays"
          ),
          difference    : this.differenceInAverageTimeToClose,
          class         : this.getColor(this.differenceInAverageTimeToClose, false),
          kpiTooltipText: this.$t("1145")
        })
      }
      return result
    },
    filterOnlyCases() {
      return this.pIssues?.filter(issue => issue.typeId === ISSUE_TYPES[0].id) ?? []
    },
    customViewId() {
      return +this.$route.params.id
    },
    isRouteAnalyticsView() {
      return this.$route.name === ROUTE_NAME.ANALYTICS_VIEW
    },
    customView() {
      return this.pCustomViews?.find(eachCustomView => eachCustomView.id === this.customViewId)
    },
    charts() {
      return this.pCharts?.filter(chart => chart.customViewId === this.customView?.id)
    },
    usersMap() {
      const usersMap = {}
      for (const user of this.pUsers) {
        usersMap[user.id] = user
      }
      return usersMap
    },
    channelsMap() {
      const channelsMap = {}
      for (const channel of this.pChannels) {
        channelsMap[channel.id] = channel
      }
      return channelsMap
    },
    domainsMap() {
      const domainsMap = {}
      for (const domain of this.pDomains) {
        domainsMap[domain.id] = domain
      }
      return domainsMap
    },
    languagesMap() {
      const languagesMap = {}
      for (const language of this.pLanguages) {
        languagesMap[language.id] = language
      }
      return languagesMap
    },
    statusesMap() {
      const statusesMap = {}
      for (const status of this.pStatuses) {
        statusesMap[status.id] = status
      }
      return statusesMap
    },
    issueFieldsMap() {
      const issueFieldsMap = {}
      for (const issueField of this.pIssueFields) {
        issueFieldsMap[issueField.id] = issueField
      }
      return issueFieldsMap
    },
    fieldsMap() {
      const fieldsMap = {}
      for (const field of this.pFields) {
        fieldsMap[field.id] = field
      }
      return fieldsMap
    },
    filterIdIssueIdsMap() {
      const filterIdIssueIdsMap = {}
      for (const filterIdIssueId of this.pFilterIdWithIssueIds) {
        filterIdIssueIdsMap[filterIdIssueId.filterId] = filterIdIssueId.issueIds
      }
      return filterIdIssueIdsMap
    },
    formTemplateConfigurationsMap() {
      const formTemplateConfigurationsMap = {}
      for (const formTemplateConfig of this.pFormTemplateConfigurations) {
        formTemplateConfigurationsMap[formTemplateConfig.id] = formTemplateConfig.fieldId
      }
      return formTemplateConfigurationsMap
    },
    isDefaultView() {
      return !(this.isRouteAnalyticsView && !!this.customView)
    },
    chartsToDisplay() {
      if (this.isDefaultView) {
        const charts = []
        if (this.isSelectPresentInViewPolicies("Issue view")) {
          charts.push(DEFAULT_CHARTS[0])
        }
        if (this.isSelectPresentInViewPolicies("Issue view") && this.isSelectPresentInViewPolicies("Domain view")) {
          charts.push(DEFAULT_CHARTS[1])
        }
        if (this.isSelectPresentInViewPolicies("Report view") && this.isSelectPresentInViewPolicies("Channel view")) {
          charts.push(DEFAULT_CHARTS[2])
        }
        return charts
      } else {
        return this.charts
      }
    },
    isMaximumChartsPerCustomViewLimitReached() {
      return this.charts.length >= MAXIMUM_CHARTS_PER_CUSTOM_VIEW
    },
    isCurrentViewOwner() {
      return this.customView?.creatorId === this.pLoggedInUser.id
    },
    listItemsToDisplay() {
      return [{
        id  : 0,
        name: this.$t("2225")
      },
      ...this.pCustomViews]
    }
  },
  methods: {
    handleDateInput() {
      if (this.selectedDates.length === 2) {
        const fromDate = new Date(this.selectedDates[0])
        const toDate   = new Date(this.selectedDates[1])
        if (toDate.getTime() < fromDate.getTime()) {
          this.selectedDates = [this.selectedDates[1], this.selectedDates[0]]
        }
        const startDate = moment(this.selectedDates[0])
        const endDate   = moment(this.selectedDates[1])
        const diff      = endDate.diff(startDate, "days")
        if (diff <= this.maximumNumberOfDaysLimit) {
          this.validSelectedDatesForKpis = this.selectedDates
        }
        this.showDateRangeMenu = false
      }
    },
    closeShareAccessDialog() {
      this.showManageAccessDialog = false
    },
    handleShareAccess(access) {
      this.$emit("updateCustomView", access)
    },
    filterIssuesByDateRange(dateRange) {
      if (dateRange.length !== 2) {
        return []
      }

      const [start, end] = dateRange.map(date => new Date(date))
      start.setHours(0, 0, 0, 0)
      end.setHours(23, 59, 59, 999)

      return this.filterOnlyCases.filter(kpi => {
        const createdAt = new Date(kpi.createdAt)
        return createdAt >= start && createdAt <= end
      })
    },
    getColor(value, showGreenForPositive) {
      if (value === this.$t("1143")) {
        return "grey--text text--darken-4"
      } else if ((Number(value) >= 0 && showGreenForPositive)
        || (Number(value) < 0 && !showGreenForPositive)) {
        return "secondary--text"
      } else {
        return "error--text text--darken-2"
      }
    },
    getDifference(currentData, previousData, previousCount) {
      const numberOfCases   = previousCount ?? previousData
      const isNotApplicable = numberOfCases === 0
        || previousData === this.$t("1143")
        || currentData === this.$t("1143")
      if (isNotApplicable) {
        return this.$t("1143")
      }

      const difference = currentData - previousData
      return difference > 0 ? this.$t("24", { difference }) : difference
    },
    getAverageTimeToClose(cases) {
      const casesWithClosedAt = cases.filter(kpi => kpi.closedAt)
      if (casesWithClosedAt.length === 0) {
        return this.$t("1143")
      }
      const days = casesWithClosedAt
        .map(kpi => {
          const closedAt              = moment(kpi.closedAt)
          const receivedAtOrCreatedAt = moment(kpi.receivedAt ? kpi.receivedAt : kpi.createdAt)
          const diffInMilliseconds    = closedAt.diff(receivedAtOrCreatedAt)
          return diffInMilliseconds / (1000 * 60 * 60 * 24)
        })

      const totalDays = days.reduce((total, day) => total + day, 0)
      return Math.ceil(totalDays / casesWithClosedAt.length)
    },
    getReports(cases) {
      return cases.filter(issue => issue.report?.id)
    },
    getNumberOfCheckBackReports(reports) {
      return reports.filter(issue => issue.kpi?.hasReporterCheckBack)?.length
    },
    getCheckBackRate(numberOfReports, numberOfCheckBackReports) {
      return numberOfReports ? Math.ceil(numberOfCheckBackReports / numberOfReports * 100) : this.$t("1143")
    },
    checkNotApplicable(value, locale, localeVariable) {
      return value === this.$t("1143")
        ? this.$t("1143")
        : this.$t(locale, { [localeVariable]: value })
    },
    isSelectPresentInViewPolicies(policyName) {
      const viewPolicies = this.pLoggedInUserPolicies[policyName]
      return viewPolicies && viewPolicies.some(eachViewPolicy =>
        eachViewPolicy.select && eachViewPolicy.select.length > 0)
    },
    checkPropertyInIssueViewPoliciesSelect(property) {
      const issueViewPolicies = this.pLoggedInUserPolicies["Issue view"]
      return issueViewPolicies && issueViewPolicies.some(issueViewPolicy =>
        issueViewPolicy.select && issueViewPolicy.select.includes(property)
      )
    },
    monthYears() {
      if (this.validSelectedDatesForKpis.length === 2) {
        const monthYears = []
        const startDate  = moment(this.validSelectedDatesForKpis[0])
        const endDate    = moment(this.validSelectedDatesForKpis[1])

        while (startDate.isSameOrBefore(endDate, "month") &&
          startDate.isSameOrBefore(endDate, "year")
        ) {
          monthYears.push(startDate.format("MMM YYYY"))
          startDate.add(1, "month")
        }
        return monthYears
      }
    },
    handleChartDownload(chart) {
      const chartComponent = this.$refs[chart.chartsRef][0]

      chartComponent.dataURI().then(({ imgURI }) => {
        const link    = document.createElement("a")
        link.href     = imgURI
        link.download = `${chart.cardTitle}.png`
        link.click()
      })
    },
    handleQuickLink(quickLinkItem) {
      const { from, to }     = quickLinkItem
      this.showDateRangeMenu = false

      const currentDate  = moment()
      const currentYear  = currentDate.year()
      const currentMonth = currentDate.month() + 1

      const fromDate = moment().set({
        month: from.month - 1,
        date : from.day,
        year : currentYear + (from.yearOffset ?? 0)
      })

      const toDate = moment().set({
        month: to.month - 1,
        date : to.day,
        year : currentYear + (to.yearOffset ?? 0)
      })

      // Check if current date falls within the from-to range or if the current month is past the 'to' month
      const isCurrentDateInRange        = currentDate.isBetween(fromDate, toDate, null, "[]")
      const isCurrentMonthPastFromMonth = currentMonth > from.month

      if (!isCurrentDateInRange && !isCurrentMonthPastFromMonth) {
        fromDate.year(currentYear - 1)
        toDate.year(currentYear - 1)
      }

      const formatForPickerInput     = "YYYY-MM-DD"
      this.selectedDates             = [fromDate.format(formatForPickerInput), toDate.format(formatForPickerInput)]
      this.validSelectedDatesForKpis = this.selectedDates
    },
    handleCreateNewView() {
      this.$emit("addCustomView", this.newCustomView)
    },
    handleCancelAddView() {
      this.newCustomView.name      = null
      this.showCreateNewViewDialog = false
    },
    handleRemoveCustomView() {
      this.$emit("removeCustomView", this.customView.id)
    },
    handleCancelRemoveCustomView() {
      this.showDeleteViewDialog = false
    },
    handleCancelDeleteChart() {
      this.showDeleteChartDialog = false,
      this.chartToDelete         = null
    },
    handleRemoveChart() {
      this.$emit("removeChart", this.chartToDelete.id)
    },
    handleClickOnCustomView(selectedCustomView) {
      if (selectedCustomView.id === 0) {
        if (this.$route.name !== ROUTE_NAME.ANALYTICS_VIEWS) {
          this.$router.push({
            name: ROUTE_NAME.ANALYTICS_VIEWS
          })
        }
      } else {
        if (this.customViewId !== selectedCustomView.id) {
          this.$router.push({
            name  : ROUTE_NAME.ANALYTICS_VIEW,
            params: {
              id: selectedCustomView.id
            }
          })
        }
      }
    },
    handleCloseAddChart() {
      this.showAddChartDialog = false
    },
    handleCreateNewChart(event) {
      this.newChart = event
      this.$emit("addChart", event)
    },
    handleResetChartAddError() {
      this.$emit("resetChartAddError")
    },
    updateCustomViewName() {
      if (this.hasCustomViewNameTextFormFieldChanged()) {
        if (this.localCustomView.name.length <= MAX_CHARACTER_LIMIT.CUSTOM_VIEW_NAME) {
          this.$emit("updateCustomView", {
            id  : this.customView.id,
            name: this.localCustomView.name
          })
        }
      }
    },
    handleViewNameBlur(onBlur) {
      onBlur()
      this.updateCustomViewName()
    },
    handleViewNameInputOnEnter() {
      this.$refs.text_field_custom_view_name.blur()
    },
    hasCustomViewNameTextFormFieldChanged() {
      if (!this.customView?.name
        || !this.localCustomView.name
        || this.isCustomViewUpdateNameDuplicate
      ) {
        return false
      }
      return this.localCustomView.name !== this.customView.name
    },
    createTotalCasesGraphData(chartData, cardTitle, chartId) {
      return {
        vColDataCy             : `6750_${chartId}`,
        vCardDataCy            : `6751_${chartId}`,
        vCardRef               : `chart_card_${chartId}`,
        vCardTitleDataCy       : `6752_${chartId}`,
        vCardTitleRef          : `chart_card_title_${chartId}`,
        vCardActionButtonDataCy: `6753_${chartId}`,
        vCardActionButtonRef   : `chart_action_button_${chartId}`,
        vListItemDownloadDataCy: `6754_${chartId}`,
        vListItemDownloadRef   : `chart_download_list_item_${chartId}`,
        chartsRef              : `chart_${chartId}`,
        chartsDataCy           : `6755_${chartId}`,
        cardTitle              : this.isDefaultView ? this.$t(cardTitle) : cardTitle,
        ...chartData
      }
    },
    async computeChart(chart) {
      return new Promise(resolve => {
        const timeout = setTimeout(() => {
          const computedChart =
            chart.savedFilterId && this.pNotAccessibleSavedFilterIds.includes(chart.savedFilterId)
              ? this.createUnavailableChartData(chart)
              : this.createAvailableChartData(chart)
          resolve(computedChart)
        }, 500)
        this.computeChartTimeouts.push(timeout)
      })
    },
    resetAndLoadCharts() {
      for (const timeout of this.computeChartTimeouts) {
        clearTimeout(timeout)
      }
      this.loadCharts()
    },
    resetAndLoadChartsWithFiltersIds(filterIds) {
      this.loadChartsWithFilterIds(filterIds)
    },
    async loadCharts() {
      this.chartDataList = []
      for (const chart of this.chartsToDisplay) {
        const computedChart = await this.computeChart(chart)
        this.chartDataList.push(computedChart)
      }
    },
    async loadChartsWithFilterIds(filterIds) {
      const accessibleFilterIds = filterIds.filter(filterId =>
        !this.pNotAccessibleSavedFilterIds.includes(filterId)
      )
      const chartsToLoad        = this.chartsToDisplay.filter(chart =>
        accessibleFilterIds.includes(chart.savedFilterId)
      )

      for(let iterator = 0; iterator < this.chartDataList.length; iterator++) {
        const chartData   = this.chartDataList[iterator]
        const chartToLoad = chartsToLoad.find(chart => chart.id === chartData.id)
        if (chartToLoad) {
          const computedChart = await this.computeChart(chartToLoad)
          this.chartDataList.splice(iterator, 1, computedChart)
        }
      }
    },
    getLeafNodes(nodes) {
      const parentChildrenMap = new Map()
      for (const node of nodes) {
        if (node.parentId !== null) {
          if (!parentChildrenMap.has(node.parentId)) {
            parentChildrenMap.set(node.parentId, [])
          }
          parentChildrenMap.get(node.parentId).push(node)
        }
      }

      const leafNodes = nodes.filter(node => {
        return !parentChildrenMap.has(node.id)
      })

      return leafNodes
    },
    isOptionListCascaded(optionListItems) {
      return !!optionListItems.find(optionListItem => optionListItem.parentId)?.parentId
    },
    getMetricTranslation(metric) {
      return metric === METRICS.REPORT_COUNT ? this.$t("2346") : this.$t("2345")
    },
    createUnavailableChartData(chart) {
      return {
        id              : chart.id,
        name            : chart.name,
        vCardDataCy     : `6756_${chart.id}`,
        vCardRef        : `chart_not_available_card_${chart.id}`,
        vCardTitle      : this.$t("2342"),
        vCardTitleDataCy: `6757_${chart.id}`,
        vCardTitleRef   : `chart_not_available_card_title_${chart.id}`,
        vCardText       : this.$t("2343"),
        vCardTextDataCy : `6758_${chart.id}`,
        vCardTextRef    : `chart_not_available_card_text_${chart.id}`,
        vCardImageDataCy: `6759_${chart.id}`,
        vCardImageRef   : `chart_not_available_card_image_${chart.id}`,
        notAvailable    : true
      }
    },
    createAvailableChartData(chart) {
      const {
        metric,
        type: chartType,
        name: cardTitle,
        id: chartId,
        dimension,
        subdivision,
        savedFilterId
      } = chart
      const names = this.generateNamesForDimension(dimension)

      let filteredCases = metric === METRICS.REPORT_COUNT ?
        this.reportsInCurrentDateRange :
        this.casesInCurrentDateRange
      if (savedFilterId) {
        const filter = this.pFilterIdWithIssueIds.find(
          filterIdWithIssueIds => filterIdWithIssueIds.filterId === savedFilterId
        )
        if (filter) {
          filteredCases = filteredCases.filter(issue => filter.issueIds.includes(issue.id))
        }
      }

      if (subdivision) {
        const subdivisionData = this.generateSubdivisionData(dimension, subdivision, names, filteredCases, metric)
        const chartData       = this.createSubdividedChartData(
          chartType,
          names,
          subdivisionData,
          this.getMetricTranslation(metric)
        )
        chartData.id          = chartId
        chartData.name        = chart.name

        return this.createTotalCasesGraphData(chartData, cardTitle, chartId)
      } else {
        const casesCount = names.map(() => 0)
        this.populateCaseData(dimension, names, casesCount, filteredCases)

        const chartData = this.chartBasedOnType(
          chartType,
          names,
          this.getMetricTranslation(metric),
          casesCount
        )
        chartData.id    = chartId
        chartData.name  = chart.name

        return this.createTotalCasesGraphData(chartData, cardTitle, chartId)
      }
    },
    getNamesForField(field) {
      if (field && [FIELD_TYPES.OPTION_LIST.value, FIELD_TYPES.MULTIPLE_OPTION_LIST.value].includes(field.type)) {
        const currentFieldOptionListItems = this.pOptionListItems.filter(optionListItem =>
          optionListItem.optionListId === field.optionListId)

        if (this.isOptionListCascaded(currentFieldOptionListItems)) {
          const leafNodes = this.getLeafNodes(currentFieldOptionListItems)

          return leafNodes.map(item => item.name)
        } else {
          return currentFieldOptionListItems.map(item => item.name)
        }
      } else if (field && field.type === FIELD_TYPES.BOOLEAN.value) {
        return ["true", "false"]
      }
      return []
    },
    generateNamesForDimension(dimension) {
      switch (dimension.type) {
        case CHART_DIMENSION_TYPES.PROPERTY:
          switch (dimension.selected) {
            case ISSUE_PROPERTIES_FOR_CHART.DATE:
              return [...this.monthsForGraph]
            case ISSUE_PROPERTIES_FOR_CHART.ASSIGNEE:
              return this.pUsers?.map(user => user.name)
            case ISSUE_PROPERTIES_FOR_CHART.CHANNELS:
              return this.pChannels?.map(channel => channel.name)
            case ISSUE_PROPERTIES_FOR_CHART.DOMAINS:
              return this.pDomains?.map(domain => domain.name)
            case ISSUE_PROPERTIES_FOR_CHART.REPORT_LANGUAGE:
              return this.pLanguages.map(language => language.name)
            case ISSUE_PROPERTIES_FOR_CHART.SOURCE:
              return this.sources
            case ISSUE_PROPERTIES_FOR_CHART.STATUS:
              return this.pStatuses.map(status => status.name)
          }
          break
        case CHART_DIMENSION_TYPES.FIELD: {
          const field = this.fieldsMap[dimension.selected]
          return this.getNamesForField(field)
        }
        case CHART_DIMENSION_TYPES.FORM_FIELD: {
          const fieldIdOfFormTemplateConfig = this.formTemplateConfigurationsMap[dimension.selected]
          const field                       = this.fieldsMap[fieldIdOfFormTemplateConfig]

          return this.getNamesForField(field)
        }
      }
      return []
    },
    populateCaseData(dimension, names, casesCount, filterIssues) {
      for (const cases of filterIssues) {
        const value = this.getIdForDimension(dimension, cases)

        if (Array.isArray(value)) {
          value.forEach(singleValue => {
            const entity = this.getEntityForDimension(dimension, singleValue)
            if (entity) {
              const index = names.indexOf(entity.name || entity)
              if (index !== -1) {
                casesCount[index]++
              }
            }
          })
        } else if (value !== null) {
          const entity = this.getEntityForDimension(dimension, value)
          if (entity) {
            const index = names.indexOf(entity.name || entity)
            if (index !== -1) {
              casesCount[index]++
            }
          }
        }
      }
    },
    getIdForDimension(dimension, caseIssue) {
      const dimensionMap = {
        [ISSUE_PROPERTIES_FOR_CHART.DATE]             : caseIssue.createdAt,
        [ISSUE_PROPERTIES_FOR_CHART.ASSIGNEE]         : caseIssue.assigneeId ?? "",
        [ISSUE_PROPERTIES_FOR_CHART.CHANNELS]         : caseIssue.report?.channelId,
        [ISSUE_PROPERTIES_FOR_CHART.DOMAINS]          : caseIssue.domainId,
        [ISSUE_PROPERTIES_FOR_CHART.REPORT_LANGUAGE]  : caseIssue.report?.languageId,
        [ISSUE_PROPERTIES_FOR_CHART.SOURCE]           : caseIssue.report?.source,
        [ISSUE_PROPERTIES_FOR_CHART.STATUS]           : caseIssue.statusId,
        [ISSUE_PROPERTIES_FOR_CHART.MANUAL_OR_SPEAKUP]: caseIssue.report?.id ? this.$t("1404") : this.$t("1403")
      }

      if (dimension.type === CHART_DIMENSION_TYPES.FIELD) {
        if (caseIssue.fields && Object.keys(caseIssue.fields).length) {
          for (const [issueFieldId, issueFieldValue] of Object.entries(caseIssue.fields)) {
            const issueField = issueFieldId ? this.issueFieldsMap[issueFieldId] : null
            if (issueField) {
              const field = this.fieldsMap[issueField.fieldId]
              if (field && field.id === dimension.selected) {
                return issueFieldValue
              }
            }
          }
        }
        return null
      }

      if (dimension.type === CHART_DIMENSION_TYPES.FORM_FIELD) {
        const formValue = caseIssue.forms[dimension.selected]
        return formValue
      }

      return dimensionMap[dimension.selected] || null
    },
    getEntityForDimension(dimension, id) {
      const entityMap = {
        [ISSUE_PROPERTIES_FOR_CHART.DATE]             : () => ({ name: moment(id).format("MMM YYYY") }),
        [ISSUE_PROPERTIES_FOR_CHART.ASSIGNEE]         : () => this.usersMap[id],
        [ISSUE_PROPERTIES_FOR_CHART.CHANNELS]         : () => this.channelsMap[id],
        [ISSUE_PROPERTIES_FOR_CHART.DOMAINS]          : () => this.domainsMap[id],
        [ISSUE_PROPERTIES_FOR_CHART.REPORT_LANGUAGE]  : () => this.languagesMap[id],
        [ISSUE_PROPERTIES_FOR_CHART.SOURCE]           : () => id,
        [ISSUE_PROPERTIES_FOR_CHART.STATUS]           : () => this.statusesMap[id],
        [ISSUE_PROPERTIES_FOR_CHART.MANUAL_OR_SPEAKUP]: () => id
      }

      if (dimension.type === CHART_DIMENSION_TYPES.FIELD || dimension.type === CHART_DIMENSION_TYPES.FORM_FIELD) {
        return { name: id }
      }

      return entityMap[dimension.selected]?.() || null
    },
    handleDeleteChart(chart) {
      this.showDeleteChartDialog = true
      this.chartToDelete         = chart
    },
    generateSubdivisionData(dimension, subdivision, names, filteredCases, metric) {
      const subdivisionNames = this.getSubdivisionNames(subdivision)
      const data             = new Map()

      let othersLabel

      if (subdivision.type === CHART_DIMENSION_TYPES.FIELD) {
        othersLabel = this.$t("2177")
      } else {
        const override = SUBDIVISION_LABEL_OVERRIDES[subdivision.selected]
        if (override && override[metric]) {
          othersLabel = this.$t(override[metric])
        } else if (override && override.default) {
          othersLabel = this.$t(override.default)
        } else {
          othersLabel = this.$t("2140")
        }
      }

      if (subdivision.selected === ISSUE_PROPERTIES_FOR_CHART.MANUAL_OR_SPEAKUP) {
        data.set(this.$t("1403"), names.map(() => 0))
        data.set(this.$t("1404"), names.map(() => 0))
      } else {
        for (const subdivisionName of subdivisionNames) {
          data.set(subdivisionName, names.map(() => 0))
        }
        data.set(othersLabel, names.map(() => 0))
      }

      for (const caseIssue of filteredCases) {
        const dimensionValue   = this.getIdForDimension(dimension, caseIssue)
        const subdivisionValue = this.getIdForDimension(subdivision, caseIssue)

        const processMainValue = value => {
          const mainEntity = this.getEntityForDimension(dimension, value)
          return mainEntity ? names.indexOf(mainEntity.name || mainEntity) : -1
        }

        const processSubValue = value => {
          const subEntity = this.getEntityForDimension(subdivision, value)
          if (!subEntity) {
            return "Others"
          }

          const subLabel = subEntity.name || subEntity
          return subdivisionNames.includes(subLabel) ? subLabel : "Others"
        }

        const mainIndices = Array.isArray(dimensionValue)
          ? dimensionValue.map(processMainValue)
          : [processMainValue(dimensionValue)]

        const subLabels = Array.isArray(subdivisionValue)
          ? subdivisionValue.map(processSubValue)
          : [processSubValue(subdivisionValue)]

        mainIndices.forEach(mainIndex => {
          if (mainIndex === -1) {
            return
          }
          subLabels.forEach(subLabel => {
            const key      = subLabel === "Others" ? othersLabel : subLabel
            const subArray = data.get(key) || data.get(othersLabel)
            if (subArray) {
              subArray[mainIndex]++
            }
          })
        })
      }

      return data
    },
    getSubdivisionNames(subdivision) {
      const subdivisionMap = {
        [ISSUE_PROPERTIES_FOR_CHART.ASSIGNEE]         : () => this.pUsers?.map(user => user.name) || [],
        [ISSUE_PROPERTIES_FOR_CHART.CHANNELS]         : () => this.pChannels?.map(channel => channel.name) || [],
        [ISSUE_PROPERTIES_FOR_CHART.DOMAINS]          : () => this.pDomains?.map(domain => domain.name) || [],
        [ISSUE_PROPERTIES_FOR_CHART.REPORT_LANGUAGE]  : () => this.pLanguages?.map(language => language.name) || [],
        [ISSUE_PROPERTIES_FOR_CHART.SOURCE]           : () => this.sources || [],
        [ISSUE_PROPERTIES_FOR_CHART.STATUS]           : () => this.pStatuses?.map(status => status.name) || [],
        [ISSUE_PROPERTIES_FOR_CHART.MANUAL_OR_SPEAKUP]: () => [this.$t("1404"), this.$t("1403")]
      }

      if (subdivision.type === CHART_DIMENSION_TYPES.FIELD) {
        const field = this.fieldsMap[subdivision.selected]
        return this.getNamesForField(field) || []
      }

      if (subdivision.type === CHART_DIMENSION_TYPES.FORM_FIELD) {
        const fieldIdOfFormTemplateConfig = this.formTemplateConfigurationsMap[subdivision.selected]
        const field                       = this.fieldsMap[fieldIdOfFormTemplateConfig]
        return this.getNamesForField(field) || []
      }

      return subdivisionMap[subdivision.selected]?.() || []
    },
    chartBasedOnType(chartType, data, seriesName, issueData) {
      const isHorizontal = chartType === CHART_TYPES.BAR_HORIZONTAL
      const options      = getBarChartOptions(data, isHorizontal ? data.length : undefined, isHorizontal)
      const height       = isHorizontal ? getHorizontalBarChartHeight(data.length) : VERTICAL_CHART_HEIGHT
      const series       = [{
        name: seriesName,
        data: issueData
      }]

      return {
        options,
        height,
        series
      }
    },
    createSubdividedChartData(chartType, names, subdivisionData) {
      const isHorizontal = chartType === CHART_TYPES.BAR_HORIZONTAL
      const options      = isHorizontal ? getBarChartOptions(names, names.length, true) : getBarChartOptions(names)
      const height       = isHorizontal ? getHorizontalBarChartHeight(names.length) : VERTICAL_CHART_HEIGHT
      const series       = Array.from(subdivisionData, ([name, data]) => ({ name, data }))

      return {
        options,
        height,
        series
      }
    }

  },
  watch: {
    pIsCustomViewAdded: {
      handler: function(value) {
        if (value) {
          this.newCustomView.name = null,
          this.showCreateNewViewDialog = false
        }
      }
    },
    pIsCustomViewRemoved: {
      handler: function(value) {
        if (value) {
          this.selectedItem         = 0
          this.showDeleteViewDialog = false
        }
      }
    },
    "newCustomView.name": {
      handler: function() {
        if (this.isCustomViewNameDuplicate) {
          this.$emit("setCustomViewAddError")
        }
      }
    },
    "$route.name": {
      immediate: true,
      handler  : function(name) {
        if (name === ROUTE_NAME.ANALYTICS_VIEW) {
          const indexOfCurrentCustomView = this.pCustomViews.findIndex(eachCustomView =>
            eachCustomView.id === this.customView?.id)
          if (indexOfCurrentCustomView >= 0) {
            this.selectedItem = indexOfCurrentCustomView + 1
          }
        }
      }
    },
    "$route.params.id": {
      immediate: true,
      handler  : function(currentViewId, oldViewId) {
        if (currentViewId !== oldViewId) {
          if (this.isCustomViewUpdateNameDuplicate) {
            this.$emit("resetCustomViewUpdateError")
          }
          this.resetAndLoadCharts()
        }
      }
    },
    "customView.name": {
      immediate: true,
      handler  : function() {
        this.localCustomView.name = this.customView?.name
      }
    },
    pCustomViewAddError: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue?.field === "name" && newValue?.type === "duplicate") {
          this.isCustomViewNameDuplicate = true
        } else {
          this.isCustomViewNameDuplicate = false
        }
      }
    },
    pIsAccessUpdated: {
      handler: function(value) {
        if (value) {
          this.showManageAccessDialog = false
        }
      }
    },
    pIsChartAdded: {
      handler: async function(value) {
        if (value) {
          this.showAddChartDialog = false
          const newlyAddedChart   = this.pCharts.find(chart =>
            chart.customViewId === this.customViewId && chart.name === this.newChart.name)
          if (newlyAddedChart) {
            const computedChart = await this.computeChart(newlyAddedChart)
            this.chartDataList.push(computedChart)
          }
        }
      }
    },
    "localCustomView.name": {
      immediate: true,
      handler  : function() {
        if (this.isCustomViewUpdateNameDuplicate) {
          this.$emit("resetCustomViewUpdateError")
        }
      }
    },
    pCustomViewNameUpdateError: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue?.field === "name" && newValue?.type === "duplicate") {
          this.isCustomViewUpdateNameDuplicate = true
        } else {
          this.isCustomViewUpdateNameDuplicate = false
        }
      }
    },
    pIsChartRemoved: {
      handler: function(value) {
        if (value) {
          this.showDeleteChartDialog = false
          this.chartDataList         = this.chartDataList.filter(data => data.id !== this.chartToDelete.id)
          this.chartToDelete         = null
        }
      }
    },
    pIsRemovingChart: {
      handler: function(newValue) {
        this.$DECISIONS.REMOVE_CHART.pActions[0].buttonProps.disabled = newValue
        this.$DECISIONS.REMOVE_CHART.pActions[1].buttonProps.loading  = newValue
      }
    },
    pIsResetAndLoadAllCharts: {
      handler: function(value) {
        if (value) {
          this.resetAndLoadCharts()
          this.$emit("resetReloadAllCharts")
        }
      }
    },
    pCustomViewChartFiltersToReload: {
      handler: function(value) {
        if (value.filterIds.length
            && value.customViewId === this.customViewId) {
          this.resetAndLoadChartsWithFiltersIds(value.filterIds)
          this.$emit("resetCustomViewChartFiltersToReload")
        }
      }
    },
    validSelectedDatesForKpis: {
      handler: function() {
        this.resetAndLoadCharts()
      }
    }
  }
}