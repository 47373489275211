import ThemisReportMessages from "@/components/report/messages"
import ThemisReportMetadata from "@/components/report/metadata"
import ThemisInput from "@/components/shared/input"
import ThemisDecision from "@/components/shared/decision"
import ThemisReadOnly from "@/components/shared/read-only"
import ThemisFile from "@/components/shared/file"
import ThemisLogs from "@/components/shared/logs"
import ThemisIssueDocuments from "@/components/issue/documents"
import ThemisIssueFormInstance from "@/components/issue/issue-form-instance"
import ThemisReportIntakeForms from "@/components/report/intake-forms"
import ThemisIssueLinks from "@/components/issue/issue-links"
import ThemisTasks from "@/components/issue/tasks"
import ThemisAiFeedback from "@/components/ai-feedback-confirmation"
import { compareDates } from "@/utils"
import { mapActions, mapGetters, mapMutations } from "vuex"
import { getHeadersForTable } from "@/utils/table"
import { convertDaysToDuration } from "@/utils"
import {
  ISSUE_DOCUMENTS_TYPES,
  ISSUE_TYPES,
  DEFAULT_DATA_RETENTION_PERIODS,
  ISSUE_STATUS,
  TABLE_NAMES,
  DATA_RETENTION_STATUS,
  FIELD_TYPES,
  REPORT_CONTENTS_TO_BE_CLEARED,
  FILE_TYPE,
  AUTO_SAVE_INTERVAL,
  MAX_CHARACTER_LIMIT,
  TYPE,
  AI_ASSISTANCE_TYPES,
  AI_ASSISTANCE_STATUS,
  AI_ASSISTANCE_POLL_INTERVAL,
  AI_SUMMARISATION_FEEDBACK_QUESTIONS,
  AI_SUMMARISATION_FEEDBACK_ANSWERS,
  AI_SUMMARISATION_COPIED_TEXT_INTERVAL
} from "@/constants"
import { AI_SUMMARISATION_FEEDBACK_LABELS } from "@/constants/locales"

export default {
  name      : "Issue",
  components: {
    ThemisDecision,
    ThemisInput,
    ThemisFile,
    ThemisReadOnly,
    ThemisLogs,
    ThemisIssueDocuments,
    ThemisReportMetadata,
    ThemisReportIntakeForms,
    ThemisReportMessages,
    ThemisIssueFormInstance,
    ThemisIssueLinks,
    ThemisTasks,
    ThemisAiFeedback
  },
  data: () => ({
    localIssue                           : undefined,
    canViewSummary                       : false,
    canUpdateSummary                     : false,
    canViewDescription                   : false,
    canUpdateDescription                 : false,
    canUpdateDataRetentionPeriod         : false,
    canViewReceivedOn                    : false,
    canViewStatus                        : false,
    canViewIssueHistory                  : false,
    isSendingMessage                     : false,
    isMessageSent                        : false,
    activityTab                          : null,
    isRemoveCommentDialogOpen            : false,
    commentToBeRemoved                   : undefined,
    newLabel                             : undefined,
    expandMessages                       : false,
    canViewAnonymisation                 : false,
    canViewReportStatus                  : false,
    currentExpandedExpansionPanels       : [],
    draftDescriptionAutoSaveInterval     : undefined,
    displayDescriptionSaveAndCancelButton: false,
    showAddIssueFormTemplateDialog       : false,
    selectedIssueFormTemplate            : null,
    showRemoveFormInstanceDialog         : false,
    formInstanceToBeRemoved              : undefined,
    selectedDataRetentionPeriod          : undefined,
    showSetDataRetentionPeriodDialog     : false,
    showClearReportContentDialog         : false,
    canClearReportContent                : false,
    isLocalIssueSetButDifferent          : false,
    issueFormInstanceRemoved             : false,
    canViewReportDetails                 : false,
    canViewMessages                      : false,
    canViewIssueLinks                    : false,
    draftDescriptionLastSavedTime        : undefined,
    localReportContentsToBeCleared       : [],
    addNewTask                           : false,
    documentCount                        : 0,
    canViewFormInstancesOfIssue          : false,
    isDescriptionInputFocused            : false,
    issueDescriptionMaximumCharacterLimit: MAX_CHARACTER_LIMIT.ISSUE_DESCRIPTION_MAX_VALUE,
    isIssueCommentsLoaded                : false,
    showAiSummarisationFeedbackDialog    : false,
    aiSummarisationFeedbackLabels        : AI_SUMMARISATION_FEEDBACK_LABELS,
    draftCommentAutoSaveInterval         : undefined,
    draftCommentLastSavedTime            : undefined,
    isCommentInputFocused                : false,
    isDraftCommentSaved                  : false,
    previousCommentValue                 : "",
    likeOrDislikeAiSummarisation         : false,
    showSkeletonLoaderForSummariseIssue  : false,
    issueSummarisedText                  : undefined,
    showAiAssistanceFailedText           : false,
    aiAssistanceStatusPolling            : null,
    showAiSummarisationCopied            : false,
    comment                              : "",
    draftCommentSaved                    : "", // Computed comment with mentions
    commentForDisplay                    : "", // Display comment with mentions
    isAiCaseSummarisationMenuVisible     : false,
    menu                                 : false,
    filteredUsers                        : [],
    mentions                             : [],
    cursorPosition                       : 0,
    initialData                          : {
      id      : null,
      text    : "",
      mentions: []
    }
  }),
  beforeDestroy() {
    if (this.draftDescriptionAutoSaveInterval) {
      clearInterval(this.draftDescriptionAutoSaveInterval)
    }
    if (this.draftCommentAutoSaveInterval) {
      clearInterval(this.draftCommentAutoSaveInterval)
    }
    if(this.aiAssistanceStatusPolling) {
      clearInterval(this.aiAssistanceStatusPolling)
    }
  },
  computed: {
    ...mapGetters({
      loggedInUser                                          : "auth/loggedInUser",
      accesses                                              : "accesses/accesses",
      languages                                             : "languages/languages",
      usersIncludingDeletedUsers                            : "users/usersIncludingDeletedUsers",
      groups                                                : "groups/groups",
      domains                                               : "domains/domains",
      configurations                                        : "configurations/configurations",
      issues                                                : "issues/issues",
      issueDocuments                                        : "issueDocuments/issueDocuments",
      logsOfIssues                                          : "issues/logsOfIssues",
      isUpdatingSummary                                     : "issues/isUpdatingSummary",
      isUpdatingDescription                                 : "issues/isUpdatingDescription",
      statuses                                              : "issueStatuses/issueStatuses",
      resolutions                                           : "issueResolutions/issueResolutions",
      reports                                               : "reports/reports",
      isClearingReportContent                               : "reports/isClearingReportContent",
      isReportContentCleared                                : "reports/isReportContentCleared",
      isReportStatusUpdated                                 : "reports/isStatusUpdated",
      isUpdatingReportStatus                                : "reports/isUpdatingStatus",
      channels                                              : "channels/channels",
      messages                                              : "messages/messages",
      isMessageAdded                                        : "messages/isMessageAdded",
      isMessageStatusUpdated                                : "messages/isStatusUpdated",
      isUpdatingMessageStatus                               : "messages/isUpdatingStatus",
      isMessageRemoved                                      : "messages/isMessageRemoved",
      isRemovingMessage                                     : "messages/isRemovingMessage",
      messageItems                                          : "messageItems/messageItems",
      isUpdatingMessageItemValue                            : "messageItems/isUpdatingValue",
      translations                                          : "translations/translations",
      isUpdatingTranslationStatus                           : "translations/isUpdatingStatus",
      isUpdatingDataRetainedUntil                           : "issues/isUpdatingDataRetainedUntil",
      isDataRetainedUntilUpdated                            : "issues/isDataRetainedUntilUpdated",
      isTranslationStatusUpdated                            : "translations/isStatusUpdated",
      isAddingTranslation                                   : "translations/isAddingTranslation",
      isTranslationAdded                                    : "translations/isTranslationAdded",
      issueComments                                         : "issueComments/issueComments",
      isAddingComment                                       : "issueComments/isAddingComment",
      isCommentAdded                                        : "issueComments/isCommentAdded",
      isRemovingComment                                     : "issueComments/isRemovingComment",
      isCommentRemoved                                      : "issueComments/isCommentRemoved",
      isUpdatingComment                                     : "issueComments/isUpdatingComment",
      isCommentUpdated                                      : "issueComments/isCommentUpdated",
      isAddingIssueDocument                                 : "issueDocuments/isAddingIssueDocument",
      isIssueDocumentAdded                                  : "issueDocuments/isIssueDocumentAdded",
      isRemovingIssueDocument                               : "issueDocuments/isRemovingIssueDocument",
      isIssueDocumentRemoved                                : "issueDocuments/isIssueDocumentRemoved",
      isUpdatingDocumentDescription                         : "issueDocuments/isUpdatingDocumentDescription",
      isDocumentDescriptionUpdated                          : "issueDocuments/isDocumentDescriptionUpdated",
      issueViewPolicies                                     : "accessControl/issueViewPolicies",
      issueUpdatePolicies                                   : "accessControl/issueUpdatePolicies",
      issueAddDocumentsPolicies                             : "accessControl/issueAddDocumentsPolicies",
      issueDocumentRemovePolicies                           : "accessControl/issueDocumentRemovePolicies",
      issueCommentAddPolicies                               : "accessControl/issueCommentAddPolicies",
      issueCommentRemovePolicies                            : "accessControl/issueCommentRemovePolicies",
      reportViewPolicies                                    : "accessControl/reportViewPolicies",
      reportUpdatePolicies                                  : "accessControl/reportUpdatePolicies",
      reportContentClearPolicies                            : "accessControl/reportContentClearPolicies",
      reportAddMessagesPolicies                             : "accessControl/reportAddMessagesPolicies",
      messageUpdatePolicies                                 : "accessControl/messageUpdatePolicies",
      messageRemovePolicies                                 : "accessControl/messageRemovePolicies",
      translationUpdatePolicies                             : "accessControl/translationUpdatePolicies",
      messageItemAddTranslationsPolicies                    : "accessControl/messageItemAddTranslationsPolicies",
      issueAddFoldersPolicies                               : "accessControl/issueAddFoldersPolicies",
      issueDocumentUpdatePolicies                           : "accessControl/issueDocumentUpdatePolicies",
      folderUpdatePolicies                                  : "accessControl/folderUpdatePolicies",
      folderRemovePolicies                                  : "accessControl/folderRemovePolicies",
      accessViewPolicies                                    : "accessControl/accessViewPolicies",
      formInstances                                         : "formInstances/formInstances",
      issueFormTemplates                                    : "formTemplates/issueFormTemplates",
      fieldsV2                                              : "fields/fieldsV2",
      formTemplateConfigurations                            : "formTemplateConfigurations/formTemplateConfigurations",
      isAddingIssueFormInstance                             : "formInstances/isAddingIssueFormInstance",
      isIssueFormInstanceAdded                              : "formInstances/isIssueFormInstanceAdded",
      issueAddFormInstancesPolicies                         : "accessControl/issueAddFormInstancesPolicies",
      optionListItems                                       : "optionListItems/optionListItems",
      isLoadingIssueDocuments                               : "issueDocuments/isLoadingIssueDocuments",
      isLoadingFormInstances                                : "formInstances/isLoadingFormInstances",
      isLoadingMessages                                     : "messages/isLoadingMessages",
      isLoadingMessageItems                                 : "messageItems/isLoadingMessageItems",
      isFormInstanceRemoved                                 : "formInstances/isFormInstanceRemoved",
      isRemovingFormInstance                                : "formInstances/isRemovingFormInstance",
      isUpdatingFormInstance                                : "formInstances/isUpdatingFormInstance",
      isFormInstanceUpdated                                 : "formInstances/isFormInstanceUpdated",
      formInstanceUpdatePolicies                            : "accessControl/formInstanceUpdatePolicies",
      formInstanceRemovePolicies                            : "accessControl/formInstanceRemovePolicies",
      issuePrintPolicies                                    : "accessControl/issuePrintPolicies",
      issueLinkAddPolicies                                  : "accessControl/issueLinkAddPolicies",
      issueLinkRemovePolicies                               : "accessControl/issueLinkRemovePolicies",
      issueViewLogsPolicies                                 : "accessControl/issueViewLogsPolicies",
      issueLinks                                            : "issueLinks/issueLinks",
      isLoadingIssueLinks                                   : "issueLinks/isLoadingIssueLinks",
      isIssueLinkAdded                                      : "issueLinks/isIssueLinkAdded",
      isAddingIssueLinks                                    : "issueLinks/isAddingIssueLinks",
      isIssueLinkRemoved                                    : "issueLinks/isIssueLinkRemoved",
      isRemovingIssueLink                                   : "issueLinks/isRemovingIssueLink",
      loggedInUserPolicies                                  : "accessControl/loggedInUserPolicies",
      issueAddTaskPolicies                                  : "accessControl/issueAddTaskPolicies",
      dataRetentionPeriods                                  : "dataRetentionPeriods/dataRetentionPeriods",
      isAiAnonymisationEnabled                              : "configurations/isAiAnonymisationEnabled",
      isIssueLinksEnabled                                   : "configurations/isIssueLinksEnabled",
      isTasksEnabled                                        : "configurations/isTasksEnabled",
      isIssueFormsEnabled                                   : "configurations/isIssueFormsEnabled",
      workflows                                             : "workflows/workflows",
      workflowAssociations                                  : "workflowAssociations/workflowAssociations",
      isTransitionDone                                      : "transitions/isTransitionDone",
      isTransitionInProgress                                : "transitions/isTransitionInProgress",
      groupsUsers                                           : "groups/groupsUsers",
      isReporterIntakeFormsEnabled                          : "configurations/isReporterIntakeFormsEnabled",
      isMentionCommentsEnabled                              : "configurations/isMentionCommentsEnabled",
      isReplyTemplatesEnabled                               : "configurations/isReplyTemplatesEnabled",
      users                                                 : "users/users",
      replyTemplates                                        : "replyTemplates/replyTemplates",
      isAddingFolder                                        : "folders/isAddingFolder",
      isFolderAdded                                         : "folders/isFolderAdded",
      folders                                               : "folders/folders",
      allowedIssuesAndDomainsForIssueDocumentViewPolicy     : "accessControl/allowedIssuesAndDomainsForIssueDocumentViewPolicy",
      allowedIssuesAndDomainsForReportViewPolicy            : "accessControl/allowedIssuesAndDomainsForReportViewPolicy",
      allowedIssuesAndDomainsForMessageViewPolicy           : "accessControl/allowedIssuesAndDomainsForMessageViewPolicy",
      allowedIssuesAndDomainsForIssueLinkViewPolicy         : "accessControl/allowedIssuesAndDomainsForIssueLinkViewPolicy",
      allowedIssuesAndDomainsForViewReporterIntakeFormPolicy: "accessControl/allowedIssuesAndDomainsForViewReporterIntakeFormPolicy",
      allowedIssuesAndDomainsForIssueViewCommentsPolicy     : "accessControl/allowedIssuesAndDomainsForIssueViewCommentsPolicy",
      allowedIssuesAndDomainsForFormInstanceViewPolicy      : "accessControl/allowedIssuesAndDomainsForFormInstanceViewPolicy",
      isUpdatingParentId                                    : "folders/isUpdatingParentId",
      isParentIdUpdated                                     : "folders/isParentIdUpdated",
      isUpdatingFolderId                                    : "issueDocuments/isUpdatingFolderId",
      isFolderIdUpdated                                     : "issueDocuments/isFolderIdUpdated",
      isUpdatingFolderIdForItem                             : "messageItems/isUpdatingFolderId",
      isFolderIdUpdatedForItem                              : "messageItems/isFolderIdUpdated",
      messageItemUpdatePolicies                             : "accessControl/messageItemUpdatePolicies",
      isUpdatingFolder                                      : "folders/isUpdatingName",
      isFolderUpdated                                       : "folders/isNameUpdated",
      isRemovingFolder                                      : "folders/isRemovingFolder",
      isFolderRemoved                                       : "folders/isFolderRemoved",
      isUpdatingDraftDescription                            : "issues/isUpdatingDraftDescription",
      isAddingFormInstanceFieldValues                       : "formInstanceFieldValues/isAddingFormInstanceFieldValues",
      isProgressBarInitiated                                : "shared/isProgressBarInitiated",
      isProgressBarPromisesPending                          : "shared/isProgressBarPromisesPending",
      isLoadingIssue                                        : "issues/isLoadingIssue",
      isAiCaseSummarisationEnabled                          : "configurations/isAiCaseSummarisationEnabled",
      isAiSummarisationEnabled                              : "configurations/isAiSummarisationEnabled",
      aiFeedback                                            : "feedback/aiFeedback",
      isAiFeedbackSubmitted                                 : "feedback/isAiFeedbackSubmitted",
      isAiFeedbackUpdated                                   : "feedback/isAiFeedbackUpdated",
      aiAssistanceValue                                     : "aiAssistances/aiAssistanceValue",
      issueFormTemplatesForIssueTypes                       : "issueFormTemplates/issueFormTemplates",
      isLoadingUsers                                        : "users/isLoadingUsers",
      isUsersLoaded                                         : "users/isUsersLoaded"
    }),
    isDraftDescriptionPresent() {
      return !!this.localIssue.draftDescription
    },
    getDescriptionDetailText() {
      if (this.isUpdatingDraftDescription) {
        return this.$t("2072")
      }
      if (this.localIssue.draftDescription) {
        if (!this.isDescriptionInputFocused) {
          return this.$t("2074")
        }
        return this.$t("2075", { time: this.$moment(this.draftDescriptionLastSavedTime).format("D MMMM YYYY HH:mm (UTCZ)") })
      }
    },
    usersWithAllLevelAccess() {
      let usersWithAllLevelAccess = []
      for (const access of this.accesses) {
        if (this.issue.parentId) {
          if (access.criteria?.id === this.parentIssue.id && access.userId) {
            usersWithAllLevelAccess.push(access.userId)
          } else if (access.criteria?.id === this.parentIssue.id && access.groupId) {
            if (this.groupsUsers[access.groupId]) {
              usersWithAllLevelAccess = [...usersWithAllLevelAccess,
                ... this.groupsUsers[access.groupId]]
            }
          }
        }
        if (access.criteria?.id === this.issue?.id && access.userId) {
          usersWithAllLevelAccess.push(access.userId)
        } else if (access.criteria?.id === this.issue?.id && access.groupId) {
          if (this.groupsUsers[access.groupId]) {
            usersWithAllLevelAccess = [...usersWithAllLevelAccess,
              ... this.groupsUsers[access.groupId]]
          }
        }
      }
      usersWithAllLevelAccess = [...usersWithAllLevelAccess, ... this.usersWithDomainLevelAccess]
      return [...new Set(usersWithAllLevelAccess)]
    },
    getDescriptionValue() {
      return this.isDescriptionInputFocused && this.localIssue.draftDescription && this.canUpdateDescription
        ? this.localIssue.draftDescription
        : this.issue.description
    },
    usersWithDomainLevelAccess() {
      let usersWithDomainLevelAccess = []
      for (const access of this.accesses) {
        const issue = this.issue.parentId ? this.parentIssue : this.issue
        if (access.criteria?.domainId === issue.domainId && access.userId) {
          usersWithDomainLevelAccess.push(access.userId)
        } else if (access.criteria?.domainId === issue.domainId && access.groupId) {
          if (this.groupsUsers[access.groupId]) {
            usersWithDomainLevelAccess = [...usersWithDomainLevelAccess,
              ... this.groupsUsers[access.groupId]]
          }
        }
      }
      return [...new Set(usersWithDomainLevelAccess)]
    },
    parentIssue() {
      return this.issues.find(issue => issue.id === this.issue?.parentId)
    },
    isLargeAndUpScreen() {
      return this.$vuetify.breakpoint.lgAndUp
    },
    logsOfIssue() {
      let logsOfIssue = this.logsOfIssues[+this.$route.params.id]
      if (logsOfIssue) {
        logsOfIssue = logsOfIssue.sort((item1, item2) => {
          return compareDates(item2.createdAt, item1.createdAt)
        })
      }
      return logsOfIssue
    },
    getDescriptionLength() {
      return this.getDescriptionValue?.length || 0
    },
    getCommentDetailText() {
      if (this.isUpdatingComment) {
        return this.$t("2072")
      }
      if (this.isDraftComment) {
        if (!this.isCommentInputFocused) {
          return this.$t("2074")
        }
        return this.$t("2075", { time: this.$moment(this.draftCommentLastSavedTime).format("D MMMM YYYY HH:mm (UTCZ)") })
      }
    },
    isDraftComment() {
      const draftComment = this.issueComments.find(comment => comment.creatorId === this.loggedInUser.id
        && comment.issueId === +this.$route.params.id && comment.draft === true)
      return !!draftComment?.draft
    },
    commentsOfIssue() {
      const commentsOfIssue = this.issueComments.filter(comment =>
        comment.issueId === +this.$route.params.id && comment.draft === false)
      if (commentsOfIssue?.length && Object.keys(this.usersMap).length) {
        return commentsOfIssue.sort((firstComment, secondComment) => compareDates(
          firstComment.createdAt, secondComment.createdAt))
          .map(comment => {
            let startingPosition = 0
            const commentValues  = comment.mentionComments.sort(
              (firstMention, secondMention) => firstMention.position - secondMention.position)
              .map(mention => {
                const end   = mention.position
                const value = comment.value.substring(startingPosition, end)

                const user       = this.usersMap[mention.userId]
                const userName   = user?.enabled ? user?.name
                  : user?.deletedAt
                    ? `${user?.name}`
                    : `${user?.name} ${this.$t("1131")}`
                startingPosition = end
                return { value, userName }
              })
            const remainingText = comment.value.substring(startingPosition)
            commentValues.push({ value: remainingText })

            return { ...comment, commentValues }
          })
      }
    },
    domainsMap() {
      const domainsMap = new Object()
      for (const domain of this.domains) {
        domainsMap[domain.id] = domain
      }
      return domainsMap
    },
    languagesMap() {
      const languagesMap = new Object()
      for (const language of this.languages) {
        languagesMap[language.id] = language
      }
      return languagesMap
    },
    resolutionsMap() {
      const resolutionsMap = new Object()
      for (const resolution of this.resolutions) {
        resolutionsMap[resolution.id] = resolution
      }
      return resolutionsMap
    },
    statusesMap() {
      const statusesMap = new Object()
      for (const status of this.statuses) {
        statusesMap[status.id] = status
      }
      return statusesMap
    },
    usersMap() {
      const usersMap = new Object()
      for (const user of this.usersIncludingDeletedUsers) {
        usersMap[user.id] = user
      }
      return usersMap
    },
    groupsMap() {
      const groupsMap = new Object()
      for (const group of this.groups) {
        groupsMap[group.id] = group
      }
      return groupsMap
    },
    translationsMap() {
      const translationsMap = new Object()
      for (const translation of this.translations) {
        translationsMap[translation.messageItemId] = translation
      }
      return translationsMap
    },
    issue() {
      return this.issues?.find(issue => issue.id === +this.$route.params.id)
    },
    tasks() {
      const filteredIssues = this.issues.filter(issue => issue.parentId === this.issue.id)

      return filteredIssues.sort((first, second) => {
        return compareDates(first.createdAt, second.createdAt)
      })
    },
    latestTask() {
      return this.tasks[this.tasks.length - 1]
    },
    reportId() {
      return this.reports?.find(report => report.issueId === this.issue?.id)?.id
    },
    report() {
      let report = this.reports?.find(report => report.issueId === this.issue?.id)
      if (report) {
        const messages = this.messages?.filter(message =>
          message.reportId === report.id
        )
        report         = Object.assign({}, report, {
          messages
        })
        if (report?.messages?.length && this.messageItems) {
          const indexOfMessages = new Object()
          for (const [index, message] of report.messages.entries()) {
            indexOfMessages[message.id] = index
          }
          for (let messageItem of this.messageItems) {
            const indexOfMessage = indexOfMessages[messageItem.messageId]
            if (indexOfMessage !== undefined) {
              messageItem = Object.assign({}, messageItem, {
                language: this.languagesMap[messageItem.languageId]
              })
              if (report.messages[indexOfMessage].items) {
                report.messages[indexOfMessage].items.push(messageItem)
              } else {
                report.messages[indexOfMessage] = Object.assign({}, report.messages[indexOfMessage], {
                  items: [messageItem]
                })
              }
            }
            if (messageItem.version === "original" && this.translationsMap[messageItem.id]) {
              report.messages[indexOfMessage] = Object.assign({}, report.messages[indexOfMessage], {
                translation: this.translationsMap[messageItem.id]
              })
            }
          }
        }
      }
      return report
    },
    newMessagesFromReporter() {
      const newMessagesFromReporter = new Array()
      for (const message of this.report?.messages) {
        if (message.userId === null && message.status === this.$CONSTANTS.MESSAGE_STATUS.NEW) {
          newMessagesFromReporter.push(message)
        }
      }
      return newMessagesFromReporter
    },
    isReportOpen() {
      return this.report?.status === "new"
    },
    isReportAnonymised() {
      return this.report?.anonymisation === "done"
    },
    isReportAnonymisationScheduled() {
      return this.report.anonymisation === "scheduled"
    },
    reportFormInstances() {
      const filteredFormInstances = this.formInstances.filter(formInstance => formInstance.reportId === this.report?.id)

      for (const reportFormInstance of filteredFormInstances) {
        reportFormInstance.formInstanceFields.sort(
          (firstFormInstanceField, secondFormInstanceField) =>
            firstFormInstanceField.sortingOrder - secondFormInstanceField.sortingOrder
        )
      }
      return filteredFormInstances
    },
    issueFormInstances() {
      const filteredFormInstances                 = this.formInstances.
        filter(formInstance => formInstance.issueId === this.issue?.id)
      const issueFormTemplatesForIssueTypeForCase = this.issueFormTemplatesForIssueTypes.filter(issueFormTemplate =>
        issueFormTemplate.typeId === ISSUE_TYPES[0].id)

      const formTemplateSortingOrderMap = issueFormTemplatesForIssueTypeForCase.
        reduce((sortingOrderMap, issueFormTemplate) => {
          sortingOrderMap[issueFormTemplate.formTemplateId] = issueFormTemplate.sortingOrder
          return sortingOrderMap
        }, {})

      return filteredFormInstances.sort((firstFormInstance, secondFormInstance) => {
        const sortingOrderOfFirstFormInstance  = formTemplateSortingOrderMap[firstFormInstance.formTemplateId]
        const sortingOrderOfSecondFormInstance = formTemplateSortingOrderMap[secondFormInstance.formTemplateId]
        return sortingOrderOfFirstFormInstance - sortingOrderOfSecondFormInstance
      })
    },
    documentsOfIssue() {
      return this.issueDocuments?.filter(issueDocument => issueDocument.issueId === +this.$route.params.id)
    },
    documentsOfReport() {
      const documentsOfReport = new Array()
      if (this.report?.messages) {
        for (const message of this.report.messages) {
          if (!message.userId && message.items) {
            for (const item of message.items) {
              if (!["text", "audio"].includes(item.type)) {
                documentsOfReport.push(item)
              }
            }
          }
        }
      }
      return documentsOfReport
    },
    foldersOfIssue() {
      return this.folders.filter(folder => folder.issueId === +this.$route.params.id)
    },
    documentsCount() {
      return this.documentsOfIssue.concat(this.documentsOfReport).length
    },
    channel() {
      const channel = this.channels?.find(channel => channel.id === this.report.channelId)
      if (channel) {
        if (channel.overrideTranslationPreference) {
          channel.effectiveTranslationPreference = channel.translationPreferenceId
        } else if (this.globalTranslationPreference) {
          channel.effectiveTranslationPreference = +this.globalTranslationPreference.value
        }
      }
      return channel
    },
    isSummaryChanged() {
      return this.issue?.summary !== this.localIssue?.summary
    },
    isDraftDescriptionChanged() {
      if (this.localIssue.draftDescription === "" && this.issue?.draftDescription === null) {
        this.draftDescriptionAutoSaveInterval = undefined
        return false
      }
      return this.issue?.draftDescription !== this.localIssue?.draftDescription
    },
    globalTranslationPreference() {
      return this.configurations.find(configuration => configuration.key === "TRANSLATION")
    },
    currentIssueDocumentRemovePolicies() {
      return this.issueDocumentRemovePolicies.filter(issueDocumentRemovePolicy =>
        this.issueDocuments.find(issueDocument =>
          issueDocument.id === issueDocumentRemovePolicy.id
        )
      )
    },
    currentIssueAddDocumentsPolicies() {
      return this.issueAddDocumentsPolicies.find(issueAddDocumentsPolicy =>
        issueAddDocumentsPolicy.id === +this.$route.params.id
      )
    },
    canViewIssueDocuments() {
      if (!this.isACase) {
        const parentIssueId = this.issue.parentId
        const parentIssue   = this.issues.find(issue => issue.id === parentIssueId)
        return this.allowedIssuesAndDomainsForIssueDocumentViewPolicy.ids.includes(parentIssueId) ||
            this.allowedIssuesAndDomainsForIssueDocumentViewPolicy.domainIds.includes(parentIssue?.domainId)
      } else {
        return this.allowedIssuesAndDomainsForIssueDocumentViewPolicy.ids.includes(this.issue.id) ||
            this.allowedIssuesAndDomainsForIssueDocumentViewPolicy.domainIds.includes(this.issue.domainId)
      }
    },
    canViewIssueComments() {
      if (!this.isACase) {
        const parentIssueId = this.issue.parentId
        const parentIssue   = this.issues.find(issue => issue.id === parentIssueId)
        return this.allowedIssuesAndDomainsForIssueViewCommentsPolicy.ids.includes(parentIssueId) ||
            this.allowedIssuesAndDomainsForIssueViewCommentsPolicy.domainIds.includes(parentIssue?.domainId)
      } else {
        return this.allowedIssuesAndDomainsForIssueViewCommentsPolicy.ids.includes(this.issue.id) ||
            this.allowedIssuesAndDomainsForIssueViewCommentsPolicy.domainIds.includes(this.issue.domainId)
      }
    },
    currentIssueAddFoldersPolicies() {
      return this.issueAddFoldersPolicies.find(issueAddFolderPolicy =>
        issueAddFolderPolicy.id === +this.$route.params.id
      )
    },
    currentIssueDocumentUpdatePolicies() {
      return this.issueDocumentUpdatePolicies.filter(issueDocumentUpdatePolicy =>
        this.documentsOfIssue.find(issueDocument =>
          issueDocument.id === issueDocumentUpdatePolicy.id
        )
      )
    },
    currentMessageItemUpdatePolicies() {
      return this.messageItemUpdatePolicies.filter(messageItemUpdatePolicy =>
        this.documentsOfReport.find(reportDocument =>
          reportDocument.id === messageItemUpdatePolicy.id
        )
      )
    },
    currentFolderUpdatePolicies() {
      return this.folderUpdatePolicies.filter(folderUpdatePolicy =>
        this.folders.find(folder =>
          folder.id === folderUpdatePolicy.id
        )
      )
    },
    currentFolderRemovePolicies() {
      return this.folderRemovePolicies.filter(folderRemovePolicy =>
        this.folders.find(folder =>
          folder.id === folderRemovePolicy.id
        )
      )
    },
    currentReportViewPolicy() {
      return this.reportViewPolicies.find((reportViewPolicy =>
        reportViewPolicy.id === this.report?.id
      ))
    },
    canViewReport() {
      return !!this.currentReportViewPolicy?.select?.length
    },
    currentReportUpdatePolicy() {
      return this.reportUpdatePolicies.find((reportUpdatePolicy =>
        reportUpdatePolicy.id === this.report?.id
      ))
    },
    currentReportContentClearPolicies() {
      return this.reportContentClearPolicies.find(reportClearContentPolicy =>
        reportClearContentPolicy.id === this.report?.id
      )
    },
    currentReportAddMessagePolicy() {
      return this.reportAddMessagesPolicies.find(reportAddMessagesPolicy =>
        reportAddMessagesPolicy.id === this.report?.id
      )
    },
    currentReportMessageUpdatePolicies() {
      const currentReportMessageUpdatePolicies = []
      if (this.messages) {
        for (const message of this.messages) {
          const messageUpdatePolicy = this.messageUpdatePolicies.find(policy => policy.id === message.id)
          if (messageUpdatePolicy) {
            currentReportMessageUpdatePolicies.push(messageUpdatePolicy)
          }
        }
      }
      return currentReportMessageUpdatePolicies
    },
    currentReportMessageRemovePolicies() {
      const currentReportMessageRemovePolicies = []
      if (this.messages) {
        for (const message of this.messages) {
          const messageRemovePolicy = this.messageRemovePolicies.find(policy => policy.id === message.id)
          if (messageRemovePolicy) {
            currentReportMessageRemovePolicies.push(messageRemovePolicy)
          }
        }
      }
      return currentReportMessageRemovePolicies
    },
    accessIdsWithAllLevelForCurrentIssue() {
      let accessIdsWithAllLevelForCurrentIssue = []
      for (const access of this.accesses) {
        if (access.criteria?.id === this.issue.id && access.userId) {
          accessIdsWithAllLevelForCurrentIssue.push(access.id)
        } else if (access.criteria?.id === this.issue.id && access.groupId) {
          accessIdsWithAllLevelForCurrentIssue.push(access.id)
        }
      }
      accessIdsWithAllLevelForCurrentIssue = [
        ...accessIdsWithAllLevelForCurrentIssue,
        ...this.accessIdsWithDomainLevelForCurrentIssue
      ]
      return accessIdsWithAllLevelForCurrentIssue
    },
    accessIdsWithDomainLevelForCurrentIssue() {
      const accessIdsWithDomainLevelForCurrentIssue = []
      for (const access of this.accesses) {
        if (access.criteria?.domainId === this.issue.domainId && access.userId) {
          accessIdsWithDomainLevelForCurrentIssue.push(access.id)
        } else if (access.criteria?.domainId === this.issue.domainId && access.groupId) {
          accessIdsWithDomainLevelForCurrentIssue.push(access.id)
        }
      }
      return accessIdsWithDomainLevelForCurrentIssue
    },
    canViewAccess() {
      return this.accessViewPolicies.some(accessViewPolicy =>
        this.accessIdsWithAllLevelForCurrentIssue.includes(accessViewPolicy.id)
      )
    },
    canAddComment() {
      const currentIssueCommentAddPolicy = this.issueCommentAddPolicies.find(
        issueAddCommentPolicy => issueAddCommentPolicy.id === +this.$route.params.id
      )
      return currentIssueCommentAddPolicy?.set?.add !== undefined
    },
    canAddDocuments() {
      return this.currentIssueAddDocumentsPolicies?.set?.add !== undefined
    },
    canAddDataRetentionPeriod() {
      return this.localIssue.closedAt !== null && this.localIssue.dataRetentionStatus === null
        && this.canUpdateDataRetentionPeriod
    },
    currentIssuePrintPolicies() {
      return this.issuePrintPolicies.find(issuePrintPolicy =>
        issuePrintPolicy.id === +this.$route.params.id
      )
    },
    canPrintIssue() {
      return this.currentIssuePrintPolicies?.set?.print !== undefined
    },
    isIssueClosedAndNotScheduled() {
      return this.issue?.statusId === ISSUE_STATUS.CLOSED &&
        this.issue?.dataRetentionStatus === null
    },
    isDataRetentionAnonymised() {
      return this.issue?.dataRetentionStatus === DATA_RETENTION_STATUS.ANONYMISED
    },
    reportContentsToBeCleared() {
      const reportContentsToBeCleared = []
      for (const content of REPORT_CONTENTS_TO_BE_CLEARED) {
        reportContentsToBeCleared.push({
          ...content,
          label: this.$t(content.label),
          field: false
        })
      }
      for (const formInstance of this.reportFormInstances) {
        const shortTextFormInstanceFields = formInstance.formInstanceFields
          .filter(field => field.type === FIELD_TYPES.SHORT_TEXT.value)
        for (const field of shortTextFormInstanceFields) {
          reportContentsToBeCleared.push({
            value  : field.fieldId,
            label  : field.systemName,
            checked: false,
            field  : true
          })
        }
      }
      return reportContentsToBeCleared
    },
    isReportContentToBeCleared() {
      return !this.localReportContentsToBeCleared.some(item => item.checked)
    },
    showReportPanels() {
      return this.canViewReportDetails && !!this.canViewReport && !!this.report && !this.isLocalIssueSetButDifferent
    },
    showDocumentsPanel() {
      return this.canViewIssueDocuments ||
      (this.canViewMessages && this.documentsOfReport.length) || this.canAddDocuments
    },
    showMessagesPanels() {
      return this.canViewMessages && !!this.report
    },
    showReportIntakeFormPanels() {
      return this.canViewFormInstancesOfIssue &&
        !!this.report &&
        !!this.loggedInUserPolicies["FormInstance view"]?.some(policy => policy?.wheres?.some(where => !!where.report))
    },
    showIssueFormPanels() {
      return this.canViewFormInstancesOfIssue &&
        !!this.issueFormInstances?.length &&
        !!this.loggedInUserPolicies["FormInstance view"]?.some(policy => policy?.wheres?.some(where => !!where.issue))
    },
    allowedIssueDocumentsTypes() {
      return Object.values(ISSUE_DOCUMENTS_TYPES)
    },
    showFormInstancesPanels() {
      const numberOfFormInstances = this.reportFormInstances.length + this.issueFormInstances.length
      return !!numberOfFormInstances &&  !this.isLocalIssueSetButDifferent
    },
    anchorLinks() {
      const links = []
      if (this.showReportPanels) {
        links.push({
          id  : "report_overview_panel",
          name: this.$t("1201"),
          hash: "report_overview_panel"
        })
      }
      if (this.canViewFormInstancesOfIssue && this.reportFormInstances.length && this.isReporterIntakeFormsEnabled) {
        for (const formInstance of this.reportFormInstances) {
          links.push({
            id  : formInstance.id,
            name: this.$t("800"),
            hash: `panel_report_intake_forms_${formInstance.id}`
          })
        }
      }
      const messages = this.messages?.filter(message => message.reportId === this.reportId)
      if (messages.length) {
        links.push({
          id  : "panel_messages",
          name: this.$t("1213", { count: messages.length }),
          hash: "panel_messages"
        })
      }
      if (this.showDocumentsPanel) {
        links.push({ name: this.$t("297", { count: this.documentsCount }), hash: "documents_panel" })
      }
      if (this.issueFormInstances.length && this.isIssueFormsEnabled) {
        for (const formInstance of this.issueFormInstances) {
          links.push({
            id  : formInstance.id,
            name: formInstance.formTemplateName,
            hash: `panel_issue_form_${formInstance.id}`
          })
        }
      }
      if (this.canViewIssueLinks && this.isIssueLinksEnabled) {
        links.push({ id: "issue_links", name: this.$t("1165", { count: this.linkedIssuesForTheIssue.length }), hash: "issue_links" })
      }
      if (this.isTasksEnabled) {
        links.push({ id: "tasks_panel", name: this.$t("1264", { count: this.tasks.length }), hash: "tasks_panel" })
      }
      if (this.canViewIssueComments || this.canViewIssueHistory) {
        links.push({ id: "activity", name: this.$t("593"), hash: "activity" })
      }
      return links
    },
    scrollToHash() {
      const isHashHasTemp = this.$route.hash.startsWith("#temp")
      if (this.isDataLoadingComplete) {
        return this.$route.hash
      }
      if (isHashHasTemp) {
        return this.$route.hash
      }
    },
    issueFormTemplatesAddTableHeaders() {
      return getHeadersForTable(TABLE_NAMES.ISSUE_FORM_TEMPLATES, this.$t.bind(this))
    },
    fieldsMap() {
      const fieldsMap = new Map()
      for (const field of this.fieldsV2) {
        fieldsMap.set(field.id, field)
      }
      return fieldsMap
    },
    issueFormTemplatesNameMap() {
      const issueFormTemplatesNameMap = new Map()
      for (const issueFormTemplate of this.issueFormTemplates) {
        issueFormTemplatesNameMap.set(issueFormTemplate.id, issueFormTemplate.name)
      }
      return issueFormTemplatesNameMap
    },
    issueFormTemplateFieldsMap() {
      const issueFormTemplateFieldsMap = new Map()
      for (const configuration of this.formTemplateConfigurations) {
        let fields = [this.fieldsMap.get(configuration.fieldId)]
        if (issueFormTemplateFieldsMap.has(configuration.formTemplateId)) {
          fields = [...fields, ...issueFormTemplateFieldsMap.get(configuration.formTemplateId)]
        }
        issueFormTemplateFieldsMap.set(configuration.formTemplateId, fields)
      }
      return issueFormTemplateFieldsMap
    },
    singleInstanceIssueFormTemplatesAddedToIssue() {
      const addedFormTemplateIds = new Set(this.issueFormInstances.map(
        issueFormInstance => issueFormInstance.formTemplateId
      ))
      return this.issueFormTemplatesForIssueTypes.filter(issueFormTemplate =>
        addedFormTemplateIds.has(issueFormTemplate.formTemplateId) && !issueFormTemplate.multiInstance
      ).map(issueFormTemplate => issueFormTemplate.id)
    },
    issueFormTemplatesAddTableItems() {
      const filteredIssueFormTemplates = this.issueFormTemplatesForIssueTypes
        .sort((firstIssueFormTemplate, secondIssueFormTemplate) =>
          firstIssueFormTemplate.sortingOrder - secondIssueFormTemplate.sortingOrder)
        .filter(issueFormTemplate =>
          !this.singleInstanceIssueFormTemplatesAddedToIssue.includes(issueFormTemplate.id)
        )
      return filteredIssueFormTemplates.map(issueFormTemplate => {
        const fieldNames = this.getIssueFormTemplateFieldNames(issueFormTemplate.formTemplateId)
        return {
          id    : issueFormTemplate.formTemplateId,
          name  : this.issueFormTemplatesNameMap.get(issueFormTemplate.formTemplateId),
          fields: fieldNames?.length ? fieldNames.join(", ") : this.$t("741")
        }
      })
    },
    issueFormTemplatesAddTableHeight() {
      const dataRowHeight      = 48
      const maxAvailableHeight = window.innerHeight - 430
      const maxDataRows        = Math.floor((maxAvailableHeight / dataRowHeight) - 1)
      let heightOfTable        = dataRowHeight // initialize with header height

      if (this.issueFormTemplatesAddTableItems.length > maxDataRows) {
        heightOfTable += maxDataRows * dataRowHeight
      } else if (!this.issueFormTemplatesAddTableItems.length) {
        heightOfTable += dataRowHeight // one row for "no data available"
      } else {
        heightOfTable += this.issueFormTemplatesAddTableItems.length * dataRowHeight
      }

      return heightOfTable
    },
    currentIssueAddFormInstancesPolicies() {
      return this.issueAddFormInstancesPolicies.find(issueAddFormInstancesPolicy =>
        issueAddFormInstancesPolicy.id === +this.$route.params.id
      )
    },
    canAddIssueFormTemplate() {
      return this.currentIssueAddFormInstancesPolicies?.set?.add !== undefined
    },
    isDataLoadingComplete() {
      return !this.isLoadingFormInstances && !this.isLoadingMessages &&
        !this.isLoadingIssueDocuments && !this.isLoadingMessageItems &&
        !this.isLoadingIssueLinks
    },
    displayMoreActionMenu() {
      return this.isACase && (this.canAddDataRetentionPeriod || this.canAddDocuments ||
        this.canPrintIssue || this.canClearReportContent)
    },
    issueViewPolicy() {
      return this.issueViewPolicies.find(issueViewPolicy => issueViewPolicy.id === +this.$route.params.id)
    },
    issueUpdatePolicy() {
      return this.issueUpdatePolicies.find(issueUpdatePolicy => issueUpdatePolicy.id === +this.$route.params.id)
    },
    issueViewLogsPolicy() {
      return this.issueViewLogsPolicies.find(issueViewLogsPolicy => issueViewLogsPolicy.id === +this.$route.params.id)
    },
    linkedIssuesForTheIssue() {
      return this.issueLinks.filter(issueLink =>
        issueLink.fromId === this.issue?.id || issueLink.toId === this.issue?.id
      )
    },
    isACase() {
      return this.issue.typeId === ISSUE_TYPES[0].id
    },
    canAddTask() {
      return this.issueAddTaskPolicies.find(issueAddTaskPolicy =>
        issueAddTaskPolicy.id === +this.$route.params.id)?.set?.add !== undefined
    },
    retentionPeriods() {
      if (this.dataRetentionPeriods.length) {
        return [...this.dataRetentionPeriods].sort((firstDataRetentionPeriod, secondRetentionPeriod) =>
          firstDataRetentionPeriod.value - secondRetentionPeriod.value)
          .map(dataRetentionPeriod => dataRetentionPeriod.value)
      }
      return DEFAULT_DATA_RETENTION_PERIODS
    },
    retentionPeriodsToDisplay() {
      const dataRetentionPeriods = []
      for (const retentionPeriod of this.retentionPeriods) {
        const dataRetentionPeriod = convertDaysToDuration(retentionPeriod)
        dataRetentionPeriods.push({
          days    : retentionPeriod,
          duration: this.$tc(dataRetentionPeriod.localeValue, dataRetentionPeriod.count,
            { count: dataRetentionPeriod.count })
        })
      }
      return dataRetentionPeriods
    },
    usersWithAccess() {
      return this.users.filter(user => this.usersWithAllLevelAccess.includes(user.id)).filter(user => user.enabled)
    },
    sortedReplyTemplates() {
      return this.replyTemplates.sort((first, second) => first.name.localeCompare(second.name))
    },
    showSkeletonLoaderForIssueAccesses() {
      return !(this.canViewAccess || this.isAllPromisesResolved)
    },
    showSkeletonLoaderForIssueActions() {
      return !(this.displayMoreActionMenu || this.isAllPromisesResolved)
    },
    showSkeletonLoaderForReportOverview() {
      return !(this.showReportPanels || this.isAllPromisesResolved)
    },
    showSkeletonLoaderForReporterIntakeForms() {
      return !(this.showReportIntakeFormPanels || this.isAllPromisesResolved)
    },
    showSkeletonLoaderForIssueForms() {
      return !((this.showIssueFormPanels && this.issueFormInstances?.length) || this.isAllPromisesResolved)
    },
    showSkeletonLoaderForDocuments() {
      if (this.isAllPromisesResolved) {
        return false
      }
      return true
    },
    showSkeletonLoaderForMessages() {
      return !(this.showMessagesPanels || this.isAllPromisesResolved)
    },
    showSkeletonLoaderForIssueLinks() {
      return !((this.canViewIssueLinks  && this.linkedIssuesForTheIssue?.length) || this.isAllPromisesResolved)
    },
    showSkeletonLoaderForTasks() {
      if (this.isAllPromisesResolved) return false
      if (this.isLoadingIssues || !this.tasks?.length) return true
      return false
    },
    showSkeletonLoaderForActivity() {
      return !(this.isAllPromisesResolved ||
         (this.canViewIssueComments && this.canViewIssueHistory && this.isIssueCommentsLoaded))
    },
    isAllPromisesResolved() {
      return !(this.isProgressBarInitiated && this.isProgressBarPromisesPending)
    },
    showAiCaseSummarisation() {
      return this.showReportIntakeFormPanels &&
        this.canViewMessages &&
        this.canViewDescription &&
        this.isACase &&
        this.isAiCaseSummarisationEnabled &&
        this.isAiSummarisationEnabled
    },
    aiSummarisationFeedbackProperties() {
      return {
        aiFeedbackSubtitle     : "2143",
        aiFeedbackLabelTitle   : "2144",
        aiFeedbackTextAreaLabel: "2145",
        aiFeedbackLabel        : AI_SUMMARISATION_FEEDBACK_LABELS,
        aiFeedBackQuestions    : AI_SUMMARISATION_FEEDBACK_QUESTIONS,
        aiFeedbackAnswers      : AI_SUMMARISATION_FEEDBACK_ANSWERS
      }
    },
    aiAssistanceOfCurrentIssue() {
      if (this.aiAssistanceValue?.issueId === this.issue?.id) {
        return this.aiAssistanceValue
      }
      return null
    },
    aiAssistanceStatus() {
      return this.aiAssistanceOfCurrentIssue?.status
    },
    aiAssistanceResult() {
      return this.aiAssistanceOfCurrentIssue?.result
    },
    initialComment() {
      let text   = this.initialData.text
      let offset = 0

      this.initialData.mentions?.forEach(mention => {
        const user = this.usersWithAccess.find(user => {
          return user.id === mention.userId
        })
        if (user) {
          const position = mention.position + offset
          text           = text.slice(0, position) + "@" + user.name + text.slice(position)
          offset        += user.name.length + 1 // Update offset to account for the added length
        }
      })
      return text
    }
  },
  methods: {
    ...mapActions({
      updateIssue                : "issues/updateIssue",
      updateReport               : "reports/updateReport",
      updateMessage              : "messages/updateMessage",
      addMessage                 : "messages/addMessage",
      addMessageItem             : "messageItems/addMessageItem",
      updateMessageItem          : "messageItems/updateMessageItem",
      loadMessageItems           : "messageItems/loadMessageItems",
      removeMessage              : "messages/removeMessage",
      notify                     : "shared/notify",
      downloadReporterFile       : "messageItems/downloadFile",
      downloadUserFile           : "issueDocuments/downloadFile",
      addIssueDocument           : "issueDocuments/addIssueDocument",
      removeIssueDocument        : "issueDocuments/removeIssueDocument",
      updateIssueDocument        : "issueDocuments/updateIssueDocument",
      addTranslation             : "translations/addTranslation",
      loadTranslations           : "translations/loadTranslations",
      updateTranslation          : "translations/updateTranslation",
      addComment                 : "issueComments/addComment",
      updateComment              : "issueComments/updateComment",
      removeComment              : "issueComments/removeComment",
      addIssueFormInstance       : "formInstances/addIssueFormInstance",
      removeFormInstance         : "formInstances/removeFormInstance",
      updateFormInstance         : "formInstances/updateFormInstance",
      addIssueLinks              : "issueLinks/addIssueLinks",
      removeIssueLink            : "issueLinks/removeIssueLink",
      makeTransition             : "transitions/makeTransition",
      clearReportContents        : "reports/clearReportContents",
      addFolder                  : "folders/addFolder",
      updateFolder               : "folders/updateFolder",
      removeFolder               : "folders/removeFolder",
      saveFormInstanceFieldValues: "formInstanceFieldValues/saveFormInstanceFieldValues",
      addAiFeedback              : "feedback/addAiFeedback",
      updateAiFeedback           : "feedback/updateAiFeedback",
      addAiAssistance            : "aiAssistances/addAiAssistance",
      loadAiAssistanceValue      : "aiAssistances/loadAiAssistanceValue"
    }),
    ...mapMutations({
      resetAiAssistanceValue: "aiAssistances/resetAiAssistanceValue"
    }),
    handleClickOnIssueSummarise() {
      this.isAiCaseSummarisationMenuVisible = true
      this.resetAiAssistanceValue()
      this.showSkeletonLoaderForSummariseIssue = true
      this.addAiAssistance({
        issueId: this.issue.id,
        type   : AI_ASSISTANCE_TYPES.ISSUE_SUMMARISATION
      })
    },
    clearAiAssistancePollingInterval() {
      if (this.aiAssistanceStatusPolling) {
        clearInterval(this.aiAssistanceStatusPolling)
        this.aiAssistanceStatusPolling = null
      }
    },
    handleLikeAiSummarisation() {
      this.addAiFeedback({
        type    : TYPE.AI_SUMMARISATION,
        feedback: [{
          "question": AI_SUMMARISATION_FEEDBACK_QUESTIONS.FIRST,
          "answer"  : AI_SUMMARISATION_FEEDBACK_ANSWERS.YES
        }]
      }),
      this.likeOrDislikeAiSummarisation = true
    },
    handleDislikeAiSummarisation() {
      this.addAiFeedback({
        type    : TYPE.AI_SUMMARISATION,
        feedback: [{
          "question": AI_SUMMARISATION_FEEDBACK_QUESTIONS.FIRST,
          "answer"  : AI_SUMMARISATION_FEEDBACK_ANSWERS.NO
        }]
      })
      this.showAiSummarisationFeedbackDialog = true
    },
    handleSubmitAiSummarisationFeedback(aiFeedback) {
      this.updateAiFeedback(aiFeedback)
      this.likeOrDislikeAiSummarisation      = true
      this.showAiSummarisationFeedbackDialog = false
    },
    updateDocumentCount(numberOfDocuments) {
      this.documentCount = numberOfDocuments
    },
    handleReportContentToBeCleared() {
      const contentToBeCleared = {
        fields  : [],
        reportId: this.report.id
      }
      for (const content of this.localReportContentsToBeCleared) {
        if (content.field) {
          contentToBeCleared.fields.push({
            id   : content.value,
            value: content.checked
          })
        } else {
          contentToBeCleared[content.value] = content.checked
        }
      }

      this.clearReportContents(contentToBeCleared)
    },
    handleCancelReportContent() {
      this.showClearReportContentDialog = false
      this.resetLocalReportContentsToBeCleared()
    },
    resetLocalReportContentsToBeCleared() {
      this.localReportContentsToBeCleared = this.localReportContentsToBeCleared.map(content => {
        content.checked = false
        return content
      })
    },
    handleDescriptionInput(text) {
      this.localIssue.draftDescription = text
      this.saveDescriptionOnInterval()
    },
    handleDescriptionClearEvent() {
      this.localIssue.draftDescription = ""
      this.updateIssue({
        id              : this.localIssue.id,
        description     : "",
        draftDescription: ""
      })
      this.handleDescriptionOnBlurEvent
      this.displayDescriptionSaveAndCancelButton = false
    },
    saveDescriptionOnInterval() {
      if (!this.draftDescriptionAutoSaveInterval && this.isDescriptionInputFocused) {
        this.draftDescriptionAutoSaveInterval = setInterval(() => {
          this.draftDescriptionLastSavedTime = new Date()
          if (this.isDraftDescriptionChanged &&
            this.localIssue.draftDescription?.length < this.issueDescriptionMaximumCharacterLimit) {
            this.updateIssue({
              id              : this.localIssue.id,
              draftDescription: this.localIssue.draftDescription ?? ""
            })
          }
        }, AUTO_SAVE_INTERVAL)
      }
    },
    handleCommentInputOnFocusEvent(onFocus) {
      onFocus()
      this.isCommentInputFocused = true

      if (!this.comment || !this.comment.length) {
        this.comment = this.draftCommentSaved
      }

      if (!this.isDraftComment) {
        this.addComment({
          issueId        : this.localIssue.id,
          value          : "",
          mentionComments: []
        })
      }

      this.draftCommentAutoSaveInterval = setInterval(() => {
        if (this.isDraftComment) {
          this.updateCommentValues()
        }
      }, AUTO_SAVE_INTERVAL)
    },
    async handleCommentInputOnBlurEvent($event, onBlur) {
      onBlur()
      clearInterval(this.draftCommentAutoSaveInterval)

      if ($event.relatedTarget?.dataset?.cy === "472") {
        return
      }
      if ($event.relatedTarget?.dataset?.cy === "474") {
        return
      }
      if (!this.initialData.id) {
        return
      }

      this.updateCommentValues()
      if (!this.menu) {
        this.comment = ""
      }

      this.isCommentInputFocused = false
    },
    handleUpdateComment() {
      this.updateCommentValues(true)
      this.resetNewComment()
    },
    handleDiscardComment() {
      if (this.initialData.id) {
        this.removeComment({
          id   : this.initialData.id,
          draft: true
        })
      }
      this.resetNewComment()
    },
    handleCommentClearEvent() {
      this.comment = ""
    },
    resetNewComment() {
      clearInterval(this.draftCommentAutoSaveInterval)
      this.mentions              = []
      this.draftCommentSaved     = ""
      this.comment               = ""
      this.isCommentInputFocused = false
      this.isDraftCommentSaved   = false
      this.initialData.id        = null
    },
    updateSummary() {
      if (this.isSummaryChanged) {
        if (!this.localIssue.summary) {
          this.localIssue.summary = ""
        }
        this.updateIssue({
          id     : this.localIssue.id,
          summary: this.localIssue.summary
        })
      }
    },
    handleSummaryInputOnBlurEvent(onBlur) {
      onBlur()
      this.updateSummary()
    },
    handleSummaryInputOnEnter() {
      this.$refs.input_summary.blur()
    },
    handleDescriptionOnFocusEvent(onFocus) {
      onFocus()
      this.isDescriptionInputFocused             = true
      this.displayDescriptionSaveAndCancelButton = true
    },
    async handleDescriptionOnBlurEvent($event, onBlur) {
      onBlur()
      clearInterval(this.draftDescriptionAutoSaveInterval)
      if ($event.relatedTarget?.dataset?.cy === "1781") {
        return
      }
      if ($event.relatedTarget?.dataset?.cy === "1782") {
        return
      }
      this.isDescriptionInputFocused             = false
      this.displayDescriptionSaveAndCancelButton = false
    },
    handleNewMessage(language) {
      this.addMessage({
        reportId: this.report.id,
        item    : {
          type      : "text",
          value     : "",
          languageId: language.id
        }
      })
    },
    handleDiscardMessage(message) {
      this.removeMessage(message.id)
    },
    sendMessage(message, messageItem) {
      this.isSendingMessage = true
      this.updateMessage({
        id    : message.id,
        status: "new",
        item  : messageItem
      })
    },
    startCommentRemovalProcess(comment) {
      this.isRemoveCommentDialogOpen = true
      this.commentToBeRemoved        = comment
    },
    handleAddIssueDocument({ file, folderId }) {
      this.addIssueDocument({
        issueId: this.localIssue.id,
        file,
        folderId
      })
    },
    canRemoveComment(commentId) {
      const issueCommentRemovePolicy = this.issueCommentRemovePolicies.find(issueCommentRemovePolicy =>
        issueCommentRemovePolicy.id === commentId
      )
      return issueCommentRemovePolicy?.set?.remove !== undefined
    },
    handleIssueDocumentRemoved(documentRemoved) {
      this.notify({
        type      : "success",
        text      : "662",
        parameters: {
          name: documentRemoved.name
        }
      })
    },
    toggleExpandCollapseMessages() {
      if (!this.expandMessages && this.newMessagesFromReporter?.length) {
        for (const message of this.newMessagesFromReporter) {
          const messageUpdatePolicy = this.currentReportMessageUpdatePolicies.find(
            messageUpdatePolicy => messageUpdatePolicy.id === message.id)
          if (messageUpdatePolicy?.set?.status) {
            if (messageUpdatePolicy.set.status.includes("seen")) {
              this.updateMessage({
                id    : message.id,
                status: "seen"
              })
            }
          }
        }
      }
      this.expandMessages = !this.expandMessages
    },
    handleCancelSaveDescription() {
      this.localIssue.description                = this.issue.description
      this.displayDescriptionSaveAndCancelButton = false
      if (this.isDraftDescriptionPresent) {
        this.updateIssue({
          id              : this.localIssue.id,
          draftDescription: ""
        })
      }
      this.localIssue.draftDescription = null
    },
    handleSaveDescription() {
      if (this.draftDescriptionAutoSaveInterval) {
        clearInterval(this.draftDescriptionAutoSaveInterval)
        this.draftDescriptionAutoSaveInterval = undefined
      }

      if (!this.localIssue.draftDescription && this.issue.description === null) {
        this.localIssue.draftDescription = ""
      }
      if (this.localIssue.draftDescription === null
        && this.issue.description === this.localIssue.description) {
        this.localIssue.draftDescription = this.issue.description
      }
      this.updateIssue({
        id              : this.localIssue.id,
        description     : this.localIssue.draftDescription,
        draftDescription: ""
      })
      this.localIssue.description      = this.localIssue.draftDescription
      this.localIssue.draftDescription = null
    },
    handleAddDocuments() {
      const expansionPanels                   = this.$refs.expansion_panels
      const indexOfAddDocumentsExpansionPanel = expansionPanels.items.findIndex(expansionPanel =>
        expansionPanel.$attrs["data-panel-name"] === "add_documents"
      )
      this.currentExpandedExpansionPanels.push(indexOfAddDocumentsExpansionPanel)

      const addDocumentsExpansionPanel = this.$refs.documents_panel
      this.$vuetify.goTo(addDocumentsExpansionPanel, {
        duration: 500,
        offset  : 0,
        easing  : "easeInOutCubic"
      })
      this.$refs.issue_documents.openFileExplorerToSelectDocuments()
    },
    getIssueFormTemplateFieldNames(formTemplateId) {
      return this.issueFormTemplateFieldsMap
        ?.get(formTemplateId)
        ?.map(field => field?.systemName)
    },
    selectIssueFormTemplate(item) {
      this.selectedIssueFormTemplate = item
    },
    async issueFormTemplatesAddHandler() {
      const payload = {
        issueId       : this.issue?.id,
        formTemplateId: this.selectedIssueFormTemplate.id
      }
      this.addIssueFormInstance(payload)
    },
    getClassForFieldRow(item) {
      let classForFieldRow = ""
      if (item.id === this.selectedIssueFormTemplate?.id) {
        classForFieldRow += "blue lighten-4"
      }
      return classForFieldRow
    },
    handleAnchorLink(link) {
      this.$router.push({ name: "issue", hash: "#temp_" + link.hash })
    },
    handleRemoveFormInstanceDialog(formInstanceToBeRemoved) {
      this.formInstanceToBeRemoved      = formInstanceToBeRemoved
      this.showRemoveFormInstanceDialog = true
    },
    handleRemoveFormInstance() {
      this.removeFormInstance(this.formInstanceToBeRemoved.id)
    },
    handleIssuePrint() {
      this.$router.push({ name: "issue-print", params: { id: this.issue?.id } })
    },
    handleAddIssuesLinks(issuesToLink) {
      this.addIssueLinks(issuesToLink)
    },
    handleAddTask(task) {
      this.addNewTask = true
      this.makeTransition({
        id  : task.transitionId,
        data: {
          ...task.data,
          parentId: +this.$route.params.id
        }
      })
    },
    handleIssueLinkRemoved(linkedIssue) {
      this.notify({
        type      : "success",
        text      : "1253",
        parameters: {
          id     : linkedIssue.issueId,
          summary: linkedIssue.text
        }
      })
    },
    handleSetDataRetentionPeriod() {
      this.updateIssue({
        id               : this.localIssue.id,
        dataRetainedUntil: this.$moment().add(this.selectedDataRetentionPeriod, "days").format("YYYY-MM-DD")
      })
    },
    handleCancelSetDataRetentionPeriod() {
      this.selectedDataRetentionPeriod      = undefined
      this.showSetDataRetentionPeriodDialog = false
    },
    handleAddFolder(folder) {
      this.addFolder({
        issueId: this.localIssue.id,
        ...folder
      })
    },
    handleFolderUpdated() {
      this.notify({
        type: "success",
        text: "1972"
      })
    },
    handleFolderRemoved(folderToBeRemoved) {
      this.notify({
        type      : "success",
        text      : "2026",
        parameters: {
          folderName: folderToBeRemoved.name
        }
      })
    },
    handleMovedToFolder(item) {
      this.notify({
        type      : "success",
        text      : "2023",
        parameters: {
          type: item.isFolder ? FILE_TYPE.FOLDER : FILE_TYPE.DOCUMENT,
          name: item.name
        }
      })
    },
    async handleCopyAiSummarisation() {
      if (this.issueSummarisedText) {
        await navigator.clipboard.writeText(this.issueSummarisedText)
        this.showAiSummarisationCopied = true
        setTimeout(() => {
          this.showAiSummarisationCopied = false
        }, AI_SUMMARISATION_COPIED_TEXT_INTERVAL)
      }
    },
    checkForAtSymbol(event) {
      const textarea = event.target

      if (!textarea || typeof textarea.selectionStart === "undefined") {
        return
      }

      this.cursorPosition = textarea.selectionStart

      const textBeforeCursor = this.comment.slice(0, this.cursorPosition)

      const lastAtSymbolIndex = textBeforeCursor.lastIndexOf("@")
      const isValidMention    = lastAtSymbolIndex !== -1 && this.cursorPosition > lastAtSymbolIndex

      if (isValidMention && this.cursorPosition > lastAtSymbolIndex) {
        this.menu          = true
        const searchTerm   = textBeforeCursor.slice(lastAtSymbolIndex + 1)
        this.filteredUsers = this.usersWithAccess.filter(user =>
          user.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      } else {
        this.menu = false
      }
    },
    selectUser(user) {
      const textBeforeCursor  = this.comment.slice(0, this.cursorPosition)
      const lastAtSymbolIndex = textBeforeCursor.lastIndexOf("@")

      this.comment =
        textBeforeCursor.slice(0, lastAtSymbolIndex + 1) +
        user.name +
        this.comment.slice(this.cursorPosition)

      this.mentions.push({
        userId  : user.id,
        position: lastAtSymbolIndex
      })

      this.menu = false
      this.$refs.input_new_comment_with_mention.focus()
    },
    saveComment() {
      const textWithoutMentions = this.comment.replace(/@\w+/g, "")
      this.updateComment({
        id             : this.initialData.id,
        value          : textWithoutMentions,
        mentionComments: this.mentions
      })
    },
    updateCommentValues(submitComment = false) {
      let commentText = this.comment ?? ""

      if (this.isMentionCommentsEnabled) {
        const usersArray = Array.isArray(this.usersMap) ? this.usersMap : Object.values(this.usersMap)

        const atPositions = []
        let pos           = -1

        while ((pos = commentText.indexOf("@", pos + 1)) !== -1) {
          if (pos === 0 || /\s/.test(commentText[pos - 1])) {
            atPositions.push(pos)
          }
        }

        const validMentions  = []
        let processedComment = commentText
        let offset           = 0

        for (const startPos of atPositions) {
          let endPos       = startPos + 1
          let longestMatch = null

          while (endPos <= commentText.length) {
            const currentText = commentText.slice(startPos + 1, endPos).trim()

            const matchingUser = usersArray.find(user =>
              user.name.toLowerCase() === currentText.toLowerCase()
            )

            if (matchingUser) {
              longestMatch = {
                user : matchingUser,
                start: startPos,
                end  : endPos,
                text : currentText
              }
            }

            endPos++
          }

          if (longestMatch) {
            const adjustedPosition = longestMatch.start - offset

            validMentions.push({
              userId  : longestMatch.user.id,
              position: adjustedPosition
            })

            processedComment =
              processedComment.slice(0, adjustedPosition) +
              processedComment.slice(adjustedPosition + longestMatch.text.length + 1)

            offset += longestMatch.text.length + 1 // +1 for @
          }
        }

        validMentions.sort((a, b) => a.position - b.position)

        this.mentions = validMentions
        commentText   = processedComment
      }

      const commentToBeSaved = {
        id             : this.initialData.id,
        value          : commentText,
        mentionComments: this.mentions
      }

      if (submitComment) {
        commentToBeSaved.draft = false
      }

      this.updateComment(commentToBeSaved)
    }
  },
  watch: {
    reportContentsToBeCleared: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue.length) {
          this.localReportContentsToBeCleared = [...newValue]
        }
      }
    },
    isReportContentCleared: {
      handler: function(newValue) {
        if (newValue) {
          this.showClearReportContentDialog = false
          this.notify({
            type: "success",
            text: "1578"
          })
          this.resetLocalReportContentsToBeCleared()
        }
      }
    },
    currentReportContentClearPolicies: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && newValue.set) {
          this.canClearReportContent = this.isDataRetentionAnonymised && !!newValue.set?.fields
          && !!newValue.set?.messageContent && !!newValue.set?.reportDocument
        }
      }
    },
    currentReportViewPolicy: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && newValue.select) {
          this.canViewAnonymisation = newValue.select.includes("anonymisation")
          this.canViewReportStatus  = newValue.select.includes("status")
        }
      }
    },
    issue: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          const isLocalIssueNotSet         = !this.localIssue
          this.isLocalIssueSetButDifferent = !isLocalIssueNotSet && (
            this.localIssue.id !== newValue.id
            || this.localIssue.summary !== newValue.summary
            || this.localIssue.description !== newValue.description
          )
          if(this.isLocalIssueSetButDifferent){
            this.currentExpandedExpansionPanels = []
          }
          if (isLocalIssueNotSet || this.isLocalIssueSetButDifferent) {
            this.localIssue = { ...newValue }
          }
          this.documentCount = this.documentsOfIssue.concat(this.documentsOfReport).length
        }
      }
    },
    issueViewPolicy: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && newValue.select) {
          this.canViewSummary     = newValue.select.includes("summary")
          this.canViewDescription = newValue.select.includes("description")
          this.canViewStatus      = newValue.select.includes("statusId")
        }
      }
    },
    issueUpdatePolicy: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && newValue.set) {
          this.canUpdateSummary             = newValue.set.summary !== undefined
          this.canUpdateDescription         = newValue.set.description !== undefined
          this.canUpdateDataRetentionPeriod = newValue.set.dataRetainedUntil !== undefined
        } else {
          this.canUpdateSummary             = undefined
          this.canUpdateDescription         = undefined
          this.canUpdateDataRetentionPeriod = undefined
        }
      }
    },
    issueViewLogsPolicy: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && newValue.select) {
          this.canViewIssueHistory = newValue.select.includes("id")
        }
      }
    },
    isUpdatingMessageStatus: {
      handler: function(newValue) {
        if (this.isSendingMessage) {
          if (!newValue) {
            this.isSendingMessage = newValue
            this.isMessageSent    = this.isMessageStatusUpdated
            this.loadTranslations({
              issueId: this.issue?.id
            })
            this.loadMessageItems({
              messageId: (this.report.messages.map(message => message.id)).toString()
            })
          }
        }
      }
    },
    isReportStatusUpdated: {
      handler: function(newValue) {
        if (newValue && this.report?.status === "closed") {
          this.notify({
            type      : "success",
            text      : "437",
            parameters: {
              id: this.localIssue.id
            }
          })
        }
      }
    },
    isMessageRemoved: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "accent",
            text: "439"
          })
        }
      }
    },
    issueComments: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {

          const draftComment = this.issueComments.find(comment => comment.creatorId === this.loggedInUser.id
            && comment.issueId === +this.$route.params.id && comment.draft === true)

          if (draftComment) {
            this.initialData.text     = draftComment.value
            this.initialData.id       = draftComment.id
            this.initialData.mentions = draftComment.mentionComments

            if(this.mentions && this.mentions.length && this.usersWithAccess && this.usersWithAccess.length) {
              this.draftCommentSaved = this.initialComment
            } else if (this.mentions && !this.mentions.length) {
              this.draftCommentSaved = this.initialComment
            }
          }
        }
      }
    },
    users: {
      handler: function(newValue) {
        if (newValue) {
          if (!this.comment.length) {
            this.draftCommentSaved = this.initialComment
          }
        }
      }
    },
    isCommentRemoved: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "602"
          })
          this.isRemoveCommentDialogOpen = false
          this.commentToBeRemoved        = undefined
        }
      }
    },
    isRemovingComment: {
      handler: function(newValue) {
        this.$DECISIONS.REMOVE_COMMENT.pActions[0].buttonProps.disabled = newValue
        this.$DECISIONS.REMOVE_COMMENT.pActions[1].buttonProps.loading  = newValue
      }
    },
    isDataRetainedUntilUpdated: {
      handler: function(newValue) {
        if (newValue) {
          this.selectedDataRetentionPeriod      = undefined
          this.showSetDataRetentionPeriodDialog = false
        }
      }
    },
    isUpdatingDataRetainedUntil: {
      handler: function(newValue) {
        this.$DECISIONS.SET_DATA_RETENTION_PERIOD.pActions[1].buttonProps.loading  = newValue
        this.$DECISIONS.SET_DATA_RETENTION_PERIOD.pActions[0].buttonProps.disabled = newValue
      }
    },
    selectedDataRetentionPeriod: {
      immediate: true,
      handler  : function(newValue) {
        this.$DECISIONS.SET_DATA_RETENTION_PERIOD.pActions[1].buttonProps.disabled = !newValue
      }
    },
    isUpdatingDescription: {
      handler: function(newValue) {
        if (!newValue) {
          this.displayDescriptionSaveAndCancelButton = false
        }
      }
    },
    showReportPanels: {
      immediate: true,
      handler  : async function(newValue) {
        if (newValue) {
          await this.$nextTick()
          const expansionPanels = this.$refs.expansion_panels
          if (expansionPanels) {
            const indexOfReportOverviewExpansionPanel = expansionPanels.items.findIndex(expansionPanel =>
              expansionPanel.$attrs["data-panel-name"] === "report_overview"
            )
            this.currentExpandedExpansionPanels.push(indexOfReportOverviewExpansionPanel)
          }
        }
      }
    },
    showFormInstancesPanels: {
      immediate: true,
      handler  : async function(newValue) {
        if (newValue) {
          await this.$nextTick()
          const expansionPanels = this.$refs.expansion_panels
          if (expansionPanels) {
            for (const [indexOfExpansionPanel, expansionPanel] of expansionPanels.items.entries()) {
              if (expansionPanel.$attrs["data-panel-name"] === "report_intake_forms" &&
                !this.currentExpandedExpansionPanels.includes(indexOfExpansionPanel)
              ) {
                this.currentExpandedExpansionPanels.push(indexOfExpansionPanel)
              }
            }
          }
        }
      }
    },
    scrollToHash: {
      immediate: true,
      handler  : async function(newValue) {
        await this.$nextTick()
        await this.$nextTick()
        const isHashHasTemp = newValue?.startsWith("#temp")
        if (newValue) {
          if (isHashHasTemp) {
            this.$router.push({
              name: "issue", hash: "#" + newValue.slice(6)
            })
          } else {
            let ref
            const slicedValue = newValue.slice(1)
            if (newValue.startsWith("#panel_issue_form") || newValue.startsWith("#panel_report_intake_form")) {
              ref = this.$refs[slicedValue]?.[0]
            } else {
              ref = this.$refs[slicedValue]
            }
            if (ref) {
              this.$vuetify.goTo(ref, {
                duration: 500,
                offset  : 0,
                easing  : "easeInOutCubic"
              })
            }
          }
        }
      }
    },
    showAddIssueFormTemplateDialog: {
      handler: function(value) {
        if (!value) {
          this.issueFormTemplateToBeAdded = []
          this.selectedIssueFormTemplate  = null
        }
      }
    },
    isIssueFormInstanceAdded: {
      handler: function(value) {
        if (value) {
          this.showAddIssueFormTemplateDialog = false
          const expansionPanels               = this.$refs.expansion_panels

          const entriesArray = Array.from(expansionPanels.items.entries())
          const latestEntry  = entriesArray[entriesArray.length - 1]
          const [index]      = latestEntry
          this.currentExpandedExpansionPanels.push(index + 1)
        }
      }
    },
    isFormInstanceRemoved: {
      handler: function(value) {
        if (value) {
          this.notify({
            type      : "success",
            text      : "1041",
            parameters: {
              name: this.formInstanceToBeRemoved.formTemplateName
            }
          })
          this.showRemoveFormInstanceDialog = false
          this.formInstanceToBeRemoved      = undefined
          this.issueFormInstanceRemoved     = true
        }
      }
    },
    isRemovingFormInstance: {
      handler: function(newValue) {
        this.$DECISIONS.REMOVE_FORM_INSTANCE.pActions[0].buttonProps.disabled = newValue
        this.$DECISIONS.REMOVE_FORM_INSTANCE.pActions[1].buttonProps.loading  = newValue
      }
    },
    isTransitionDone: {
      handler: function(newValue) {
        if (newValue && this.latestTask && this.addNewTask) {
          this.addNewTask = false
          this.$router.push({
            name  : "task",
            params: {
              issueId: this.latestTask.parentId,
              id     : this.latestTask.id
            }
          })
        }
      }
    },
    currentExpandedExpansionPanels: {
      handler: function(newValue, oldValue) {
        if(this.issueFormInstanceRemoved) {
          const indexOfIssueFormInstances = []
          const arrayDifference           = oldValue.filter(element => !newValue.includes(element))
          const differenceElement         = arrayDifference[0]
          const expansionPanels           = this.$refs.expansion_panels
          for (const [indexOfExpansionPanel, expansionPanel] of expansionPanels.items.entries()) {
            if (expansionPanel.$attrs["data-panel-name"] === "issue_form") {
              indexOfIssueFormInstances.push(indexOfExpansionPanel)
            }
          }
          if(indexOfIssueFormInstances.includes(differenceElement)){
            this.currentExpandedExpansionPanels.sort((firstIndex, secondIndex) => firstIndex - secondIndex)
            this.currentExpandedExpansionPanels.forEach((element, index) => {
              if (element > differenceElement) {
                this.currentExpandedExpansionPanels[index] -= 1
              }
            })
          }
          this.issueFormInstanceRemoved = false
        }
      }
    },
    allowedIssuesAndDomainsForReportViewPolicy: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          if (newValue.domainIds.includes(this.issue.domainId) || newValue.ids.includes(this.issue.id)) {
            this.canViewReportDetails = true
          }
        }
      }
    },
    allowedIssuesAndDomainsForMessageViewPolicy: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          if (newValue.domainIds.includes(this.issue.domainId) || newValue.ids.includes(this.issue.id)) {
            this.canViewMessages = true
          }
        }
      }
    },
    allowedIssuesAndDomainsForIssueLinkViewPolicy: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          if (newValue.domainIds.includes(this.issue.domainId) || newValue.ids.includes(this.issue.id)) {
            this.canViewIssueLinks = true
          }
        }
      }
    },
    allowedIssuesAndDomainsForFormInstanceViewPolicy: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          if (newValue.domainIds.includes(this.issue.domainId) || newValue.ids.includes(this.issue.id)) {
            this.canViewFormInstancesOfIssue = true
          }
        }
      }
    },
    commentsOfIssues: {
      handler: function(newValue) {
        if (newValue) {
          this.isIssueCommentsLoaded = true
        }
      }
    },
    isAiFeedbackSubmitted: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && this.likeOrDislikeAiSummarisation) {
          this.notify({
            type: "success",
            text: "2125"
          })
          this.likeOrDislikeAiSummarisation = false
        }
      }
    },
    aiAssistanceStatus: {
      immediate: true,
      handler  : function(newValue) {
        this.clearAiAssistancePollingInterval()
        if (newValue && this.aiAssistanceOfCurrentIssue.type === AI_ASSISTANCE_TYPES.ISSUE_SUMMARISATION) {
          if (newValue === AI_ASSISTANCE_STATUS.DONE || newValue === AI_ASSISTANCE_STATUS.FAILED) {
            this.showSkeletonLoaderForSummariseIssue = false
            if (newValue === AI_ASSISTANCE_STATUS.FAILED) {
              this.showAiAssistanceFailedText = true
            }
          } else if (newValue === AI_ASSISTANCE_STATUS.INITIATED) {
            this.aiAssistanceStatusPolling = setInterval(() => {
              this.loadAiAssistanceValue({
                id    : this.aiAssistanceOfCurrentIssue?.id,
                status: newValue
              })
            }, AI_ASSISTANCE_POLL_INTERVAL)
          }
        }
      }
    },
    aiAssistanceResult: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.issueSummarisedText = newValue.summary
        }
      }
    },
    isAiFeedbackUpdated: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && this.likeOrDislikeAiSummarisation) {
          this.notify({
            type: "success",
            text: "2125"
          })
          this.likeOrDislikeAiSummarisation = false
        }
      }
    },
    isAiCaseSummarisationMenuVisible: {
      immediate: true,
      handler  : function(newValue) {
        if (!newValue ) {
          this.showSkeletonLoaderForSummariseIssue = false
          this.clearAiAssistancePollingInterval()
        }
      }
    }
  }
}