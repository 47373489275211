import { compareArray } from "@/utils"
import ThemisDecision from "@/components/shared/decision"
import ThemisInput from "@/components/shared/input"
import { SHARE_ACCESS } from "../../constants"

export default {
  name : "ShareAccess",
  props: {
    pShareAccessTitle       : String,
    pShareAccessLabel       : String,
    pShareAccessName        : String,
    pShareAccessItems       : Array,
    pShareAccessPlaceHolder : String,
    pShareAccessToGroupLabel: String,
    pShareAccessToGroupName : String,
    pShareAccessToGroupItems: Array,
    pIsSharingAccess        : Boolean,
    pCurrentShareAccess     : Object,
    pConfirmationDialogTitle: String,
    pConfirmationDialogText : String,
    pIsAccessShared         : Boolean
  },
  data: () => ({
    filterAccess                     : null,
    filterGroups                     : [],
    showShareAccessDialog            : false,
    showShareAccessConfirmationDialog: false
  }),
  components: {
    ThemisDecision,
    ThemisInput
  },
  computed: {
    disableShareSubmitButton() {
      return !this.filterAccess ||
        this.isUpdatingAccess ||
        (this.filterAccess === SHARE_ACCESS.GROUPS && !this.filterGroups.length) ||
        (this.filterAccess === this.pCurrentShareAccess.access && this.filterAccess !== SHARE_ACCESS.GROUPS) ||
        (this.filterAccess === SHARE_ACCESS.GROUPS
          && compareArray(this.filterGroups, this.pCurrentShareAccess.groups.map(group => group.id)))
    },
    isSelectedAccessGroups() {
      return this.filterAccess === SHARE_ACCESS.GROUPS
    },
    isAccessNarrowing() {
      return (this.filterAccess === SHARE_ACCESS.PRIVATE && this.pCurrentShareAccess.access !== SHARE_ACCESS.PRIVATE) ||
      (this.pCurrentShareAccess.access === SHARE_ACCESS.EVERYONE && this.filterAccess !== SHARE_ACCESS.EVERYONE)
    }
  },
  methods: {
    handleSubmitShareFilter() {
      if (this.isAccessNarrowing) {
        this.showShareAccessConfirmationDialog = true
        this.showShareAccessDialog             = false
      } else {
        this.$emit("shareAccess", {
          id    : this.pCurrentShareAccess.id,
          access: this.filterAccess,
          groups: this.filterGroups
        })
      }
    },
    handleContinueDecisionDialogShareFilter() {
      this.$emit("shareAccess", {
        id    : this.pCurrentShareAccess.id,
        access: this.filterAccess,
        groups: this.filterGroups
      })
    },
    handleCloseShareAccessDialog() {
      this.showShareAccessDialog             = false
      this.showShareAccessConfirmationDialog = false
      this.$emit("closeShareAccessDialog")
    }
  },
  watch: {
    filterAccess: {
      handler: function(newValue) {
        if ((newValue === SHARE_ACCESS.PRIVATE || newValue === SHARE_ACCESS.EVERYONE)
          && this.filterGroups.length) {
          this.filterGroups = []
        }
      }
    },
    pCurrentShareAccess: {
      immediate: true,
      handler  : function(newValue) {
        this.filterAccess          = newValue.access
        this.filterGroups          = newValue.groups.map(group => group.id)
        this.showShareAccessDialog = true
      }
    },
    pIsSharingAccess: {
      immediate: true,
      handler  : function(newValue) {
        this.$DECISIONS.CHANGE_ACCESS.pActions[0].buttonProps.disabled = newValue
        this.$DECISIONS.CHANGE_ACCESS.pActions[1].buttonProps.loading  = newValue
      }
    },
    pIsAccessShared: {
      handler: function(newValue) {
        if (newValue) {
          this.showShareAccessDialog             = false
          this.showShareAccessConfirmationDialog = false
        }
      }
    }
  }
}