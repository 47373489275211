import { mapGetters, mapActions } from "vuex"
import { getListOfStrings } from "@/utils"
import { getHeadersForTable, getFootersForTable } from "@/utils/table"
import { TABLE_NAMES } from "@/constants"
import ThemisBulkImportUsers from "@/components/bulk-import-users"

export default {
  name      : "Users",
  components: {
    ThemisBulkImportUsers
  },
  data() {
    return {
      filters                  : undefined,
      search                   : undefined,
      showBulkImportUsersDialog: false
    }
  },
  methods: {
    ...mapActions({
      uploadCsvFilesToBulkImport: "bulkImport/uploadCsvFilesToBulkImport",
      inviteUsers               : "users/inviteUsers",
      notify                    : "shared/notify"
    }),
    /**
     * This method is used custom filtering for vuetify data table.
     * This will be called for every cell value in the table.
     * @param {*} value is content of each cell in a the table
     */
    customFilterForTable(value) {
      return this.filters.findIndex(element => value && value.toString().includes(element)) + 1
    },
    handleUpdateBulkImportUsers(dataToUpdated) {
      this.showBulkImportUsersDialog = false
      this.uploadCsvFilesToBulkImport(dataToUpdated)
    },
    getTextForStatusColumn(user) {
      if (user.enabled && user.lastLogin && user.invited) {
        return this.$t("130")
      } else if (user.enabled && !user.lastLogin && user.invited) {
        return this.$t("131")
      } else if (!user.enabled && !user.lastLogin && !user.invited) {
        return this.$t("2380")
      } else {
        return this.$t("129")
      }
    },
    handleInviteUsers() {
      this.inviteUsers()
    }
  },
  computed: {
    ...mapGetters({
      users                         : "users/users",
      groups                        : "groups/groups",
      groupsOfUsers                 : "users/groupsOfUsers",
      isDefaultSsoEnabled           : "configurations/isDefaultSsoEnabled",
      usersCount                    : "configurations/usersCount",
      isBulkImportUsersEnabled      : "configurations/isBulkImportUsersEnabled",
      isCsvFileUploadedForBulkImport: "bulkImport/isCsvFileUploadedForBulkImport"
    }),
    groupsMap() {
      const groupsMap = new Object()
      for (const group of this.groups) {
        groupsMap[group.id] = group
      }
      return groupsMap
    },
    usersForTable: function() {
      return this.users.map(user => {
        const userForTable = {
          id       : user.id,
          name     : user.name,
          email    : user.email,
          jobTitle : user.jobTitle,
          status   : this.getTextForStatusColumn(user),
          mfaStatus: this.$t(user.mfaEnabled ? "130" : "129"),
          groups   : new Array()
        }
        if (this.groupsOfUsers[user.id]) {
          for (const group of this.groupsOfUsers[user.id]) {
            userForTable.groups.push(this.groupsMap[group]?.name)
          }
        }
        return userForTable
      })
    },
    headersForTable() {
      const excludedColumns = this.isDefaultSsoEnabled ? ["status", "mfaStatus"] : undefined

      return getHeadersForTable(
        TABLE_NAMES.USERS,
        this.$t.bind(this),
        excludedColumns
      )
    },
    footersForTable() {
      return getFootersForTable(TABLE_NAMES.USERS, this.$t.bind(this))
    },
    itemsForSearch() {
      return getListOfStrings(this.usersForTable, ["id"])
    },
    numberOfUsersWithoutSupportUser() {
      return this.users.filter(user => !user.supportUser).length
    },
    isAddUserButtonDisabled() {
      return this.numberOfUsersWithoutSupportUser >= this.usersCount
    }
  },
  watch: {
    filters: function(newFilters) {
      this.search = newFilters ? newFilters.toString() : undefined
    },
    isCsvFileUploadedForBulkImport: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "2382"
          })
        }
      }
    }
  }
}