import ThemisInput from "@/components/shared/input"
import { getHeadersForTable } from "@/utils/table"
import ThemisValidationStatusIcons from "@/components/shared/validation-status-icons"
import {
  TABLE_NAMES,
  SPEAK_UP_USERS_FIELDS_ITEMS
} from "@/constants"
import moment from "moment"
import { sortIndices } from "@/utils"

export default {
  name      : "SpeakUpUsersFieldsValidationTable",
  components: {
    ThemisInput,
    ThemisValidationStatusIcons
  },
  props: {
    pItemsForTable: Array
  },
  emits: ["speakUpUsersFieldMappingStatus"],
  data : () => ({
    itemsForValidationTable: [],
    selectedDropDownItems  : new Map()
  }),
  computed: {
    calculateHeightForTable() {
      const dataRowHeight      = 48
      const maxAvailableHeight = window.innerHeight - 430
      const maxDataRows        = Math.floor((maxAvailableHeight / dataRowHeight) - 1)
      let heightOfTable        = dataRowHeight

      if (this.itemsForValidationTable.length > maxDataRows) {
        heightOfTable += maxDataRows * dataRowHeight
      }  else if (!this.itemsForValidationTable.length) {
        heightOfTable += dataRowHeight
      } else {
        heightOfTable += this.itemsForValidationTable.length * dataRowHeight
      }
      return heightOfTable
    },
    headersForCsvDataValidationTable() {
      return getHeadersForTable(TABLE_NAMES.CSV_DATA_VALIDATION, this.$t.bind(this))
    },
    itemsForSpeakUsersFields() {
      return [{
        name : this.$t("2369"),
        value: SPEAK_UP_USERS_FIELDS_ITEMS.SKIP_IN_IMPORT
      }, {
        name : this.$t("2370"),
        value: SPEAK_UP_USERS_FIELDS_ITEMS.NAME
      }, {
        name : this.$t("2371"),
        value: SPEAK_UP_USERS_FIELDS_ITEMS.EMAIL
      }, {
        name : this.$t("2372"),
        value: SPEAK_UP_USERS_FIELDS_ITEMS.JOB_TITLE
      }, {
        name : this.$t("2373"),
        value: SPEAK_UP_USERS_FIELDS_ITEMS.CREATED_AT
      }, {
        name : this.$t("2375"),
        value: SPEAK_UP_USERS_FIELDS_ITEMS.PHONE
      }, {
        name : this.$t("2374"),
        value: SPEAK_UP_USERS_FIELDS_ITEMS.GROUP
      }]
    }
  },
  methods: {
    setValidationFailure(item, message, indicesWithoutValues) {
      item.validationFailure    = true
      item.validationInProgress = false
      item.validationSuccess    = false
      item.errorToBeDisplayed   = {
        message      : message,
        indicesLength: indicesWithoutValues.length,
        sortedIndices: sortIndices(indicesWithoutValues)
      }
      const objectHasKeys       = !!Object.keys(item.errorToBeDisplayed).length
      this.$emit("speakUpUsersFieldMappingStatus", {
        mappedSpeakUpUsersField: item.speakupFields,
        header                 : item.header,
        error                  : objectHasKeys
      })
    },
    setValidationSuccessState(item) {
      item.validationSuccess       = true
      item.validationInProgress    = false
      item.validationFailure       = false
      item.defaultStateOfOuterIcon = false
      item.errorToBeDisplayed      = {}
      const objectHasKeys          = !!Object.keys(item.errorToBeDisplayed).length
      this.$emit("speakUpUsersFieldMappingStatus", {
        mappedSpeakUpUsersField: item.speakupFields,
        header                 : item.header,
        error                  : objectHasKeys
      })
    },
    setInProgressValidationState(item) {
      item.validationInProgress    = true
      item.validationSuccess       = false
      item.validationFailure       = false
      item.defaultStateOfOuterIcon = false
    },
    setDefaultValidationState(item) {
      item.validationInProgress    = false
      item.validationSuccess       = false
      item.validationFailure       = false
      item.defaultStateOfOuterIcon = true
      item.errorToBeDisplayed      = {}
      const objectHasKeys          = !!Object.keys(item.errorToBeDisplayed).length
      this.$emit("speakUpUsersFieldMappingStatus", {
        mappedSpeakUpUsersField: item.speakupFields,
        header                 : item.header,
        error                  : objectHasKeys
      })
    },
    getValidationRules(item) {
      return item.validationFailure ?
      `speakup_fields_error:true,${item.errorToBeDisplayed.message},${item.errorToBeDisplayed.sortedIndices}` : "speakup_fields_error:false"
    },
    isEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailPattern.test(email)
    },
    filterSpeakUpFieldsDropDownItems() {
      const arrayOfSelectedDropDownItems = Array.from(this.selectedDropDownItems.values())
      const itemsForSpeakUpFields        = [...this.itemsForSpeakUsersFields]

      this.itemsForValidationTable.forEach(item => {
        const modifiedArrayOfSelectedDropDownItems = arrayOfSelectedDropDownItems.filter(
          selectedItem => selectedItem !== item.speakupFields
        )
        item.speakUpUsersFieldsItems               = itemsForSpeakUpFields.filter(subItem => {
          return !modifiedArrayOfSelectedDropDownItems.includes(subItem.value)
        })
      })
    },
    handleSelectionChange(item) {
      this.setInProgressValidationState(item)

      if (item.speakupFields !== SPEAK_UP_USERS_FIELDS_ITEMS.SKIP_IN_IMPORT) {
        this.selectedDropDownItems.set(item.header, item.speakupFields)
      } else {
        this.selectedDropDownItems.delete(item.header)
      }

      this.filterSpeakUpFieldsDropDownItems()

      if (item.speakupFields === SPEAK_UP_USERS_FIELDS_ITEMS.SKIP_IN_IMPORT) {
        this.setDefaultValidationState(item)
        return
      } else if (item.speakupFields === SPEAK_UP_USERS_FIELDS_ITEMS.EMAIL) {
        const invalidEmailIndices = []
        item.headerColumnRows.forEach((rowValue, index) => {
          if (!this.isEmail(rowValue)) {
            invalidEmailIndices.push(index)
          }
          if (invalidEmailIndices.length) {
            this.setValidationFailure(item, "2376", invalidEmailIndices)
          } else {
            this.setValidationSuccessState(item)
          }
        })
      } else if (item.speakupFields === SPEAK_UP_USERS_FIELDS_ITEMS.NAME) {
        const invalidNameIndices = []
        item.headerColumnRows.forEach((rowValue, index) => {
          if (!rowValue || rowValue === "") {
            invalidNameIndices.push(index)
          }
          if (invalidNameIndices.length) {
            this.setValidationFailure(item, "2377", invalidNameIndices)
          } else {
            this.setValidationSuccessState(item)
          }
        })
      } else if (item.speakupFields === SPEAK_UP_USERS_FIELDS_ITEMS.CREATED_AT) {
        const invalidDateTimeIndices = this.validateDateTimeFields(item.headerColumnRows)
        if (invalidDateTimeIndices.length) {
          this.setValidationFailure(item, "2378", invalidDateTimeIndices)
        } else {
          this.setValidationSuccessState(item)
        }

      } else {
        this.setValidationSuccessState(item)
      }
    },
    validateDateTimeFields(allRowsArray) {
      const invalidDateTimeIndices = []

      allRowsArray.forEach((dateString, index) => {
        if(dateString) {
          const parsedDate = moment(dateString, moment.ISO_8601, true)

          if (!parsedDate.isValid()) {
            invalidDateTimeIndices.push(index + 1)
            return
          }

          const isoString       = parsedDate.toISOString()
          const isDateTimeValid = dateString === isoString || dateString === isoString.replace("Z", "+00:00")

          if (!isDateTimeValid) {
            invalidDateTimeIndices.push(index + 1)
          }
        }
      })
      return invalidDateTimeIndices
    }
  },
  watch: {
    pItemsForTable: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && newValue.length) {
          this.selectedDropDownItems = new Map()
          this.headerAndChildrenDropDownListItems = new Map(),
          this.itemsForValidationTable = newValue.map(item => {
            return {
              ...item,
              speakupFields          : SPEAK_UP_USERS_FIELDS_ITEMS.SKIP_IN_IMPORT,
              speakUpUsersFieldsItems: this.itemsForSpeakUsersFields,
              defaultStateOfOuterIcon: true,
              validationInProgress   : false,
              validationSuccess      : false,
              validationFailure      : false,
              errorToBeDisplayed     : {}
            }
          })
        }
      }
    }
  }
}